import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import TimeIcon from "../../../assets/img/icons/TimeIcon";
import LocationIcon from "../../../assets/img/icons/LocationIcon";
import ArrowForward from "../../../assets/img/icons/ArrowForward";

import BasicButton from "../../../global/BasicButton";
import { showToast } from "../../../utils";
import "./style.scss";

const Vector2 = require("../../../assets/img/Physical.svg").default;

type Props = {
  item: any;
  setMapCenter: any;
  handleViewDetails: any;
  setMapSidebar: any;
};

const UpcomingCard = ({
  item = {},
  setMapCenter = () => null,
  handleViewDetails = () => null,
  setMapSidebar = () => null,
}: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="upcoming_card_main"
        onClick={() => {
          navigate(`/listing-two/${item.event.listing.id}`);
        }}
      >
        <div className="left_booking_date_wrap">
          <p className="today_day">
            {moment(item?.date).format("YYYY-MM-DD") ===
            moment(Date.now()).format("YYYY-MM-DD")
              ? "Today"
              : moment(item?.date).format("ddd")}
          </p>
          <p className="today_date">
            {moment(item?.date).format("Do").slice(0, -2)}
            <sup>{moment(item?.date).format("Do").slice(-2)}</sup>
          </p>
        </div>
        <div className="my_booking_card_line" />
        <div className="right_booking_details_wrap">
          <div className="inner_right_booking_wrap">
            <div className="right_content_main">
              <div className="heder_container_booking">
                <div className="title_header_booking">
                  {item.title}
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                  }}
                >
                  <img
                    alt=""
                    className="package_resp"
                    src={
                      item.listingType === "P"
                        ? "/static/bookingpacakge.svg"
                        : "/static/bookingsession.svg"
                    }
                  />
                </div>
                <div
                  className="view_btn_container"
                  onClick={(e) => e.stopPropagation()}
                >
                  <BasicButton
                    onClick={() =>
                      handleViewDetails(
                        item?.id,
                        item?.event?.listing?.listingType,
                        item?.event?.id
                      )
                    }
                    btnTxt="View Details"
                    endIcon={<img src="/static/arrow/forwardArrow.svg" alt="" />}
                  />
                </div>
              </div>
              <div className="sub_details_event_type">
                <div className="event_type_wrap">
                  <div className="event_type">
                    Event Type:{" "}
                    <span
                      className={
                        item?.listingEventType === "V"
                          ? ""
                          : "event-type-orange"
                      }
                      style={{
                        color:
                          item?.listingEventType === "V"
                            ? "inherit"
                            : "#ee762f",
                      }}
                    >
                      {item?.listingEventType === "V" ? "Virtual" : "Physical"}
                    </span>
                    <img
                      src={
                        item?.listingEventType === "V"
                          ? "/static/Internet.svg"
                          : Vector2
                      }
                      alt="Event Icon"
                      style={{ width: "16px" }}
                    />
                  </div>
                </div>
                <div
                  className="link_type_wrap"
                  onClick={(e) => e.stopPropagation()}
                >
                  {item?.listingEventType === "V" ? (
                    <>
                      <img alt="" src="/static/meet.svg" />
                      <span
                        className="meet_text"
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(item.virtualLink);
                        }}
                      >
                        Meet Link
                      </span>
                      <CopyToClipboard
                        onClick={(e) => e.stopPropagation()}
                        onCopy={() => showToast("Text copied!", "success")}
                        text={item.virtualLink}
                      >
                        <img
                          alt=""
                          src="/static/copyblue.svg"
                          className="cursor-pointer"
                        />
                      </CopyToClipboard>
                    </>
                  ) : (
                    <>
                      <span>
                        <LocationIcon />
                      </span>
                      <div className="location_text_main">{item.address}</div>
                      <span
                        className="map_click"
                        onClick={(e) => {
                          e.stopPropagation();
                          setMapCenter({
                            lat: Number(item?.latitude),
                            lng: Number(item?.longitude),
                          });
                          setMapSidebar(true);
                        }}
                      >
                        Open maps
                      </span>
                    </>
                  )}
                </div>
                <div className="time_duration_wrap d-flex align-items-center">
                  <TimeIcon color="#25272D" />
                  <span
                    className="time_text"
                    style={{
                      marginLeft: "4px",
                    }}
                  >
                    {`${moment(item?.startTime?.substring(0, 5), [
                      "HH:mm",
                    ]).format("hh:mmA")} - ${moment(
                      item?.endTime?.substring(0, 5),
                      ["HH:mm"]
                    ).format("hh:mmA")}`}
                  </span>
                </div>
              </div>
              <div className="action_status_wrap">
                <div onClick={(e) => e.stopPropagation()}>
                  <AddToCalendarButton
                    name={item?.title}
                    label={"Add to Calendar"}
                    startDate={item?.date}
                    timeZone="Australia/Sydney"
                    location={
                      item?.listingEventType === "P"
                        ? `https://www.google.com/maps/place/${item?.latitude},${item?.longitude}`
                        : ""
                    }
                    startTime={item?.startTime?.substring(0, 5)}
                    endTime={item?.endTime?.substring(0, 5)}
                    trigger="click"
                    hideCheckmark={true}
                    hideBranding
                    size="5|3|2"
                    options={["Apple", "Google", "Outlook.com", "iCal"]}
                    styleLight="--btn-background: #faebe3;--btn-background-hover:   #faebe3; --btn-text: #ee7830;--btn-text-hover: #ee7830; --font: Poppins ;"
                    styleDark="--btn-background: #faebe3; --btn-text: #ee7830; --font: Georgia, 'Times New Roman', Times, serif;"
                  />
                </div>
                <div className="status_wrap">
                  Booking :{" "}
                  <span
                    className={
                      item?.bookingPaymentStatus === "Processing"
                        ? "special_processing"
                        : "special_success"
                    }
                  >
                    {item?.bookingPaymentStatus}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default UpcomingCard;
