export const STRING_CONSTANTS = {
  name: "Akash",
  forgot_password: "I forgot my password",
  sign_up_trainer_txt: "Do you want to sign up as a personal trainer?",
  profile_image_required: "Please upload profile image",
  please_add_interest: "Please add atleast 1 interest",
  wrong_data_message: "Something is wrong with the data",
  invalid_abn: "ABN Number is not active",
  invalid_abn_msg: "Search text is not a valid ABN or ACN",
  confirm_you: "Confirm it’s you",
  logout_success: "Logout Successfully",
  select_atleast_one_intrest: "Select atleast one Interest",
  password_desc:
    "To make sure it's you, please input your password to confirm the email change.",
  forgot_pass_title: "Forgot your password?",
  forgot_pass_desc: "Enter your email and we will send you a reset link.",
  delete_account_title: "We’re sorry to see you go!",
  delete_account_desc:
    "If you wish to delete your Fitness Mates account, please enter your password again below.",
  no_data_desc:
    "We apologize, but we couldn't find any interests that match your search term",
  no_data_small_desc: "Please try searching for another interest",
  basic_details_title: "Make your profile shine",
  basic_details_desc:
    "Add your business details to help your customers easily recognise and connect with your brand.",
  enter_businness_details: "Enter your business details below.",
  enter_details: "Enter your details below.",
  your_intrest: "Your interests",
  your_buisness_intrest: "Business interests",
  intrest_desc: "Select at-least one interest to continue.",
  update_business_details: "Update business details",
  update_personal_details: "Update personal details",
  expertise_desc:
    "Let us know about your expertise as a trainer and in your chosen interests!",
  logout_title: "Are you sure?",
  logout_desc: "You’ll need to log back in to access the app.",
  upload_file_5: "You can only upload maximum of 5 documents",
  unlink_social_login: "Unlink social login",
  user_name_250_max: "Username cannot be more than 250 characters",
  bio_500_max: "Bio cannot be more than 500 characters",
  user_type: "User type",
  username: "the full name",
  bio: "bio",
  address: "address",
  dob: "Date of birth",
  abn_length_11: "ABN Number must be exactly 11 digits.",
  password_length_8: "Password must contain atleast 8 letter",
  confirm_password_length_8:"Confirmed password must contain atleast 8 letter",
  invalid_email: "Invalid email address",
  new_password: "New password",
  old_password: "Old password",
  abn_no: "ABN number",
  card_added_successfully: "Card saved successfully",
  add_new_card: "Add new payment method",
  document_size_50: "click to browse (50 MB max)",
  document_size_10: "click to browse (10 MB max)",
  document_size_100: "click to browse (100 MB max)",
  upload_file_here: "Upload files here",
  upload_your_listing_photo: "Upload your listing photo or video",
  general_form_error_message: "Please correct the errors",
  add_photo_video: "Add a photo or video",
  fav_desc: "Easily view and remove your favorite users.",
  no_more_than_8_cards: "Can not add more than 8 cards",
  please_login_first:
    "Kindly create your account or Log in if already an existing user.",
  month_not_more_than_12: "Month value cannot be greater than 11",
  select_listing_type: "Select a listing type",
  dob_error: "Date of birth can not be a future date",
  no_fav_yet: "No favourites yet",
  no_fav_list_desc:
    "Start favouriting people on their profiles and build your list of favourites.",
  we_apologise_no_search_result:
    "We apologise, but we couldn't find any results that match	your search term",
  no_listing_data: "	Find local listings, events & connect with other members.",
  listing_info:
    "Be accurate and detailed in your Fitness Mates listing to attract more users.",
  max_participant_info:
    "Set participant limit. Listing closes when maximum participants is reached.",
  listing_cost_method:
    "Select the payment method, and your earnings will be transferred into that payment method",
  listing_cost_info: "Cost reflects class entry fee.",
  listing_around_you: "Listings around you",
  multiple_listing_around_you: "Bundle Deals for Multi-Class Listings",
  teams_around_you: "Explore Local teams",
  see_whats_around_in_listing:
    "See what’s going on around you.. join a fitness mates event today.",
  see_whats_around_in_multiple_listing:
    "Learn more, spend less! Multiple classes in one multiple.",
  see_whats_around_in_team:
    "Connect with like-minded individuals and engage in activities you love.",
  listing_details: "Listing Details",
  multiple_details: "Multiple Details",
  team_details: "Team Details",
  listing_location: "Listing location",
  multiple_location: "Multiple location",
  team_location: "Team location",
  payment_and_scheduling: "Payment and scheduling",
  schedule_what_day_to_run_lisiting: "Schedule what days to run listing",
  age_prefrence: "Age preference",
  all_age_listing: "This listing is for all ages",
  specific_age_listing: "This listing is made for a specific age group",
  gender_preference: "Gender preference",
  all_gender_listing: "This listing is for all genders",
  specific_gender_listing: "This listing is for a specific gender",
  make_listing_private: "Make this listing private?",
  make_multiple_private: "Make this package listing private?",
  make_team_private: "Make this team listing private?",
  please_fill_empty_fields: "Please fill the empty fields",
  start_and_end_time_error:
    "The event start time and end time is required to proceed.",
  select_future_time: "Please select some future time",
  start_and_end_date_error:
    "Event end date can not be less then or equal to the start date",
  schedule_date_placeholder: "Schedule what days to run listing",
  no_result_found: "No results found",
  please_try_another_term: "Please try another term to find an interest.",
  non_decimal_value: "This must be non decimal value",
  save_listing_as_draft: "Save listing as a draft?",
  draft_available: "Draft Available",
  event: "Events",
  listing: "My Listings",
  promotion: "Promotions",
  connect: "Connect",
  remove_video: "Remove video",
  remove_photo: "Remove photo",
  booking_info:
    "Granting this permission allows users to cancel events even after booking confirmation in the selected slot. We recommend a flexible cancellation policy.",
  payment_info:
    "Payment will be transferred to your specified bank account within 5 business days after the booking is completed.",
  remove_connection: "Remove Connection",
  delete_chat: "Delete Chat",
  exit_group: "Exit Group",
  remove_connection_description:
    "Are you sure you want to remove Kristin from your connection?",
  user_flagged: "User Flagged",
  user_flagged_description:
    "Thanks for keeping our community safe! Rest assured, actions will be taken. You won't encounter this user again.",
  user_flag_user: "Flag User",
  user_flag_description:
    "Your feedback matters! Use this feature to report any users that violate our community guidelines. Help us keep our platform safe and enjoyable for everyone. Thank you!",
  whats_wrong: "Tell us what went wrong?",
  no_users_data: "Find local user",
  name_length_3: "Name must contain atleast 3 letter",
  multiple_error_msg:
    "Oops! It seems there are errors in multiple fields. Please review and correct before proceeding",
  start_and_end_date_error_eql: "The start time can’t be more than end time",
  special_instructions: "Special instructions",
  update_profile: "Update Profile",
  trading_name: "trading name",
};
