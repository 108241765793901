import React, { useEffect, useState } from "react";
import "./style.scss";
import { Box, Collapse, Grow } from "@mui/material";
import BottomAddSlot from "./BottomAddSlot";
import CheckboxInput from "../../../CheckboxInput";
import SingleTimePicker from "../../../DatePicker/SingleTimePicker/index.tsx";
import InputFieldFloating from "../../../InputFieldFloating";
import {
  DATE_FORMAT,
  FORM_KEYS,
} from "../../../../constants/helperScheduleDates";
import moment from "moment";
type Props = {
  dateWiseData: any;
  indexing: any;
  watch: any;
  setValue: any;
  getValues: any;
  errors: any;
  clearErrors: any;
  disableCopy?: boolean;
  checkedCopy?: any;
  setCheckedCopy?: any;
};

const DateWiseSection = ({
  dateWiseData,
  indexing,
  watch,
  setValue,
  getValues,
  errors,
  clearErrors,
  disableCopy = false,
  checkedCopy,
  setCheckedCopy
}: Props) => {
  const [expandDateSlot, setExpandDateSlot] = useState(true);
  // const [checkedCopy, setCheckedCopy] = useState(false);
  const changesSlots = watch(FORM_KEYS.TEMP_DATE_TIME_SLOTS);

  const handleCopyCheckedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedCopy(event.target.checked);
    if (event.target.checked) handleCopyTimeSlot();
  };

  const getDurationData = (start, end) => {
    if (!start || !end) {
      return "";
    }
    const startT = moment(start);
    const endT = moment(end);
    const totalMinutes = endT.diff(startT, "minute");
    return `${totalMinutes} mins`;
  };

  const handleRemoveTimeSlot = (indexDate, indexTime) => {
    const finalResult = getValues(FORM_KEYS.TEMP_DATE_TIME_SLOTS).map(
      (slots, index) => {
        if (index === indexDate) {
          const completeData = getValues(FORM_KEYS.TEMP_DATE_TIME_SLOTS);
          const filterSlot = completeData[indexDate]?.timeSlots?.filter(
            (slots, index) => index !== indexTime
          );
          return {
            ...slots,
            timeSlots: filterSlot,
          };
        } else {
          return slots;
        }
      }
    );
    setValue(FORM_KEYS.TEMP_DATE_TIME_SLOTS, finalResult);
    clearErrors();
  };

  const handleAddTimeSlot = (indexDate) => {
    const finalResult = getValues(FORM_KEYS.TEMP_DATE_TIME_SLOTS).map(
      (slots, index) => {
        if (index === indexDate) {
          const completeData = getValues(FORM_KEYS.TEMP_DATE_TIME_SLOTS);
          const prevSlot = completeData[indexDate]?.timeSlots;
          return {
            ...slots,
            timeSlots: [
              ...prevSlot,
              {
                startTime: null,
                endTime: null,
              },
            ],
          };
        } else {
          return slots;
        }
      }
    );
    setValue(FORM_KEYS.TEMP_DATE_TIME_SLOTS, finalResult);
    clearErrors();
  };

  const handleCopyTimeSlot = () => {
    const finalResult = getValues(FORM_KEYS.TEMP_DATE_TIME_SLOTS).map(
      (slots, index) => {
        if (index === 0) {
          return slots;
        } else {
          const completeData = getValues(FORM_KEYS.TEMP_DATE_TIME_SLOTS);
          const firstTimeSlots = completeData[0]?.timeSlots;
          return {
            ...slots,
            timeSlots: firstTimeSlots,
          };
        }
      }
    );
    setValue(FORM_KEYS.TEMP_DATE_TIME_SLOTS, finalResult);
    clearErrors();
  };

  const customHandleChange = (name: any, val: any) => {
    setValue(name, val);
    clearErrors();
    if(checkedCopy){
      setCheckedCopy(false);
    }
  };

  return (
    <Box className="date_wise_wrapper">
      <Box
        className="date_wise_header"
        onClick={() => setExpandDateSlot((prev) => !prev)}
      >
        <Box className="date_wise_date_count">
          {moment(dateWiseData?.date, DATE_FORMAT.DATE_MONTH_FORMAT).format(
            DATE_FORMAT.DAY_MONTH_FORMAT
          )}{" "}
          {dateWiseData?.timeSlots?.length > 0 &&
            `(${dateWiseData?.timeSlots?.length} slots added)`}
        </Box>
        <Box className="date_wise_date_count_resp">
          {dateWiseData?.date}{" "}
          {dateWiseData?.timeSlots?.length > 0 &&
            `(${dateWiseData?.timeSlots?.length} slots)`}
        </Box>
        <Box className="date_wise_header_action">
          <Box
            className="date_wise_add_slot"
            onClick={(e) => {
              e.stopPropagation();
              handleAddTimeSlot(indexing);
            }}
          >
            Add slot
            <img src="/static/arrow/addPrimary.svg" alt="" />
          </Box>
          {expandDateSlot ? (
            <img
              src="/static/arrow/collapseGrey.svg"
              className="collapse_icon"
              alt=""
            />
          ) : (
            <img
              src="/static/arrow/expandGrey.svg"
              className="collapse_icon"
              alt=""
            />
          )}
        </Box>
      </Box>
      {/* content */}
      {expandDateSlot && (
        <Collapse in={expandDateSlot}>
          <Box>
            <Box className="time_slots_section">
              {dateWiseData?.timeSlots?.map((data, indexTime) => (
                <Grow in={Boolean(dateWiseData?.timeSlots)} timeout={500}>
                  <Box
                    className="slots_mapping_container"
                    key={"time" + dateWiseData?.date + indexTime}
                  >
                    <Box className="slots_mapping_inner">
                      <Box className="time_slots_section_left">
                        <SingleTimePicker
                          label={"Start Time"}
                          name={`${FORM_KEYS.TEMP_DATE_TIME_SLOTS}[${indexing}].${FORM_KEYS.TIME_SLOTS}[${indexTime}].${FORM_KEYS.START_TIME}`}
                          placeholder={moment().format(
                            DATE_FORMAT.HRS_12_FORMAT
                          )}
                          error={
                            // !data?.startTime ||
                            Object.keys(errors).length > 0 &&
                            errors?.tempDateTimeSlots[indexing]?.[indexTime]
                          }
                          watch={watch}
                          handleSetValue={customHandleChange}
                        />
                        <SingleTimePicker
                          label={"End Time"}
                          name={`${FORM_KEYS.TEMP_DATE_TIME_SLOTS}[${indexing}].${FORM_KEYS.TIME_SLOTS}[${indexTime}].${FORM_KEYS.END_TIME}`}
                          placeholder={moment().format(
                            DATE_FORMAT.HRS_12_FORMAT
                          )}
                          // minTime={moment(data?.startTime).add(1, "minute")}
                          error={
                            // !data?.startTime ||
                            Object.keys(errors).length > 0 &&
                            errors?.tempDateTimeSlots[indexing]?.[indexTime]
                          }
                          watch={watch}
                          handleSetValue={customHandleChange}
                        />
                      </Box>
                      <Box className="time_slots_section_right">
                        <Box className="time_slots_result">
                          <InputFieldFloating
                            name=""
                            focused={Boolean(
                              getDurationData(data?.startTime, data?.endTime)
                            )}
                            placeholder="45 mins"
                            value={getDurationData(
                              data?.startTime,
                              data?.endTime
                            )}
                            label="Duration"
                            readOnly
                          />
                        </Box>
                        <img
                          src="/static/close/closeRed.svg"
                          className="remove_slot_icon"
                          style={{
                            cursor:
                              dateWiseData?.timeSlots?.length < 2
                                ? "not-allowed"
                                : "pointer",
                          }}
                          alt=""
                          onClick={() => {
                            if (dateWiseData?.timeSlots?.length > 1) {
                              handleRemoveTimeSlot(indexing, indexTime);
                            }
                          }}
                        />
                      </Box>
                    </Box>
                    {Boolean(
                      getDurationData(data?.startTime, data?.endTime)
                    ) && (
                      <Box className={"resp_duration"}>
                        <img src="/static/listing/timeClockBlack.svg" alt="" />
                        Duration:{" "}
                        {getDurationData(data?.startTime, data?.endTime)}
                      </Box>
                    )}
                  </Box>
                </Grow>
              ))}
            </Box>

            {
              <BottomAddSlot
                handleAddTimeSlot={() => handleAddTimeSlot(indexing)}
              />
            }
            {indexing === 0 && !disableCopy && (
              <CheckboxInput
                label="Copy same time slots for other event dates!"
                checked={checkedCopy}
                handleChecked={handleCopyCheckedChange}
              />
            )}
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

export default DateWiseSection;
