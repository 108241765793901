import React, { useEffect } from "react";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import { apiConstants } from "../../../utils/Constants";
import "./style.scss";

type Props = {
  watch: any;
  setValue: any;
};

const SpecialInstruction = ({ watch, setValue }: Props) => {
  useEffect(() => {
    if (watch(apiConstants.selectedInstruction)?.length < 1) {
      setValue(apiConstants.selectedInstruction, [
        {
          instruction: "",
        },
      ]);
    }
  }, []);

  const handleAddInstructions = () => {
    let tempList = watch(apiConstants.selectedInstruction) || [];
    if (tempList?.length < 5) {
      setValue(apiConstants.selectedInstruction, [
        ...tempList,
        {
          instruction: "",
        },
      ]);
    }
  };

  const handleRemoveInstructions = (data: any, index: any) => {
    if (watch(apiConstants.selectedInstruction)?.length > 1) {
      const tempListData = watch(apiConstants.selectedInstruction)?.filter(
        (item, dataIndex) => dataIndex !== index
      );
      setValue(apiConstants.selectedInstruction, tempListData);
    }
  };

  return (
    <div>
      <div className="mt-3 special_inst_wrapper">
        <div className="special_inst_heading_wrapper">
          <div className="title_special_inst">
            {STRING_CONSTANTS.special_instructions}
          </div>
          <div
            className="add_special_btn_wrapper"
            onClick={() => handleAddInstructions()}
          >
            <div
              className={`${
                watch(apiConstants.selectedInstruction)?.length >= 5
                  ? "disable_text"
                  : "active_text"
              }`}
            >
              {"Add upto 5"}
            </div>
            {watch(apiConstants.selectedInstruction)?.length >= 5 ? (
              <img
                src="/static/grey_plus.png"
                className="add_special_btn disable_text"
                alt=""
              />
            ) : (
              <img
                src="/static/orange_plus.png"
                className="add_special_btn"
                alt=""
              />
            )}
          </div>
        </div>

        <div className="input_special_wrapper">
          {watch(apiConstants.selectedInstruction)?.map((val, index) => {
            return (
              <div key={val + index} className="add_inst_wrapper">
                <div className="add_inst_input">
                  <textarea
                    placeholder={"Type event instructions here"}
                    name={`${apiConstants.selectedInstruction}[${index}].instruction`}
                    value={watch(
                      `${apiConstants.selectedInstruction}[${index}].instruction`
                    )}
                    onChange={(e) => {
                      setValue(
                        `${apiConstants.selectedInstruction}[${index}].instruction`,
                        e.target?.value
                      );
                    }}
                  />
                  {watch(
                    `${apiConstants.selectedInstruction}[${index}].instruction`
                  ) && (
                    <img
                      src="/static/cross-circle-gray-dark.svg"
                      className="cross_icon_inst"
                      alt=""
                      onClick={() => {
                        setValue(
                          `${apiConstants.selectedInstruction}[${index}].instruction`,
                          ""
                        );
                      }}
                    />
                  )}
                  <div className="count_inst">
                    {`${
                      watch(
                        `${apiConstants.selectedInstruction}[${index}].instruction`
                      )
                        ?.trim()
                        ?.split(" ")?.length || 0
                    }/50`}
                  </div>
                </div>
                <img
                  onClick={() => handleRemoveInstructions(val, index)}
                  src="/static/createListingIcon/deleteTheme.svg"
                  style={{
                    cursor:
                      watch(apiConstants.selectedInstruction)?.length > 1
                        ? "pointer"
                        : "not-allowed",
                  }}
                  className="special_delete_icon"
                  alt=""
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SpecialInstruction;
