import { toast, TypeOptions } from "react-toastify";
import moment from "moment";
import success from "./Icons/success";
import error from "./Icons/error";
import { useNavigate } from "react-router-dom";
import { makeGetCall, makeNextPageCall, makePatchCall } from "./Requests";
import endpoints from "./endpoints";
import { ROUTE_CONSTANTS } from "./RouteConstants";
import { STRING_CONSTANTS } from "./StringConstant";
import { LISTING_TYPE } from "../types";
import axios from "axios";

export const emptyFunction = () => {};

export const generateFormData = (obj: any) => {
  let formData = new FormData();
  for (let [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      formData.append(`${key}`, `${value}`);
    } else {
      formData.append(`${key}`, `${value}`);
    }
  }
  return formData;
};

export const daysToYearsMonths = (days: number) => {
  const daysInYear = 365;
  const daysInMonth = 30;
  let years = null;
  let months = null;
  if (days > 0) {
    // Calculate the number of years and months
    years = Math.floor(days / daysInYear);
    months = Math.floor((days % daysInYear) / daysInMonth);
  } else {
    years = "";
    months = "";
  }

  return { years, months };
};
export const setSessionStorage = (key: string, value: string | any) =>
  window.sessionStorage.setItem(key, value);
export const getSessionStorage = (key: string) => sessionStorage.getItem(key);
export const removeSessionStorage = (key: string) =>
  sessionStorage.removeItem(key);

// adding deleting and updating local storage
export const getItemFromStore = (key: string) => {
  const storedData = window.localStorage.getItem(key);
  return storedData ? JSON.parse(storedData) : null;
};
export const setLocalStorage = (key: string, value: any) =>
  window.localStorage.setItem(key, JSON.stringify(value));

export const removeLocalStorage = (key: string) =>
  window.localStorage.removeItem(key);
export const removeAllItemFromStore = () => {
  var attribute = window.localStorage.getItem("attribute");
  window.localStorage.clear();
  if (attribute) {
    window.localStorage.setItem("attribute", attribute);
  }
  // window.localStorage.setItem('isFirstTimeUser', "no")
};
export const isUserAuthenticated = () => getItemFromStore("access_token");
export const RefreshToken = () => getItemFromStore("refresh_token");
export const scrollToBottom = () => {
  return window.scrollTo(0, document.body.scrollHeight);
};
export const scrollToTop = () => {
  return window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export function formatNumber(num) {
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + "k";
  }
  return num.toString();
}

export const showToast = (
  message: string | any,
  type: TypeOptions,
  toastId?: any
) => {
  const icon = type === "success" ? success : error;

  toast(message, {
    position: "top-right",
    autoClose: 7000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    type,
    icon: icon,
    className: `toast_${type}`,
    toastId: toastId || undefined,
  });
};

export const convertB64DataToByteArray = (b64Data: any) => {
  const encodedData = window.btoa(b64Data); // encode a string
  const byteCharacters = window.atob(encodedData);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return byteArray;
};

export const userFullName = (
  firstName: string,
  midName?: string,
  lastName?: string
) => {
  const name = `${firstName ? firstName : ""} ${midName ? midName : ""} ${
    lastName ? lastName : ""
  }`;
  return name;
};

export const blobToFile = (theBlob: Blob, name = "filename.png"): File => {
  return new File([theBlob], name, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  });
};

export const isSocialLogin = () => {
  // const setUserData = useStore((state: any) => state.setUserData);

  return (
    getItemFromStore("userProfile")?.socialLinks?.facebook ||
    getItemFromStore("userProfile")?.socialLinks?.google ||
    getItemFromStore("userProfile")?.socialLinks?.apple
  );
};
export const dataURLtoFile = (dataurl: any, filename: string) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
export const goToNextInput = (reference: any, context: any) => {
  return context[reference] ? context[reference].current.focus() : null;
};

export const dateFormatter = (
  inputDate?: any,
  format: string = "DD-MM-YYYY"
) => {
  return moment(inputDate ? inputDate : null).format(format);
};

// Function to format date range
export const formatDateRange = (startDate: any, endDate: any) => {
  const start = moment(startDate);
  const end = moment(endDate);

  if (start.isSame(end, "month") && start.isSame(end, "year")) {
    return `${start.format("DD")} - ${end.format("DD MMM, YYYY")}`;
  }

  return `${dateFormatter(startDate, "DD MMM, YYYY")} - ${dateFormatter(
    endDate,
    "DD MMM, YYYY"
  )}`;
};

export const positiveNumber = new RegExp("^([1-9]|10)$");
export const onlyNumber = new RegExp(/^[0-9]*$/);

export const handleLocation = (
  lat: string,
  lng: string,
  address: string,
  address_components: any
) => {
  const userLocation = Object.assign(
    {},
    ...address_components.map((item: any) => ({
      [item.types[0]]: item.short_name || item.long_name,
    }))
  );

  let AddressData = {
    address: address,
    lat: lat,
    lng: lng,
    zipcode: userLocation?.postal_code,
    state: userLocation?.administrative_area_level_1,
    locality: userLocation?.locality,
    country: userLocation?.country,
  };
  return AddressData;
};

export const isEmptyOrNullOrUndefined = (str: string) => {
  str =
    str === undefined || str == null || str === "0"
      ? ""
      : str.toString().trim();
  if (str === "") return true;
  return false;
};

export const isObjEmpty = (obj = {}) => Object.keys(obj).length === 0;

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const Redirect = () => {
  const Navigate = useNavigate();

  Navigate("/");
};

export const fetchMoreIntrestList = (
  nextUrl: any,
  data: any,
  setNextUrl: any,
  setData: any,
  setIsNextCallLoading: (value: boolean) => void
) => {
  if (!nextUrl) return;
  // setIsNextCallLoading(true);
  makeNextPageCall({ url: nextUrl })
    .then((res) => {
      const tempData = [...data, ...res.results];

      setNextUrl(res?.next);
      setData(tempData as never);
    })
    .catch((err) => {
      showToast(err, "error");
    });
};
export const getIntrestList = (
  params: any = "",
  setInterestList: any,
  setNextUrl: any,
  setIsLoading: any
) => {
  setIsLoading(true);
  makeGetCall({ url: endpoints.interest_list, params })
    .then((res) => {
      setInterestList(res?.results);
      setNextUrl(res?.next || null);
      setIsLoading(false);
    })
    .catch((err) => {
      setIsLoading(false);
      showToast(err, "error");
    });
};
export const getIntrestExperience = (
  params: any = "",
  setInterestList: any,
  setNextUrl: any,
  setIsLoading: any
) => {
  setIsLoading(true);
  makeGetCall({ url: endpoints.interest_experience, params })
    .then((res) => {
      setInterestList(res?.results);
      setNextUrl(res?.next || null);
      setIsLoading(false);
    })
    .catch((err) => {
      setIsLoading(false);
      showToast(err, "error");
    });
};

export const getUserProfile = (navigate: any, reset: any) => {
  let decoUserData;
  makeGetCall({ url: endpoints.profile })
    .then((res) => {
      if (res.status.code === 200) {
        let data = {
          ...res.data?.userDetail,
          ...res.data?.businessDetail?.[0],
          ...res.data?.trainerData?.[0],
        };

        if (data) {
          if (!data.name) {
            navigate(ROUTE_CONSTANTS.manage_profile);
          } else if (data?.interest?.length === 0 && !res.data.userDetail.isProfileCompleted ) {
            navigate("/manage-profile");
          } else {
            decoUserData = {
              ...data,
              businessContactNumber: data?.businessContactNumber?.replace('+', ''),
            };
            if (data.dob) {
              decoUserData.dob = moment(data.dob, "YYYY-MM-DD")?.toDate();
              decoUserData.dobDate = data?.dob ? moment(data.dob)?.date() : "";
              decoUserData.dobMonth = data?.dob
                ? moment(data.dob)?.format("MMMM")
                : "";
              decoUserData.dobYear = data?.dob ? moment(data.dob)?.year() : "";
            }
            // if (reset) {
            reset(decoUserData);
            // }
            setLocalStorage("userProfile", decoUserData);

            // setUserUpdate(true);
          }
        }
      } else {
        showToast(STRING_CONSTANTS.wrong_data_message, "error");
      }
    })
    .catch((err) => showToast(err, "error"));
};

export const notAValidUser = (navigate: any) => {
  //showToast(STRING_CONSTANTS.please_login_first, "error");
  navigate(ROUTE_CONSTANTS.login);
};

export const getNthWeekdayOfMonth: any = (
  year: number,
  month: number,
  dayOfWeek: number,
  occurrence: number
) => {
  const firstDayOfMonth = moment([year, month - 1]);
  const firstWeekday = firstDayOfMonth.isoWeekday();
  const shift =
    dayOfWeek >= firstWeekday
      ? dayOfWeek - firstWeekday
      : dayOfWeek + 7 - firstWeekday;
  const targetDate = firstDayOfMonth.add((occurrence - 1) * 7 + shift, "days");
  if (targetDate.month() !== month - 1) {
    return getNthWeekdayOfMonth(year, month, dayOfWeek, occurrence - 1);
  }
  return targetDate.toDate();
};

export const getNextNMonthsOccurrences = (
  year: number,
  month: number,
  dayOfWeek: number,
  occurrence: number,
  numMonths: number
) => {
  const occurrences = [];
  for (let i = 0; i < numMonths + 1; i++) {
    const currentYear = year + Math.floor((month + i - 1) / 12);
    const currentMonth = ((month + i - 1) % 12) + 1;
    const occurrenceDate = getNthWeekdayOfMonth(
      currentYear,
      currentMonth,
      dayOfWeek,
      occurrence
    );
    occurrences.push(occurrenceDate);
  }
  return occurrences;
};

export const findDatesInNextThreeMonths = (
  dateString: any,
  reps: any,
  endDate: any
) => {
  const date: any = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth();
  const dayOfWeek = moment(dateString).day();
  const repetation = Math.ceil(moment(dateString).date() / 7);
  const occurrence: any = {
    repetation,
    dayOfWeek: dayOfWeek === 0 ? 7 : dayOfWeek,
  };
  const dates: any = getNextNMonthsOccurrences(
    year,
    month + 1,
    dayOfWeek,
    occurrence.repetation,
    reps
    // endDate
  );

  return dates;
};

export const updateDisplayedFileInfo = (input: any) => {
  const file = input.files?.[0];

  // if (!file) {
  // 	output.textContent = "No file selected";
  // 	return;
  // }

  const dateModified = new Date(file.lastModified).toISOString();
  const { name, size, type } = file;

  const data = {
    dateModified,
    name,
    size,
    type,
  };

  const json = JSON.stringify(data, null, 2);
  return json;

  // output.textContent = json;
};

export const handleVideoClick = (id: string) => {
  const video: any = document.getElementById(id);
  if (video) {
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) {
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) {
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) {
      video.msRequestFullscreen();
    }
  }
};

export const getEventsList = (
  params: any = "",
  setEventList: any,
  setNextUrl: any,
  setIsLoading: any,
  setPageCount: any
) => {
  if (params?.listing_type === LISTING_TYPE.S) {
    params["listing_type"] = LISTING_TYPE.S + "," + LISTING_TYPE.SPECIAL;
  }
  if(params?.listing_type === LISTING_TYPE.C){
    delete params["max_price"]
    delete params["min_price"]
  }
  setIsLoading(true);
  makeGetCall({ url: endpoints.dashboard_listing_new, params })
    .then((res) => {
      setEventList(res?.results);
      setPageCount(res?.count);
      setNextUrl(res?.next || null);
      setIsLoading(false);
      scrollToTop();
    })
    .catch((err) => {
      setIsLoading(false);
      showToast(err, "error");
    });
};
export const getEventsListMap = (
  params: any = "",
  setEventMap: any,
  setNextUrl: any,
  setIsLoading: any,
  setPageCount: any
) => {
  if (params?.listing_type === LISTING_TYPE.S) {
    params["listing_type"] = LISTING_TYPE.S + "," + LISTING_TYPE.SPECIAL;
  }
  setIsLoading(true);
  makeGetCall({ url: endpoints.dashboard_listing_map, params })
    .then((res) => {
      setEventMap(res?.data);
      //alert('hi');
      // setPageCount(res?.count)
      // setNextUrl(res?.next || null);
      setIsLoading(false);
    })
    .catch((err) => {
      setIsLoading(false);
      showToast(err, "error");
    });
};

export const getSearchList = (
  params: any = "",
  setSearchEvents: any,
  setSearchUsers: any
) => {
  makeGetCall({ url: endpoints.search_users_listings, params })
    .then((res) => {
      if (params.tab === "Events") {
        setSearchEvents(res?.results);
      } else {
        setSearchUsers(res?.results);
      }
      // setEventMap(res?.data);
    })
    .catch((err) => {
      showToast(err, "error");
    });
};

//function to download file
export const downloadFile = (data) => {
  const link = document.createElement("a");
  link.href = data.data.csvUrl;
  // The filename you want to download it as
  const fileName = link.href.substring(link.href.lastIndexOf("/") + 1);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove(); // Remove the element after download
};

//function to convert amount
export const formatAmount = (amount) => {
  if (amount >= 1000000) {
    return (amount / 1000000).toFixed(2) + "M";
  } else if (amount >= 1000) {
    return (amount / 1000).toFixed(2) + "K";
  } else {
    return amount.toString();
  }
};

export const convertBytesToMB = (bytes, decimalPlaces = 2) => {
  const bytesInAMegabyte = 1024 * 1024;
  return (bytes / bytesInAMegabyte).toFixed(decimalPlaces);
};

export const allowedFormats = [
  "jpg",
  "jpeg",
  "heic",
  "png",
  "mp4",
  "mov",
  "avi",
  "gif",
];
export const downloadTicket = (data) => {
  const link = document.createElement("a");
  link.href = data;
  // The filename you want to download it as
  const fileName = link.href.substring(link.href.lastIndexOf("/") + 1);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove(); // Remove the element after download
};

export const getIntrestListFeatured = (
  params: any = "",
  setDesktopInterestList: any
) => {
  makeGetCall({ url: endpoints.interest_list, params })
    .then((res) => {
      if (res?.results?.length > 0 || res?.data?.length > 0) {
        if (res?.data?.length > 0) {
          setDesktopInterestList(res?.data);
        } else {
          setDesktopInterestList(res?.results);
        }
      } else {
        setDesktopInterestList([
          {
            id: "d97bcf7a-7f3f-4de8-a652-1e50a5df6205",
            createdAt: "2024-02-02T12:01:14.240000Z",
            modifiedAt: "2024-02-03T04:14:30.987600Z",
            title: "Interest 10",
            isActive: true,
            listingNo: 0,
            image:
              "https://fitness-mates.s3.amazonaws.com/interest/d97bcf7a7f3f4de8a6521e50a5df6205/dB055a5m-1706933670987.png",
            minimumAge: null,
            isFeatured: false,
          },
          {
            id: "ee4cffe2-7b40-4179-b85c-d34b3465aec3",
            createdAt: "2024-02-02T12:01:01.477055Z",
            modifiedAt: "2024-02-03T04:11:09.254264Z",
            title: "Interest 9",
            isActive: true,
            listingNo: 0,
            image:
              "https://fitness-mates.s3.amazonaws.com/interest/ee4cffe27b404179b85cd34b3465aec3/jRkmegTK-1706933469254.png",
            minimumAge: null,
            isFeatured: false,
          },
          {
            id: "5b48f256-5836-4b2e-9de7-512b712f1e06",
            createdAt: "2023-12-11T05:49:54.396051Z",
            modifiedAt: "2024-02-03T04:10:35.295328Z",
            title: "Zumba 1",
            isActive: true,
            listingNo: 0,
            image:
              "https://fitness-mates.s3.amazonaws.com/interest/5b48f25658364b2e9de7512b712f1e06/WJCTsano-1706933435295.png",
            minimumAge: null,
            isFeatured: false,
          },
          {
            id: "92c2a1db-7647-4a74-aa9a-921c67b7c9a8",
            createdAt: "2024-02-02T11:59:50.865597Z",
            modifiedAt: "2024-02-03T04:09:53.187480Z",
            title: "Interest 1",
            isActive: true,
            listingNo: 0,
            image:
              "https://fitness-mates.s3.amazonaws.com/interest/92c2a1db76474a74aa9a921c67b7c9a8/zyu9KFcJ-1706933393187.png",
            minimumAge: null,
            isFeatured: false,
          },
          {
            id: "996efcd2-ac25-42d2-be02-c6c73ecf2fbe",
            createdAt: "2024-02-02T12:00:55.389632Z",
            modifiedAt: "2024-02-03T04:15:33.667446Z",
            title: "Interest 8",
            isActive: true,
            listingNo: 0,
            image:
              "https://fitness-mates.s3.amazonaws.com/interest/996efcd2ac2542d2be02c6c73ecf2fbe/92hcYyHL-1706933733667.png",
            minimumAge: null,
            isFeatured: false,
          },
          {
            id: "aa389d29-54e2-408f-bf8d-7446face75b2",
            createdAt: "2023-12-11T05:49:32.955651Z",
            modifiedAt: "2024-02-03T04:15:25.631885Z",
            title: "Yoga",
            isActive: true,
            listingNo: 0,
            image:
              "https://fitness-mates.s3.amazonaws.com/interest/aa389d2954e2408fbf8d7446face75b2/RTeJ1cij-1706933725631.png",
            minimumAge: null,
            isFeatured: false,
          },
          {
            id: "ca7df1a7-2fe8-46ce-a3ff-bb463f96b91c",
            createdAt: "2024-02-02T12:00:17.628475Z",
            modifiedAt: "2024-02-03T04:14:15.110645Z",
            title: "Interest 5",
            isActive: true,
            listingNo: 0,
            image:
              "https://fitness-mates.s3.amazonaws.com/interest/ca7df1a72fe846cea3ffbb463f96b91c/EO2uTDmR-1706933655110.png",
            minimumAge: null,
            isFeatured: false,
          },
          {
            id: "38452caa-4820-47a3-ad64-0a51fdc225a8",
            createdAt: "2024-05-22T06:13:14.239723Z",
            modifiedAt: "2024-05-23T04:42:37.017758Z",
            title: "Crossfi",
            isActive: true,
            listingNo: 0,
            image: null,
            minimumAge: null,
            isFeatured: false,
          },
          {
            id: "e2de1f21-c707-4dd3-8635-dba0fd5aff10",
            createdAt: "2024-05-22T06:48:25.529580Z",
            modifiedAt: "2024-05-22T06:48:25.529640Z",
            title: "Crossfitts",
            isActive: true,
            listingNo: 0,
            image: null,
            minimumAge: null,
            isFeatured: false,
          },
          {
            id: "9fffe209-0d07-4247-ac9d-3c3318991a4f",
            createdAt: "2023-12-11T05:49:01.388259Z",
            modifiedAt: "2024-01-31T12:49:20.921415Z",
            title: "HIT 3",
            isActive: true,
            listingNo: 5,
            image:
              "https://fitness-mates.s3.amazonaws.com/interest/9fffe2090d074247ac9d3c3318991a4f/q9GEoUW3-1706705360921.jpeg",
            minimumAge: null,
            isFeatured: false,
          },
        ]);
      }
    })
    .catch((err) => {
      showToast(err, "error");
    });
};

export const handleFileUpload = async (
  file: File,
  url = "",
  key = "",
  AWSAccessKeyId = "",
  policy = "",
  signature = "",
  tempId = ""
) => {
  const formData = new FormData();

  formData.append("key", key);
  formData.append("AWSAccessKeyId", AWSAccessKeyId);
  formData.append("policy", policy);
  formData.append("signature", signature);
  formData.append("file", file);

  try {
    const response: any = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 204) {
      return {
        upload_document: key,
        document_name: file.name,
        document_file_type: file.type,
        tempId:tempId
      };
    }
  } catch (error) {
    console.error("Error uploading file", error);
  }
};

export const getRequiredMediaUrl = (item: any) => {
  if (item?.uploadDocumentThumbnail) {
    return item?.uploadDocumentThumbnail;
  } else {
    return item?.uploadDocument;
  }
};

export const debounce = (func: any, delay: any) => {
  let timerId: any;

  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const updateUserLocation = async (lat:any, long:any): Promise<void> => {
  try {
    const response = await makePatchCall({
      url: "auth/location/",
      apiPayload: {
        location: `${long},${lat}`,
      },
    });
  } catch (err) {
    showToast(err, "error");
  }
};

export const isVideo = (media: any) => {
  if (
    typeof media === "string" &&
    (media.includes("video") ||
      media.includes("mp4") ||
      media.includes("mov") ||
      media.includes("avi"))
  ) {
    return true;
  } else {
    return /\.(mp4|mov|avi)$/.test(
      media?.documentName?.toLowerCase() || media?.toLowerCase()
    );
  }
};