import { useEffect, useState } from "react";
import { getItemFromStore, handleLocation, showToast } from "../../../utils";
import { apiConstants } from "../../../utils/Constants";
import InputField from "../../../global/InputField";
import MobileInput from "../../../global/MobileInput";
import GooglePlacesSearchBar from "../../../global/GooglePlaceSelector";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import Button from "../../../global/Button";
import { makeGetCall } from "../../../utils/Requests";
import ImageCropper from "../../../global/ImageCropper";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import { USER_TYPE } from "../../../types";
import PhoneNumberInput from "../../PhoneNumberInput/PhoneNumberInput";

type Props = {
  [key: string]: any;
};

const BusinessDetails = ({
  handleNext,
  control,
  errors,
  isLoading,
  setValue,
  getValues,
  watch,
  handleBusineessDetailSkip,
  setCountryCode
}: Props) => {
  const { isMobile } = useCheckDeviceScreen();
  const [abnResponse, setAbnResponse] = useState<any>("");

  const [bussLocation, setBussLocation] = useState(
    watch(apiConstants.address) || null
  );

  useEffect(() => {
    if (watch(apiConstants.abn)?.length === 11) {
      makeGetCall({
        url: `auth/${getValues(apiConstants.abn)}/validate-abn/`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            if (res.data.AbnStatus === "Active") {
              setAbnResponse(res.data);
              setValue(apiConstants.abnDetails, res.data);
            } else {
              showToast(STRING_CONSTANTS.invalid_abn, "error");
              setAbnResponse(null);
            }
          } else {
            showToast(STRING_CONSTANTS.invalid_abn, "error");
            setAbnResponse(null);
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    } else {
      setAbnResponse(null);
    }
  }, [watch(apiConstants.abn)]);

  // const checkNumberIsAdded = () => {
  //   const numberWithoutCountryCode = getValues(
  //     apiConstants.businessContactNumber
  //   ).phone.startsWith(
  //     getValues(apiConstants.businessContactNumber).country.dialCode
  //   )
  //     ? getValues(apiConstants.businessContactNumber).phone.slice(
  //         getValues(apiConstants.businessContactNumber).country.dialCode.length
  //       )
  //     : getValues(apiConstants.businessContactNumber).phone;
  //   return numberWithoutCountryCode;
  // };

  const handleSubmit = () => {
    // setValue(apiConstants.businessContactNumber, {phone: checkNumberIsAdded()});
    handleNext();
  };

  return (
    <div className="mp_form_wrapper">
      <div className="mp_form_container">
        <div className="mp_form_fill_container">
          {getItemFromStore("userData")?.userType !== "Guest" && (
            <>
              <div className="mp_section">
                <InputField
                  type="tradingname"
                  className={"floating_input"}
                  name={apiConstants.tradingName}
                  control={control}
                  errors={errors}
                  inputLabel={"Trading Name"}
                  placeholder={"Enter your trading name"}
                  setValue={setValue}
                />
              </div>
              <div className="mp_section">
                <MobileInput
                  name={apiConstants.abn}
                  watch={watch}
                  setValue={setValue}
                  control={control}
                  className={"floating_input"}
                  inputLabel={"ABN Number"}
                  placeholder={"Add your ABN number"}
                  mask={"99999999999"}
                  errors={errors}
                />
                {watch(apiConstants.abn) &&
                  abnResponse &&
                  abnResponse?.AbnStatus === "Active" && (
                    <div className="api_response" style={{ marginTop: "16px" }}>
                      <div className="wrapper">
                        <>
                          <label>Business Name</label>
                          <p className="mb-0">{abnResponse?.EntityName}</p>
                          {isMobile && <label>Address</label>}
                          <p className="mb-0">{abnResponse.AddressState}</p>
                          <span>
                            `Registered on` {abnResponse.AbnStatusEffectiveFrom}
                          </span>
                        </>
                      </div>
                    </div>
                  )}
              </div>
              <div className="mp_section">
                Enter business contact details below
              </div>
              <div className="mp_section">
                {/* <MobileInput
                  name={apiConstants.businessContactNumber}
                  watch={watch}
                  setValue={setValue}
                  control={control}
                  className={"mobile floating_input floating_pre_icon floating_pre_text"}
                  errors={errors}
                /> */}
                <PhoneNumberInput
                  name={apiConstants.businessContactNumber}
                  watch={watch}
                  setValue={setValue}
                  parentClass="signup_contact_field"
                  label={"Contact Number"}
                  errors={errors}
                  setCountryCode={setCountryCode}
                />
              </div>
              <div className="mp_section">
                <InputField
                  type="email"
                  className={"floating_input"}
                  name={apiConstants.businessContactEmail}
                  control={control}
                  errors={errors}
                  inputLabel={"Contact Email"}
                  placeholder={"Add your email address"}
                  setValue={setValue}
                />
              </div>
              <div className="mp_section">
                <GooglePlacesSearchBar
                  onChangeLocation={(
                    lat: string,
                    lng: string,
                    address: string,
                    address_components: any
                  ) => {
                    let location = handleLocation(
                      lat,
                      lng,
                      address,
                      address_components
                    );
                    setBussLocation(address);
                    setValue(apiConstants.address, address);
                    setValue(apiConstants.lat, lat);
                    setValue(apiConstants.lng, lng);
                    setValue(apiConstants.postCode, location.zipcode);
                    setValue(apiConstants.state, location.state);
                    setValue(apiConstants.city, location.locality);
                    setValue(apiConstants.country, location.country);
                  }}
                  setValue={setValue}
                  handleLocation={handleLocation}
                  control={control}
                  errors={errors}
                  name={apiConstants.address}
                  label={"Business location"}
                  placeholder="Address"
                />
              </div>
            </>
          )}

          <div className="mp_section"></div>
          <div className="mp_section"></div>
        </div>
      </div>
      <div
        style={{
          zIndex: "6",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          position: "sticky",
          bottom: "0px",
          padding: "30px 0px",
          boxShadow: "0px 0px 4px 0px #00000040",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            maxWidth: "768px",
            paddingRight: "20px",
            gap: "24px",
          }}
        >
          <Button
            btnTxt="Skip"
            className={"btn_skip"}
            style={{
              maxWidth: "372px",
              background: "transparent",
              paddingLeft: "20px",
              paddingRight: "20px",
              color: "#25272d",
            }}
            onClick={() => handleBusineessDetailSkip()}
          />
          <Button
            btnTxt="Save & continue"
            onClick={() => {
              if (
                getItemFromStore("userProfile")?.userType !== USER_TYPE.GUEST &&
                watch(apiConstants.address) &&
                watch(apiConstants.address) !== bussLocation
              ) {
                showToast(
                  "Please select business address from the suggestion.",
                  "error"
                );
                return;
              }
              handleSubmit();
            }}
            style={!isMobile ? { maxWidth: "372px" } : {}}
            className={"submit_btn"}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessDetails;
