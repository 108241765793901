import dayjs from "dayjs";
import moment from "moment";

export const DATE_FORMAT = {
  DAY_MONTH_FORMAT: "dddd, Do MMMM YYYY",
  SORT_DAY_MONTH_FORMAT: "ddd, Do MMMM",
  DATE_MONTH_FORMAT: "DD, MMMM YYYY",
  SORT_MONTH_FORMAT: "DD, MMM, YYYY",
  DDMMYYYY_FORMAT: "DD-MM-YYYY",
  YYYYMMDD_FORMAT: "YYYY-MM-DD",
  RESP_FORMAT: "YYYY-MM-DD HH:mm:ss.SSSSSS",
  HRS_12_FORMAT: "hh:mm A",
  TZ_TIME: "HH:mm:ss.SSSSSS",
} as const;

export const FORM_KEYS = {
  DATE_TIME_SLOTS: "dateTimeSlots",
  TEMP_DATE_TIME_SLOTS: "tempDateTimeSlots",
  TIME_SLOTS: "timeSlots",
  TEMP_TIME_SLOTS: "tempTimeSlots",
  START_TIME: "startTime",
  END_TIME: "endTime",
  INITIAL_DATES_RESULT: "initialDatesResult",
  TEMP_DATES_RESULT: "tempDatesResult",
  VIRTUAL_LINK: "virtualLink",
} as const;

export const WEEKLY_FORM_KEYS = {
  EVENT_DATE: "eventDate",
  EVENT_TYPE: "eventType",
  REPEAT_IN_EVERY: "repeatInEvery",
  REPEAT_TYPE: "repeatType",
  WEEKDAYS: "weekDays",
  MONTH_ON_EVERY: "monthOnEvery",
  MONTH_ON_DAYS: "monthOnDays",
  END_EVENT_TYPE: "endEventType",
  END_AFTER_VAL: "endAfterVal",
  END_BY_VAL: "endByVal",
} as const;

export enum SCHEDULE_TYPE {
  // schedule event type
  O = "O",
  R = "R",
  // recurring type
  MONTH = "Month",
  DAY = "Day",
  WEEK = "Week",
}

export const radioData = [
  {
    label: "One-time Event",
    value: SCHEDULE_TYPE.O,
  },
  {
    label: "Recurring Event",
    value: SCHEDULE_TYPE.R,
  },
];

export const eventData = [
  {
    label: "End after",
    value: "end-after",
  },
  {
    label: "End by",
    value: "end-by",
  },
];

export const convertToFlatDates = (data) => {
  const result = [];

  data.forEach((item) => {
    item.timeSlots.forEach((slot) => {
      if (slot?.startTime && slot?.endTime) {
        result.push({
          date: item.date,
          startTime: slot.startTime,
          endTime: slot.endTime,
        });
      }
    });
  });

  return result;
};

export const INITIAL_SCHEDULE_VALUES = {
  [FORM_KEYS.INITIAL_DATES_RESULT]: [
    {
      date: "23 March 2024",
    },
    {
      date: "15 April 2024",
    },
    {
      date: "7 May 2024",
    },
    {
      date: "19 June 2024",
    },
  ],
  [FORM_KEYS.TEMP_DATES_RESULT]: [],
  [FORM_KEYS.DATE_TIME_SLOTS]: [],
  [FORM_KEYS.TEMP_DATE_TIME_SLOTS]: [],
  [FORM_KEYS.TIME_SLOTS]: [],
  [FORM_KEYS.TEMP_TIME_SLOTS]: [],
};

export const formatDatePayload = (dates) => {
  return dates?.map((val) => moment(val, DATE_FORMAT.DATE_MONTH_FORMAT).format(DATE_FORMAT.YYYYMMDD_FORMAT));
};

export const formatDateResponse = (dates) => {
  return dates?.map((val) => moment(val, DATE_FORMAT.YYYYMMDD_FORMAT).format(DATE_FORMAT.DATE_MONTH_FORMAT));
};


// export const formatPicker=(props : any)=>{
//   if(props){
//   return moment(..props)

//   }else{
//     return moment()
//   }
// }