import React, { useEffect, useState } from "react";
import Button from "../../global/Button";
import {
  getItemFromStore,
  handleFileUpload,
  removeLocalStorage,
  setLocalStorage,
  showToast,
} from "../../utils";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import apiConfig from "../../utils/endpoints";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import { makeGetCall, makePatchCall, makePostCall } from "../../utils/Requests";
import { useNavigate } from "react-router-dom";
import LoadingLayer from "../ConnectUserProfile/LoaderLayer";
import { useAppDispatch } from "../../store/store";
import { authSuccess } from "../../store/auth/action";
import { handleData } from "../../constants/helperOnboarding";

type Props = {
  [key: string]: any;
};

const Trainer = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [interestList, setInterestList] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [userData, setUserData] = useState<any>(null);

  function convertToDays(years: any, months: any) {
    const daysInYear = 365;
    const daysInMonth = 30;
    const totalDays = years * daysInYear + months * daysInMonth;
    return totalDays;
  }
  useEffect(() => {
    makeGetCall({ url: apiConfig.profile })
      .then((res) => {
        setUserData(res.data);
        setInterestList(res.data?.userDetail?.interest || []);
        const initialSelectedData = (res.data?.userDetail?.interest || []).map(
          (interestData) => {
            // Find the trainer that matches the interestId
            const matchingTrainer =
              res.data?.trainerData[0]?.trainerSpecializationExperience?.find(
                (trainer) => trainer.interestId === interestData.id
              );

            return {
              id: interestData.id,
              trainerId: matchingTrainer ? matchingTrainer?.id : null,
              experience: 0,
              month: 0,
              interest_information: "",
              document_details: null,
              image: interestData.image,
              title: interestData?.title,
            };
          }
        );
        setSelectedData(initialSelectedData);
      })
      .catch((err) => {
        showToast(err, "error");
      });
  }, []);

  const handleNext = async () => {
    let newObj = selectedData
      .map(({ image, experience, month, id, trainerId, ...rest }) => {
        return {
          ...rest,
          experience: convertToDays(experience, month),
          interest: id,
          id: trainerId,
        };
      })
      .filter(
        (val) =>
          val.experience > 0 ||
          val?.document_details?.length > 0 ||
          val?.interest_information?.trim()
      );
    if (newObj?.length < 1) {
      navigate(ROUTE_CONSTANTS.landing);
      return;
    }

    setIsLoading(true);

    let uploadedNewObj = [];

    for (const item of newObj) {
      let newDoc = [];
      if (item?.document_details?.length > 0) {
        for (const files of item?.document_details) {
          const res = await makeGetCall({
            url: `${apiConfig.signed_url}/${files?.name}`,
          });
          if (res.status.code === 200) {
            const {
              AWSAccessKeyId = "",
              key = "",
              policy = "",
              signature = "",
            } = res?.data?.fields;
            const uploadedResponse = await handleFileUpload(
              files,
              res?.data?.url,
              key,
              AWSAccessKeyId,
              policy,
              signature
            );

            newDoc = [...newDoc, { ...uploadedResponse }];
          }
        }
        uploadedNewObj.push({
          ...item,
          document_details: newDoc,
        });
      } else {
        const { document_details, ...rest } = item;
        uploadedNewObj.push({ ...rest });
      }
    }

    await makePostCall({
      url: apiConfig.interest_experience,
      apiPayload: { update_experience: uploadedNewObj },
    })
      .then((res) => {
        if (res.status.code === 200) {
          setIsLoading(false);
          showToast(res.status.message, "success");
          setLocalStorage("expData", res?.data);
          handleSkipClick();
        } else {
          showToast(STRING_CONSTANTS.wrong_data_message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSkipClick = () => {
    let apiPayload: any = {};
    apiPayload.isProfileCompleted = true;
    apiPayload.onboardingStep = "7";
    makePatchCall({
      url: apiConfig.profile,
      apiPayload,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setIsLoading(false);
          showToast(res.status.message, "success");
          
          dispatch(
            authSuccess({
              moreUserDetails: handleData(res?.data?.userDetail || {}),
            })
          );
            setLocalStorage("userData", {
              ...res.data?.userDetail,
              ...res.data?.businessDetail,
              ...res.data?.trainerData,
            });
          navigate(ROUTE_CONSTANTS.landing);
          setLocalStorage("isProfileComplete", true);
        } else {
          showToast(STRING_CONSTANTS.wrong_data_message, "error");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        showToast(err, "error");
        setIsLoading(false);
      });
  };

  const handleFileChange = (event, id) => {
    const file: any = Array.from(event.target.files);

    // file: pdf and image
    const invalidFiles = file.filter((f: File) => {
      const isValidType =
        f.type.includes("pdf") || f.type.includes("image");

      return !isValidType;
    });
    if (invalidFiles.length > 0) {
      showToast("You can only upload 'pdf' and 'image' files.", "error");
      return;
    }

    // const maxSize = 30 * 1024 * 1024; // 30 MB

    setSelectedData((prev) => {
      const existingIndex = prev.findIndex((item) => item.id === id);
      const updatedData = [...prev];

      if (existingIndex > -1) {
        let detailData =
          updatedData[existingIndex]?.document_details?.length > 0
            ? [...updatedData[existingIndex]?.document_details]
            : [];
        updatedData[existingIndex] = {
          ...updatedData[existingIndex],
          document_details: [...file, ...detailData],
        };
      } else {
        updatedData.push({ id, document_details: [...file] });
      }

      return updatedData;
    });
  };

  const handleClearText = (id) => {
    setSelectedData((prev) => {
      const existingIndex = prev.findIndex((item) => item.id === id);
      const updatedData = [...prev];

      if (existingIndex > -1) {
        updatedData[existingIndex] = {
          ...updatedData[existingIndex],
          interest_information: "",
        };
      } else {
        updatedData.push({ id, interest_information: "" });
      }

      return updatedData;
    });
  };

  const handleChangeValue = (e, name, id) => {
    const val = e.target.value;

    setSelectedData((prev) => {
      const existingIndex = prev.findIndex((item) => item.id === id);
      const updatedData = [...prev];

      switch (name) {
        case "year":
          if ((val > -1 && val < 100) || val === "") {
            if (existingIndex > -1) {
              updatedData[existingIndex] = {
                ...updatedData[existingIndex],
                experience: val,
              };
            } else {
              updatedData.push({ id, experience: val });
            }
          }
          break;

        case "month":
          if ((val > -1 && val < 100) || val === "") {
            if (existingIndex > -1) {
              updatedData[existingIndex] = {
                ...updatedData[existingIndex],

                month: val,
              };
            } else {
              updatedData.push({ id, month: val });
            }
          }
          break;

        case "interestInformation":
          if (val?.split(" ")?.length <= 300) {
            if (existingIndex > -1) {
              updatedData[existingIndex] = {
                ...updatedData[existingIndex],
                interest_information: val,
              };
            } else {
              updatedData.push({ id, interest_information: val });
            }
          }
          break;

        default:
          break;
      }

      return updatedData;
    });
  };

  const handleRemoveDoc = (id, docIndex) => {
    setSelectedData((prev) => {
      const existingIndex = prev.findIndex((item) => item.id === id);
      const updatedData = [...prev];

      if (existingIndex > -1) {
        const detailData =
          updatedData[existingIndex]?.document_details?.length > 0
            ? [...updatedData[existingIndex]?.document_details]
            : [];

        const detailDataNew = detailData?.filter(
          (item, index) => index !== docIndex
        );
        updatedData[existingIndex] = {
          ...updatedData[existingIndex],
          document_details: [...detailDataNew],
        };
      } else {
        updatedData.push({ id, document_details: [] });
      }

      return updatedData;
    });
  };

  return (
    <div className="trainer_wrapper">
      {isLoading && <LoadingLayer />}
      <div className="trainer_container">
        <div className="body_trainer">
          {selectedData &&
            selectedData.map((item: any, index: number) => {
              return (
                <div>
                  <div className="trainer_expert_card" key={index + "interest"}>
                    <img
                      className="image_data"
                      src={item?.image || "/static/dance.png"}
                      alt=""
                    />
                    <div className="container_data">
                      <div className="d-flex">
                        <div>
                          <img
                            className="image_data_resp"
                            src={item?.image || "/static/dance.png"}
                            alt=""
                          />
                        </div>
                        <div>
                          <div className="heading_t">{item?.title}</div>
                          <div className="title_optional">(optional)</div>
                          <div className="form_input">
                            <div className="input_cont">
                              <input
                                type="text"
                                placeholder="0"
                                value={item?.experience || ""}
                                defaultValue={0}
                                onChange={(e) =>
                                  handleChangeValue(e, "year", item.id)
                                }
                                className="text_input"
                              />
                              <div className="end_text">Years</div>
                            </div>
                            <div className="input_cont">
                              <input
                                type="text"
                                value={item?.month || ""}
                                placeholder="0"
                                // defaultValue={0}
                                className="text_input"
                                onChange={(e) => {
                                  if (
                                    Number(e.target.value) >= 0 &&
                                    Number(e.target.value) <= 11
                                  ) {
                                    handleChangeValue(e, "month", item.id);
                                  } else {
                                    showToast(
                                      STRING_CONSTANTS.month_not_more_than_12,
                                      "error"
                                    );
                                  }
                                }}
                              />
                              <div className="end_text">Months</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form_textarea">
                        <div className="area_header">
                          <div className="text_heading">Additional note</div>
                          <div className="header_right_area">
                            <div>
                              {`${
                                (item?.interest_information &&
                                  item?.interest_information?.split(" ")
                                    ?.length) ||
                                0
                              }/ 300`}
                            </div>
                            {item?.interest_information && (
                              <img
                                src="/static/CrossBlack.svg"
                                alt=""
                                onClick={() => handleClearText(item.id)}
                              />
                            )}
                          </div>
                        </div>
                        <textarea
                          placeholder="Write something!"
                          rows={2}
                          className="area_input"
                          spellCheck="false"
                          value={item?.interest_information || ""}
                          onChange={(e) =>
                            handleChangeValue(e, "interestInformation", item.id)
                          }
                        />
                      </div>
                      <div className="add_file_wrapper">
                        <label className="add_file" htmlFor={item.id}>
                          <>
                            Add Qualification
                            <img src="/static/addFilledPrimary.svg" alt="" />
                          </>
                        </label>

                        <input
                          type="file"
                          id={item.id}
                          style={{ display: "none" }}
                          multiple
                          onInput={(e) => handleFileChange(e, item.id)}
                          onChange={(e) => (e.target.value = "")}
                        />
                      </div>

                      <div className="qualification_doc_container d-flex d-md-none">
                        {item?.document_details?.map((file, indexDoc) => (
                          <div
                            key={file?.name + indexDoc}
                            className="doc_wrap_exp"
                          >
                            <img
                              alt="file-icon"
                              src="/static/experience/doc.svg"
                            />
                            <div className="doc_name_wrap">
                              <div className="qual_doc_name_title">
                                <span className="file_name_doc">
                                  {file?.name}
                                </span>
                                {/* <span className="file_name_size">
                              {file?.size} KB
                            </span> */}
                              </div>
                            </div>
                            <img
                              alt=""
                              src="/static/DeleteBlack.svg"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRemoveDoc(item.id, indexDoc)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="qualification_doc_container d-none d-md-flex">
                    {item?.document_details?.map((file, indexDoc) => (
                      <div key={file?.name + indexDoc} className="doc_wrap_exp">
                        <img alt="file-icon" src="/static/experience/doc.svg" />
                        <div className="doc_name_wrap">
                          <div className="qual_doc_name_title">
                            <span className="file_name_doc">{file?.name}</span>
                            {/* <span className="file_name_size">
                              {file?.size} KB
                            </span> */}
                          </div>
                        </div>
                        <img
                          alt=""
                          src="/static/DeleteBlack.svg"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRemoveDoc(item.id, indexDoc)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="btn_footer">
      <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            maxWidth: "768px",
            paddingRight:"20px",
            gap:"24px"
          }}>
        <Button
          btnTxt="Skip"
          className={"btn_skip"}
          style={{
            maxWidth: "372px",
            background: "transparent",
            paddingLeft: "20px",
            paddingRight: "20px",
            color: "#25272d",
          }}
          onClick={() => handleSkipClick()}
        />
        <Button
          btnTxt="Save"
          className={"btn_Qualifications_Save"}
          // disabled={Object.keys(selectedData).length < 1}
          onClick={handleNext}
          isLoading={isLoading}
        />
        </div>
      </div>
    </div>
  );
};

export default Trainer;
