import { useContext, useEffect, useRef, useState } from "react";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import IconFilter from "../../../assets/img/connectIcons/filter.png";
import IconSearch from "../../../assets/img/connectIcons/search.png";
import IconNoSearchedData from "../../../assets/img/connectIcons/no-searched-data.svg";
import cross from "../../../assets/img/cross.png";
import IconFilterGray from "../../../assets/img/connectIcons/filter-gray.png";
import IconMapOrange from "../../../assets/img/connectIcons/map-orange.png";
import Slider from "@mui/material/Slider";
import { useNavigate } from "react-router-dom";
import ConnectFilter from "./ConnectFilter";
import Button from "../../../global/Button";
import useDebounce from "../../../utils/useDebounce";
import {
  fetchMoreIntrestList,
  getIntrestList,
  getItemFromStore,
  scrollToTop,
} from "../../../utils";
import ApiLoader from "../../../global/ApiLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { useForm } from "react-hook-form";
import { apiConstants } from "../../../utils/Constants";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import { initialFilterData } from "../ConnectConstants";
import { mobileDropdownContext } from "./ConectWrapper";
import { UseClickOutside } from "../../Dashboard/Event/UseClickOutside";
import SelectInterestComponentFilter from "../../ListingComponents/SelectInterestComponent/SelectInterestComponentFilter";
import SelectInterestButton from "../../ListingComponents/SelectInterestButton";

const applyFilter: any = () => {};
const SearchFilter = ({
  showMap,
  handleViewToggle,
  handleSearchChange,
  serachData,
  handleSeeAll,
  handleFilterApply = applyFilter,
  isFilterSupported = false,
  handleClearSearch,
  isSearchedSeeAllTrue = false,
  // setIsFilterCleared
  isActiveFilter = false,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({});
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  // const [showMap, setShowMap] = useState(false);
  const modalRef = useRef(null);
  const filterDialogRef = useRef(null);
  const searchRef = useRef(null);
  const [scrolling, setScrolling] = useState(false);
  const [suggestionData, setSuggestionData] = useState([]);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  // const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [error, setError] = useState({
    distance: false,
    minAge: false,
    maxAge: false,
  });
  const [filterData, setFilterData] = useState({
    ...initialFilterData,
  });
  const userData = getItemFromStore("userData")?.interest || [];
  const [selectedInterest, setSelectedInterest] = useState<any>([userData]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const debounceSearch = useDebounce(search, 300);
  const isOpen = useContext(mobileDropdownContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [intrestList, setIntrestList] = useState([]);
  const [isClickedOutside, setIsClickedOutside] = useState(false);
  const closeSuggestionModal = () => {
    setSuggestionModal(false);
    setOpenMobileSuggestionModal(false);
    setIsClickedOutside(true);
    setSearch("");
  };
  UseClickOutside(searchRef, closeSuggestionModal);
  const [tempInterestIds, setTempInterestIds] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [suggestionModal, setSuggestionModal] = useState(false);
  const [isInterestTabOpen, setIsInterestTabOpen] = useState(false);
  const [openMobileSuggestionModal, setOpenMobileSuggestionModal] =
    useState(false);
  const { isMobile } = useCheckDeviceScreen();

  const handleSearchDivClick = (isMobile = false) => {
    if (searchRef.current) {
      searchRef.current.focus(); // Focus the input field
    }
    if (isMobile) {
      setOpenMobileSuggestionModal(true);
      setIsClickedOutside(false);
    }
  };

  const handleScroll = () => {
    // Determine if the page is scrolled
    if (window.scrollY > 0) {
      setScrolling(true);
      setSuggestionModal(false);
      setOpenMobileSuggestionModal(false);
    } else {
      setScrolling(false);
    }
  };

  // const handleActiveFilter = () =>  setIsActiveFilter(true);

  const handleClearClick = (e: any) => {
    setSearch("");
    setSuggestionModal(false);
    setOpenMobileSuggestionModal(false);
    handleClearSearch();
  };

  const handleSeeAllClick = () => {
    setSuggestionModal(false);
    setOpenMobileSuggestionModal(false);
    handleSeeAll();
  };

  const scrollToTopInModal = () => {
    if (filterDialogRef.current) {
      filterDialogRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const handleClearFilterClick = () => {
    setFilterData({ ...initialFilterData });
    setShowFilter(false);
    handleClearSearch("Filter");
    reset();
    // setIsActiveFilter(false);
    scrollToTopInModal();
  };

  const handleSetValue = (field: string, value: any) => {
    setValue(field, value, { shouldDirty: true });
  };

  const handleIntrest = (intrest: any) => {
    const tempList = watch(apiConstants.selectedInterest) || [];
    const tempListIds = [...tempInterestIds];
    let index = tempList.findIndex((intr: any) => intr.id === intrest.id);
    if (index > -1) {
      tempList.splice(index, 1);
      tempListIds.splice(index, 1);
    } else {
      tempList.push(intrest);
      tempListIds.push(intrest.id);
    }

    handleSetValue(apiConstants.selectedInterest, [...tempList]);
    setTempInterestIds([...tempListIds]);
    setFilterData((prev) => ({ ...prev, interest: [...tempList] }));
  };

  const handleIntrestTwo = (levelArray) => {
    const tempList = [...levelArray];
    const tempListIds = [];
    tempList.map((intr, index) => {
      tempListIds.push(intr.id);
    });
    handleSetValue(apiConstants.selectedInterest, [...tempList]);
    setTempInterestIds([...tempListIds]);
    setFilterData((prev) => ({ ...prev, interest: [...tempList] }));
  };
  const handleDistanceChange = (event: any) => {
    let value = event.target.value;
    if (value < 0 || value > 2500) {
      setError((prev: any) => ({ ...prev, distance: true }));
    } else {
      setFilterData((prev: any) => ({
        ...prev,
        distance: value,
      }));
    }
  };

  useEffect(() => {
    if (serachData) {
      setSuggestionData(serachData);
      if (serachData.length !== 0) {
        setSuggestionModal(true);
      }
    }
  }, [serachData]);

  useEffect(() => {
    getIntrestList(
      { search: searchTerm || "", sort: "" },
      setIntrestList,
      setNextUrl,
      setIsLoading
    );
  }, [debouncedSearchTerm]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return isMobile ? (
    <div style={{ position: "relative" }}>
      <div style={{ display: "flex", justifyContent: "start", gap: "10px" }}>
        <div
          style={{
            flex: 1,
          }}
        >
          <div
            className="search-wrapper"
            style={{
              width: "100%",
              zIndex: isClickedOutside
                ? "0"
                : isOpen?.isOpen
                ? "0"
                : scrolling
                ? "996"
                : "998",
            }}
            onClick={() => handleSearchDivClick(true)}
          >
            <img src={IconSearch} className="search-icon" alt="search-icon" />
            <input
              type="text"
              className="input-style"
              placeholder="Search for any user"
              style={{ border: "none" }}
              value={search}
              onKeyDown={(e) => {
                setScrolling(false);
                if (e.key === "Enter") {
                  handleSeeAllClick();
                }
                if (e.key === "Backspace") {
                  setSuggestionModal(false);
                }
              }}
              onChange={(e) => {
                let value = e.target.value;
                setOpenMobileSuggestionModal(true);
                setSearch(value);
                if (value === "") {
                  setSuggestionModal(false);
                } else {
                  handleSearchChange(value);
                }
              }}
              ref={searchRef}
            />
            {search && (
              <span onClick={handleClearClick} className="clearstyle">
                X
              </span>
            )}
          </div>
        </div>
        {isFilterSupported && !openMobileSuggestionModal && (
          <div
            style={{
              borderRadius: "12px",
              height: "48px",
              width: "48px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: isActiveFilter
                ? "1px solid #ee762f"
                : "1px solid #25272D",
              backgroundColor: isActiveFilter ? "#feefe7" : "",
            }}
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          >
            <img src={IconFilterGray} height="16px" width="20px" />
          </div>
        )}

        {!openMobileSuggestionModal && (
          <div
            style={{
              borderRadius: "12px",
              height: "48px",
              width: !isFilterSupported ? "106px" : "48px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: `1px solid ${showMap ? "#EE7830" : "#25272D "}`,
              backgroundColor: isHovered ? "antiquewhite" : "",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleViewToggle}
          >
            <img
              src={showMap ? IconMapOrange : "/static/map-icon.svg"}
              height="24px"
              width="24px"
              // style={{ backgroundColor: showMap ? "antiquewhite" : ""}}
            />
            {!isFilterSupported && (
              <span className="btn-text">{showMap ? "List" : "Map"}</span>
            )}
          </div>
        )}
        {suggestionModal && !isSearchedSeeAllTrue && (
          <div
            className="connect-search-modal"
            style={{ zIndex: scrolling ? "996" : "997", width: "100%" }}
            ref={modalRef}
          >
            {suggestionData?.length > 0 &&
              suggestionData?.map((suggestion) => (
                <div
                  key={suggestion.id}
                  className="searched-suggestion-wrap"
                  onClick={() => navigate(`/our-mates/${suggestion?.id}`)}
                >
                  <div>
                    {suggestion?.profilePictureExtension?.includes("video") ? (
                      <video
                        autoPlay
                        playsInline
                        loop
                        muted
                        src={suggestion?.profilePicture}
                        className="search-profile-icon vid"
                      >
                        <source src={"" || ""} type="video/mp4" />
                      </video>
                    ) : (
                      <img
                        src={
                          suggestion?.profilePicture
                            ? suggestion.profilePicture
                            : "/static/dummyimg.svg"
                        }
                        className="search-profile-icon"
                        alt="profile-img"
                      />
                    )}
                  </div>
                  <div>
                    <div className="searched-name">{suggestion.name}</div>
                    <div className="searched-role">
                      {suggestion.isTrainer ? "Trainer" : suggestion.userType}
                    </div>
                  </div>{" "}
                </div>
              ))}
            {suggestionData?.length === 0 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                    flexDirection: "column",
                    marginBottom: "100px",
                  }}
                >
                  <img
                    src={IconNoSearchedData}
                    alt="search-icon"
                    height="147px"
                    width="147px"
                  />
                  <div
                    style={{
                      width: "156px",
                      height: "14px",
                      borderRadius: "100%",
                      border: "none",
                      backgroundColor: "#F3F3F3",
                      marginTop: "11px",
                    }}
                  ></div>
                  <div
                    style={{
                      maxWidth: "350px",
                      marginTop: "32px",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      lineHeight: "21px",
                    }}
                  >
                    <span style={{ color: "#8F939A" }}>
                      We apologise, but we couldn't find any results that match
                      your search term
                    </span>
                    <span style={{ color: "#25272D" }}>{` "${search}"`}</span>
                    <span style={{ color: "#8F939A" }}>.</span>
                  </div>
                </div>
              </div>
            )}
            {suggestionData?.length !== 0 && (
              <div
                className="see-all-wrapper"
                onClick={() => handleSeeAllClick()}
              >
                See all results
              </div>
            )}
          </div>
        )}
        {showFilter && (
          <ConnectFilter
            show={showFilter}
            onHide={() => setShowFilter(false)}
            ref={filterDialogRef}
          >
            {isInterestTabOpen ? (
              <SelectInterestComponentFilter
                interestPicked={[]}
                handleBackClick={() => setIsInterestTabOpen(false)}
                handleAdd={async (value) => {
                  handleIntrestTwo(value);
                  // alert('hi');
                  // await handleSetValue(apiConstants.selectedInterest, value);
                  await setIsInterestTabOpen(false);
                }}
              />
            ) : (
              <div className="connect-filter-modal">
                <div
                  className="cross-btn"
                  style={isMobile ? { marginTop: "-7px" } : {}}
                  onClick={() => setShowFilter(false)}
                >
                  <img src="/static/cross_circle.png" alt="" />
                </div>
                <div
                  className="heading"
                  style={
                    isMobile ? { fontSize: "16px", lineHeight: "24px" } : {}
                  }
                >
                  Filter & Sort
                </div>
                <div className="filter-container">
                  <div className="f-interest">
                    <SelectInterestButton
                      isOutside={true}
                      handleClick={() => setIsInterestTabOpen(true)}
                      list={watch(apiConstants.selectedInterest) || []}
                      handleRemoveInterest={(id) => {
                        let tempData = watch(
                          apiConstants.selectedInterest
                        )?.filter((item) => item?.id !== id);
                        handleSetValue(apiConstants.selectedInterest, tempData);
                        handleIntrestTwo(tempData);
                      }}
                    />
                    {/* <div className="f-title">Filter by interests</div> */}
                    {/* <div className="f-sub-title">Search the filter</div> */}
                    {/* <div className="interest-input-container">
                    <div className={`form_group `}>
                      <div className={"input_box"}>
                         <span className="input_label">Search Interests</span>
                        <input
                          style={{color:"#EE7830"}}
                          type="text"
                          value={searchTerm}
                          placeholder="Click here to select the interest"
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {watch(apiConstants.selectedInterest) &&
                      watch(apiConstants.selectedInterest).length > 0 && (
                        <div className="apply_btn_container">
                          <div className="flex-fill chip_container mb-4">
                            {watch(apiConstants.selectedInterest).map(
                              (item, idx) => (
                                <div
                                  className="interest_chip_inner"
                                  key={"chip" + idx}
                                >
                                  <img
                                    src={item?.icon}
                                    style={{ height: "15px", width: "15px" }}
                                  />
                                  <span className="chip_text ">
                                    {item?.title}
                                  </span>
                                  <img
                                    src={cross}
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleIntrest(item);
                                    }}
                                  />
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                  </div> */}

                    <div className="wth-dorpwodn ">
                      {searchTerm && (
                        <div className="data-setup">
                          {isLoading ? (
                            <ApiLoader />
                          ) : intrestList?.length ? (
                            <div>
                              <InfiniteScroll
                                className="infinite_scroll_styles"
                                dataLength={intrestList?.length}
                                next={() =>
                                  fetchMoreIntrestList(
                                    nextUrl,
                                    intrestList,
                                    setNextUrl,
                                    setIntrestList,
                                    setIsLoading
                                  )
                                }
                                hasMore={nextUrl ? true : false}
                                loader={<ApiLoader />}
                              >
                                {intrestList?.map(
                                  (item: any, index: number) => {
                                    return (
                                      <span key={index}>
                                        <label
                                          htmlFor={item.title}
                                          onClick={() => {
                                            handleIntrest(item);
                                          }}
                                          className={
                                            selectedInterest.some(
                                              (intr: any) => intr.id === item.id
                                            )
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <img
                                            src={
                                              item?.image || "/static/dance.png"
                                            }
                                            alt=""
                                            width={160}
                                            height={180}
                                          />

                                          <h4
                                            className=""
                                            style={{
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                              width: "140px",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {item.title}{" "}
                                            {watch(
                                              apiConstants.selectedInterest
                                            )?.some(
                                              (intr: any) => intr.id === item.id
                                            ) ? (
                                              <img
                                                className="tick"
                                                src="/static/checked.svg"
                                                alt=""
                                              />
                                            ) : (
                                              ""
                                            )}{" "}
                                          </h4>
                                        </label>
                                      </span>
                                    );
                                  }
                                )}
                              </InfiniteScroll>
                            </div>
                          ) : (
                            <div className="no_data">
                              <img src="/static/Search.svg" alt="" />
                              <h3>{STRING_CONSTANTS.no_result_found}</h3>
                              <p>{STRING_CONSTANTS.please_try_another_term}</p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="f-price-range">
                    <div className="f-title">Sort by nearby</div>
                    <div className="range-slider-container">
                      <Slider
                        aria-label="Temperature"
                        defaultValue={0}
                        value={filterData.distance}
                        color="warning"
                        step={25}
                        min={1}
                        max={2500}
                        slotProps={{ track: "" }}
                        onChange={(e) => handleDistanceChange(e)}
                        style={{ width: "95%", marginLeft: "15px" }}
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "80%",
                          marginLeft: "15px",
                        }}
                      >
                        <div>{0}km</div>
                        <div>{2500}km</div>
                      </div>
                    </div>

                    <div className="minmax-price-container">
                      <div className="min-price price-box">
                        <label>Sort by nearby</label>
                        <input
                          placeholder="0"
                          value={filterData.distance}
                          onChange={(e) => handleDistanceChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="f-age">
                    <div className="f-title">Age </div>
                    <div className="age-options-container">
                      <div
                        className="select-option"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setFilterData((prev) => ({
                            ...prev,
                            allAges: true,
                            minAge: "",
                            maxAge: "",
                          }))
                        }
                      >
                        <input
                          type="radio"
                          value={"all"}
                          name="age"
                          checked={filterData.allAges}
                          // onChange={handleAgeSelectionChange}
                        />
                        All ages
                      </div>
                      <div
                        className="select-option"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setFilterData((prev) => ({
                            ...prev,
                            allAges: false,
                          }))
                        }
                      >
                        <input
                          type="radio"
                          value={"specific"}
                          name="age"
                          checked={!filterData.allAges}
                          onChange={() =>
                            setFilterData((prev) => ({
                              ...prev,
                              allAges: false,
                            }))
                          }
                        />
                        Specific age group
                      </div>
                    </div>

                    {!filterData.allAges && (
                      <div className="minmax-age-container">
                        <div>
                          <div className="min-age age-box">
                            <label>Minimum age</label>
                            <input
                              type="number"
                              name="minAge"
                              placeholder="Enter min age"
                              min={0}
                              value={filterData.minAge}
                              onChange={(e) => {
                                let age = Number(e?.target?.value);
                                setFilterData((prev) => ({
                                  ...prev,
                                  minAge: e.target.value,
                                }));
                                if (age < 0 || age > 99) {
                                  setError((prev) => ({
                                    ...prev,
                                    minAge: true,
                                  }));
                                } else {
                                  setError((prev) => ({
                                    ...prev,
                                    minAge: false,
                                  }));
                                }
                              }}
                            />
                          </div>
                          {error.minAge && (
                            <div style={{ color: "red" }}>
                              Age must be between 0 and 99
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="max-age age-box">
                            <label>Maximum age</label>
                            <input
                              type="number"
                              max={99}
                              name="maxAge"
                              placeholder="Enter max age"
                              value={filterData.maxAge}
                              onChange={(e) => {
                                let value = e?.target?.value;
                                let age;
                                if (value) {
                                  age = Number(value);
                                } else {
                                  age = "";
                                }

                                setFilterData((prev: any) => ({
                                  ...prev,
                                  maxAge: age,
                                }));
                                if (age < 0 || age > 99) {
                                  setError((prev) => ({
                                    ...prev,
                                    maxAge: true,
                                  }));
                                } else {
                                  setError((prev) => ({
                                    ...prev,
                                    maxAge: false,
                                  }));
                                }
                              }}
                            />
                          </div>
                          {error.maxAge && (
                            <div style={{ color: "red" }}>
                              Age must be between 0 and 99
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div></div>
                  </div>
                  <div className="f-gender">
                    <div className="f-title">Gender</div>
                    <div
                      className="gender-options-container"
                      style={{
                        display: "flex",
                        gap: "5px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "400",
                        width: "100%",
                        justifyContent: "space-between",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#25272d ",
                      }}
                    >
                      <div
                        className="select-option"
                        onClick={() =>
                          setFilterData((prev) => ({ ...prev, gender: "M" }))
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="radio"
                          value={"male"}
                          name="gender"
                          checked={filterData.gender === "M" ? true : false}
                          onClick={() =>
                            setFilterData((prev) => ({ ...prev, gender: "M" }))
                          }
                        />
                        Male
                      </div>
                      <div
                        className="select-option"
                        onClick={() =>
                          setFilterData((prev) => ({ ...prev, gender: "F" }))
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="radio"
                          value={"Female"}
                          name="gender"
                          checked={filterData.gender === "F" ? true : false}
                          onClick={() =>
                            setFilterData((prev) => ({ ...prev, gender: "F" }))
                          }
                        />
                        Female
                      </div>
                      <div
                        className="select-option"
                        onClick={() =>
                          setFilterData((prev) => ({ ...prev, gender: "P" }))
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="radio"
                          value={"Both"}
                          name="gender"
                          checked={filterData.gender === "P" ? true : false}
                          onClick={() =>
                            setFilterData((prev) => ({ ...prev, gender: "P" }))
                          }
                        />
                        Prefer not to say
                      </div>
                    </div>
                  </div>
                </div>
                <div className="apply-btn-container">
                  <Button
                    btnTxt="Apply"
                    className="apply-btn"
                    onClick={() => {
                      setShowFilter(false);
                      handleFilterApply(filterData);
                    }}
                  />
                  <div
                    className="clear-filter"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClearFilterClick()}
                  >
                    Clear Filter
                  </div>
                </div>
              </div>
            )}
          </ConnectFilter>
        )}
      </div>
    </div>
  ) : (
    <div style={{ position: "relative" }}>
      <div className="search-filter-wrapper">
        <div
          className="search-wrapper"
          style={{ zIndex: scrolling ? "998" : "1001" }}
          onClick={() => handleSearchDivClick()}
        >
          <img src={IconSearch} className="search-icon" alt="search-icon" />
          <input
            type="text"
            placeholder="Search for any user"
            className="input-style"
            value={search}
            onKeyDown={(e) => {
              setScrolling(false);
              if (e.key === "Enter") {
                handleSeeAllClick();
              }
              if (e.key === "Backspace") {
                setSuggestionModal(false);
              }
            }}
            onChange={(e) => {
              let value = e.target.value;
              setSearch(value);
              if (value === "") {
                setSuggestionModal(false);
              } else {
                handleSearchChange(value);
              }
            }}
            ref={searchRef}
          />
          {search && (
            <span onClick={handleClearClick} className="clearstyle">
              Clear
            </span>
          )}
        </div>
        <div className="filter-map-wrapper">
          {isFilterSupported && (
            <div
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <div
                className={!isActiveFilter && "filter-wrap"}
                style={
                  isActiveFilter
                    ? {
                        display: "flex",
                        backgroundColor: "#feefe7",
                        borderRadius: "12px",
                        border: "1px solid #ee762f",
                        padding: "11.2px 15.2px 11.2px 15.2px",
                        gap: "8px",
                        cursor: "pointer",
                      }
                    : {}
                }
              >
                <img src="/static/filter-sort.svg" alt="" />
                <span className="btn-text">Filter</span>
              </div>
            </div>
          )}
          <div onClick={handleViewToggle}>
            {showMap ? (
              <div className="map-btn-wrapper">
                <img src="/static/tabler_list.svg" alt="" />
                <span className="btn-text">Show List</span>
              </div>
            ) : (
              <div className="map-btn-wrapper">
                <img src="/static/map-icon.svg" alt="" />
                <span className="btn-text">Show Map</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {suggestionModal && !isSearchedSeeAllTrue && (
        <div
          className="connect-search-modal"
          style={{ zIndex: scrolling ? "998" : "999" }}
          ref={modalRef}
        >
          {suggestionData?.length > 0 &&
            suggestionData?.map((suggestion) => (
              <div
                className="searched-suggestion-wrap"
                onClick={() => navigate(`/our-mates/${suggestion?.id}`)}
              >
                <div>
                  {suggestion?.profilePictureExtension?.includes("video") ? (
                    <video
                      autoPlay
                      playsInline
                      loop
                      muted
                      src={suggestion?.profilePicture}
                      className="search-profile-icon vid"
                    >
                      <source src={"" || ""} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      src={
                        suggestion?.profilePicture
                          ? suggestion.profilePicture
                          : "/static/dummyimg.svg"
                      }
                      className="search-profile-icon"
                      alt="profile-img"
                    />
                  )}
                </div>
                <div>
                  <div className="searched-name">{suggestion.name}</div>
                  <div className="searched-role">
                    {suggestion.isTrainer ? "Trainer" : suggestion.userType}
                  </div>
                </div>{" "}
              </div>
            ))}
          {suggestionData?.length === 0 && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "100px",
                  flexDirection: "column",
                  marginBottom: "100px",
                }}
              >
                <img
                  src={IconNoSearchedData}
                  alt="search-icon"
                  height="147px"
                  width="147px"
                />
                <div
                  style={{
                    width: "156px",
                    height: "14px",
                    borderRadius: "100%",
                    border: "none",
                    backgroundColor: "#F3F3F3",
                    marginTop: "11px",
                  }}
                ></div>
                <div
                  style={{
                    maxWidth: "350px",
                    marginTop: "32px",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    lineHeight: "21px",
                  }}
                >
                  <span style={{ color: "#8F939A" }}>
                    We apologise, but we couldn't find any results that match
                    your search term
                  </span>
                  <span style={{ color: "#25272D" }}>{` "${search}"`}</span>
                  <span style={{ color: "#8F939A" }}>.</span>
                </div>
              </div>
            </div>
          )}
          {suggestionData?.length !== 0 && (
            <div
              className="see-all-wrapper"
              onClick={() => handleSeeAllClick()}
            >
              See all results
            </div>
          )}
        </div>
      )}
      {showFilter && (
        <ConnectFilter
          show={showFilter}
          onHide={() => setShowFilter(false)}
          ref={filterDialogRef}
        >
          {isInterestTabOpen ? (
            <SelectInterestComponentFilter
              interestPicked={watch(apiConstants?.selectedInterest) || []}
              handleBackClick={() => setIsInterestTabOpen(false)}
              handleAdd={async (value) => {
                handleIntrestTwo(value);
                // alert('hi');
                // await handleSetValue(apiConstants.selectedInterest, value);
                await setIsInterestTabOpen(false);
              }}
            />
          ) : (
            <div className="connect-filter-modal">
              <div className="cross-btn" onClick={() => setShowFilter(false)}>
                <img src="/static/cross_circle.png" alt="" />
              </div>
              <div className="heading">Filter & Sort</div>
              <div className="filter-container">
                <div className="f-interest">
                  <SelectInterestButton
                    isOutside={true}
                    handleClick={() => setIsInterestTabOpen(true)}
                    list={watch(apiConstants.selectedInterest) || []}
                    handleRemoveInterest={(id) => {
                      let tempData = watch(
                        apiConstants.selectedInterest
                      )?.filter((item) => item?.id !== id);
                      handleSetValue(apiConstants.selectedInterest, tempData);
                      handleIntrestTwo(tempData);
                    }}
                  />
                  {/* <div className="f-title">Filter by interests</div> */}
                  {/* <div className="f-sub-title">Search the filter</div> */}
                  {/* <div className="interest-input-container">
              <div className={`form_group `}>
                <div className={"input_box"}>
                  <span className="input_label">Search Interests</span>
                  <input
                    type="text"
                    value={searchTerm}
                    placeholder="Click here to select the interest"
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                </div>
              </div>
              {watch(apiConstants.selectedInterest) &&
                watch(apiConstants.selectedInterest).length > 0 && (
                  <div className="apply_btn_container">
                    <div className="flex-fill chip_container mb-4">
                      {watch(apiConstants.selectedInterest).map(
                        (item, idx) => (
                          <div
                            className="interest_chip_inner"
                            key={"chip" + idx}
                          >
                            <img
                              src={item?.icon}
                              style={{ height: "15px", width: "15px" }}
                            />
                            <span className="chip_text ">
                              {item?.title}
                            </span>
                            <img
                              src={cross}
                              style={{
                                width: "16px",
                                height: "16px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleIntrest(item);
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
            </div> */}
                  <div className="wth-dorpwodn ">
                    {searchTerm && (
                      <div className="data-setup">
                        {isLoading ? (
                          <ApiLoader />
                        ) : intrestList?.length ? (
                          <div>
                            <InfiniteScroll
                              className="infinite_scroll_styles"
                              dataLength={intrestList?.length}
                              next={() =>
                                fetchMoreIntrestList(
                                  nextUrl,
                                  intrestList,
                                  setNextUrl,
                                  setIntrestList,
                                  setIsLoading
                                )
                              }
                              hasMore={nextUrl ? true : false}
                              loader={<ApiLoader />}
                            >
                              {intrestList?.map((item: any, index: number) => {
                                return (
                                  <span key={index}>
                                    <label
                                      htmlFor={item.title}
                                      onClick={() => {
                                        handleIntrest(item);
                                      }}
                                      className={
                                        selectedInterest.some(
                                          (intr: any) => intr.id === item.id
                                        )
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <img
                                        src={item?.image || "/static/dance.png"}
                                        alt=""
                                        width={160}
                                        height={180}
                                      />

                                      <h4
                                        className=""
                                        style={{
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          width: "140px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {item.title}{" "}
                                        {watch(
                                          apiConstants.selectedInterest
                                        )?.some(
                                          (intr: any) => intr.id === item.id
                                        ) ? (
                                          <img
                                            className="tick"
                                            src="/static/checked.svg"
                                            alt=""
                                          />
                                        ) : (
                                          ""
                                        )}{" "}
                                      </h4>
                                    </label>
                                  </span>
                                );
                              })}
                            </InfiniteScroll>
                          </div>
                        ) : (
                          <div className="no_data">
                            <img src="/static/Search.svg" alt="" />
                            <h3>{STRING_CONSTANTS.no_result_found}</h3>
                            <p>{STRING_CONSTANTS.please_try_another_term}</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="f-price-range">
                  <div className="f-title">Sort by nearby</div>
                  <div className="range-slider-container">
                    <Slider
                      aria-label="Temperature"
                      defaultValue={0}
                      value={filterData.distance}
                      color="warning"
                      step={25}
                      min={1}
                      max={2500}
                      onChange={(e) => handleDistanceChange(e)}
                      style={{ width: "80%", marginLeft: "15px" }}
                    />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "80%",
                        marginLeft: "15px",
                      }}
                    >
                      <div>{0}km</div>
                      <div>{2500}km</div>
                    </div>
                  </div>

                  <div className="minmax-price-container">
                    <div className="min-price price-box">
                      <label>Sort by nearby</label>
                      <input
                        placeholder="0"
                        value={filterData.distance}
                        onChange={(e) => handleDistanceChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="f-age">
                  <div className="f-title">Age </div>
                  <div className="age-options-container">
                    <div
                      className="select-option"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setFilterData((prev) => ({
                          ...prev,
                          allAges: true,
                          minAge: "",
                          maxAge: "",
                        }))
                      }
                    >
                      <input
                        type="radio"
                        value={"all"}
                        name="age"
                        checked={filterData.allAges}
                        // onChange={handleAgeSelectionChange}
                      />
                      All ages
                    </div>
                    <div
                      className="select-option"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setFilterData((prev) => ({
                          ...prev,
                          allAges: false,
                        }))
                      }
                    >
                      <input
                        type="radio"
                        value={"specific"}
                        name="age"
                        checked={!filterData.allAges}
                        onChange={() =>
                          setFilterData((prev) => ({
                            ...prev,
                            allAges: false,
                          }))
                        }
                      />
                      Specific age group
                    </div>
                  </div>

                  {!filterData.allAges && (
                    <div className="minmax-age-container">
                      <div>
                        <div className="min-age age-box">
                          <label>Minimum age</label>
                          <input
                            type="number"
                            name="minAge"
                            placeholder="Enter min age"
                            min={0}
                            value={filterData.minAge}
                            onChange={(e) => {
                              let age = Number(e?.target?.value);
                              setFilterData((prev) => ({
                                ...prev,
                                minAge: e.target.value,
                              }));
                              if (age < 0 || age > 99) {
                                setError((prev) => ({
                                  ...prev,
                                  minAge: true,
                                }));
                              } else {
                                setError((prev) => ({
                                  ...prev,
                                  minAge: false,
                                }));
                              }
                            }}
                          />
                        </div>
                        {error.minAge && (
                          <div style={{ color: "red" }}>
                            Age must be between 0 and 99
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="max-age age-box">
                          <label>Maximum age</label>
                          <input
                            type="number"
                            max={99}
                            name="maxAge"
                            placeholder="Enter max age"
                            value={filterData.maxAge}
                            onChange={(e) => {
                              let value = e?.target?.value;
                              let age;
                              if (value) {
                                age = Number(value);
                              } else {
                                age = "";
                              }

                              setFilterData((prev: any) => ({
                                ...prev,
                                maxAge: age,
                              }));
                              if (age < 0 || age > 99) {
                                setError((prev) => ({
                                  ...prev,
                                  maxAge: true,
                                }));
                              } else {
                                setError((prev) => ({
                                  ...prev,
                                  maxAge: false,
                                }));
                              }
                            }}
                          />
                        </div>
                        {error.maxAge && (
                          <div style={{ color: "red" }}>
                            Age must be between 0 and 99
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div></div>
                </div>
                <div className="f-gender">
                  <div className="f-title">Gender</div>
                  <div className="gender-options-container">
                    <div
                      className="select-option"
                      onClick={() =>
                        setFilterData((prev) => ({ ...prev, gender: "M" }))
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="radio"
                        value={"male"}
                        name="gender"
                        checked={filterData.gender === "M" ? true : false}
                        onClick={() =>
                          setFilterData((prev) => ({ ...prev, gender: "M" }))
                        }
                      />
                      Male
                    </div>
                    <div
                      className="select-option"
                      onClick={() =>
                        setFilterData((prev) => ({ ...prev, gender: "F" }))
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="radio"
                        value={"Female"}
                        name="gender"
                        checked={filterData.gender === "F" ? true : false}
                        onClick={() =>
                          setFilterData((prev) => ({ ...prev, gender: "F" }))
                        }
                      />
                      Female
                    </div>
                    <div
                      className="select-option"
                      onClick={() =>
                        setFilterData((prev) => ({ ...prev, gender: "P" }))
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="radio"
                        value={"Both"}
                        name="gender"
                        checked={filterData.gender === "P" ? true : false}
                        onClick={() =>
                          setFilterData((prev) => ({ ...prev, gender: "P" }))
                        }
                      />
                      Prefer not to say
                    </div>
                  </div>
                </div>
              </div>
              <div className="apply-btn-container">
                <Button
                  btnTxt="Apply"
                  className="apply-btn"
                  onClick={() => {
                    handleFilterApply(filterData);
                    setShowFilter(false);
                  }}
                />
                <div
                  className="clear-filter"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClearFilterClick()}
                >
                  Clear Filter
                </div>
              </div>
            </div>
          )}
        </ConnectFilter>
      )}
    </div>
  );
};

export default SearchFilter;
