import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import moment from "moment";
import DashboardWrapper from "./DashboardWrapper";
import GlobalHeader from "./GlobalHeader";
import BackArrow from "../../assets/img/icons/BackArrow";
import Export from "../../assets/img/icons/Export";
import Chat from "../../assets/img/icons/Chat";
import Start from "../../assets/img/icons/Start";
import Time from "../../assets/img/icons/Time";
import Info from "../../assets/img/icons/Info";
import Contact from "../../assets/img/icons/Contact";
import Calendar from "../../assets/img/icons/Calendar";
import OutlineButton from "../../global/OutlineButton";
import Button from "../../global/Button";
import AllBookings from "./Popups/AllBookings";
import {
  BookingTable,
  BookingTableResponsive,
} from "../../global/BookingsDetailsTable";
import BookingSessions from "./Popups/BookingSessions";
import { makeGetCall, makePatchCall } from "../../utils/Requests";
import { downloadTicket, showToast } from "../../utils";
import endpoints from "../../utils/endpoints";
import ApiLoader from "../../global/ApiLoader";
import Mail from "../../assets/img/icons/Mail";
import Call from "../../assets/img/icons/Call";
import { LISTING_TYPE } from "../../types";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { Link } from "react-router-dom";
import { LeftArrowStickIcon } from "../../utils/Icons/svgs";
import GoogleMapContainer from "../../global/GoogleMapContainer";
import GlobalHeader2 from "./GlobalHeader2";
const Table = ({ columns = [], rows = [] }) => {
  return (
    <div className="custom_table">
      <div className="custom_table-row heading ">
        {columns?.map((item, index) => (
          <div
            key={index + "table"}
            className={`custom_table-header ${
              item?.align === "center" ? "text-center" : "text-start "
            }`}
          >
            {item?.label}
          </div>
        ))}
      </div>

      {rows.map((rowData, index) => {
        return (
          <div className="custom_table-row" key={"rows" + index}>
            {columns.map((colData, idx) => {
              return (
                <div
                  className={`custom_table-cell ${
                    colData?.highlight ? "highlight" : ""
                  }
                  ${
                    colData?.align === "center" ? "text-center" : "text-start "
                  }`}
                >
                  {rowData[colData?.id]}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const TableResponsive = ({ rows = [] }) => {
  return (
    <div className="responsive_table">
      {rows.map((rowData, index) => {
        return (
          <div key={"rows_resp" + index}>
            <div className="container_data">
              <div className="data_name">{rowData?.name}</div>
              <div className="d-flex align-items-center gap-2">
                <div className="text_light">{rowData?.gender}</div>
                <div className="separator" />
                <div className="text_light">{rowData?.age}</div>
              </div>
            </div>
            <div className="container_data">
              <div className="d-flex align-items-center gap-2">
                <Mail />
                <span className="text_light">{rowData?.email}</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <Call />
                <span className="text_light">{rowData?.phone}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default function BookingDetails() {
  const [showAllBookings, setShowAllBookings] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingDetails, setBookingDetails] = useState<any>(null);
  const [currentBookingData, setCurrentBookingData] = useState<any>(null);
  const [totalAttendees, setTotalAttendees] = useState<number>(0);
  const [showBookingSessions, setShowBookingSessions] = useState(false);
  const [showPaycutPopup, setShowPaycutPopup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const { bookingId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const [teamTab, setTeamTab] = useState(1);
  const [mapCenter, setMapCenter] = useState<any>();
  const [mapSidebar, setMapSidebar] = useState<any>(false);

  useEffect(() => {
    if (LISTING_TYPE.S === type || LISTING_TYPE.SPECIAL === type) {
      setIsLoading(true);
      makeGetCall({
        url: `${endpoints.listing_single_booking_details}${bookingId}/?version=v2`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setBookingDetails(res.data);
            let totalAttendee = 0;
            for (const event of res?.data?.eventsBookingDetails) {
              if (event?.id === bookingId) {
                setCurrentBookingData(event);
              }
              totalAttendee = totalAttendee + event.attendees.length;
            }
            setTotalAttendees(totalAttendee);
            setIsLoading(false);
          } else {
            showToast(res?.status?.message, "error");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          showToast(err, "error");
          setIsLoading(false);
        });
    } else if (LISTING_TYPE.M === type) {
      const eventId = queryParams.get("event");
      setIsLoading(true);
      makeGetCall({
        url: `${endpoints.listing_package_booking_details}${bookingId}/?version=v2`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setBookingDetails(res.data);
            for (const event of res?.data?.events) {
              if (event?.id === eventId) {
                let dataValue = {
                  eventData: event,
                  attendees: res.data.attendees,
                };
                setCurrentBookingData({ ...dataValue });
                break;
              }
            }
            // setTotalAttendees(totalAttendee);
            setIsLoading(false);
          } else {
            showToast(res?.status?.message, "error");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          showToast(err, "error");
          setIsLoading(false);
        });
    }
  }, [bookingId]);
  useEffect(() => {
    if (LISTING_TYPE.C === type) {
      setIsLoading(true);
      makeGetCall({
        url: `${endpoints.listing_package_booking_details}${bookingId}/?version=v2`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setBookingDetails(res.data);
            setIsLoading(false);
          } else {
            showToast(res?.status?.message, "error");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          showToast(err, "error");
          setIsLoading(false);
        });
    }
  }, [bookingId]);

  const tableHeader = [
    {
      id: "name",
      label: "Name",
      highlight: true,
    },
    { id: "age", label: "Age", align: "center" },
    { id: "gender", label: "Gender", align: "center" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone Number" },
  ];
  const tableData = currentBookingData?.attendees?.map((item) => {
    return {
      name: `${item.name}`,
      age: `${item.age}Yr`,
      gender: `${item.gender}`,
      email: `${item.email}`,
      phone: `${item.contactNumber ? item.contactNumber : "--"}`,
    };
  });

  const handleBack: any = () => {
    if (LISTING_TYPE.C !== type) {
      navigate("/my-booking");
    } else {
      navigate("/team-membership");
    }
  };

  const handleCancelTeam = () => {
    const formData = new FormData();
    formData.append("status", "CD");
    makePatchCall({
      url: `${endpoints.my_team_memberships}/${bookingId}/`,
      apiPayload: formData,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        setShowPaycutPopup(false);
        setRefresh((prev) => !prev);
        showToast(res.status.message, "success");
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  return (
    <>
      <GlobalHeader2 />
      <div className="booking_details_wrapper_new mt-60">
        <div className="main-section-new">
          <div className="booking_details_container">
            <div className="d-flex justify-content-between gap-3 booking_header">
              {/* <div className="cursor-pointer" onClick={handleBack}>
                <BackArrow />
              </div> */}
              {/* <div className="booking_header_text">Bookings</div> */}
              <div className="cursor-pointer">{/* <Export /> */}</div>
              {/* <div className="d-none d-md-block ">
                <Chat
                  onClick={() => setShowBookingSessions(true)}
                  className="cursor-pointer"
                />
              </div> */}
            </div>
            {isLoading ? (
              <ApiLoader />
            ) : (
              <div className="details_container">
                <div className="d-flex justify-content-between align-items-center gap-3 ">
                  <div className="d-inline">
                    <div className="sub_heading_container">
                      <span>{bookingDetails?.listingTitle}</span>
                      <span className="container_session">
                        <img
                          alt=""
                          src={
                            bookingDetails?.listingType === LISTING_TYPE.M
                              ? "/static/packageweb.svg"
                              : "/static/sessionweb.svg"
                          }
                        />
                        <img
                          alt=""
                          src={
                            bookingDetails?.listingEventType === "P"
                              ? "/static/physicalweb.svg"
                              : "/static/virtualweb.svg"
                          }
                        />
                        {/* <Start />
                        {LISTING_TYPE.M === type
                          ? "package"
                          : LISTING_TYPE.C === type
                          ? "Team"
                          : "Session"} */}
                      </span>
                    </div>
                  </div>

                  {/* <div className="d-none d-md-block ">
                    <Chat />
                  </div> */}
                  {bookingDetails?.chatRoom && (
                    <div className="d-none d-md-block ">
                      <Chat
                        onClick={() => {
                          navigate(
                            `${ROUTE_CONSTANTS.chat}?chatId=${bookingDetails?.chatRoom}`
                          );
                        }}
                        className="cursor-pointer"
                      />
                    </div>
                  )}
                </div>
                <div className="info-box-booking-head">
                  <img
                    alt=""
                    src="/static/info.svg"
                    width="16px"
                    height="16px"
                  />
                  <span>
                    Please note that this page includes all the details
                    regarding your booking.
                  </span>
                </div>
                <div className="details_description">
                  <div className="d-flex justify-content-between ">
                    {/* {LISTING_TYPE.C !== type && (
                      <div className="date_container">
                        <div className="date_text">
                          {moment(currentBookingData?.eventData?.date).format(
                            "dddd, D MMMM"
                          ) || null}
                        </div>
                        <div className="time_container">
                          <div className="mr-3">
                            <Time />
                          </div>
                          {moment(
                            currentBookingData?.eventData?.startTime,
                            "HH:mm:ss.SSSSSS"
                          ).format("h:mm A")}{" "}
                          -{" "}
                          {moment(
                            currentBookingData?.eventData?.endTime,
                            "HH:mm:ss.SSSSSS"
                          ).format("h:mm A")}
                        </div>
                      </div>
                    )} */}
                    {bookingDetails?.chatRoom && (
                      <div className="d-md-none">
                        <Chat
                          onClick={() => {
                            navigate(
                              `${ROUTE_CONSTANTS.chat}?chatId=${bookingDetails?.chatRoom}`
                            );
                          }}
                          className="cursor-pointer"
                        />
                      </div>
                    )}
                  </div>

                  <hr />
                  <div className="summary_container">
                    <div className="summary_heading">Booking Summary</div>
                    {/* <div className="summary_underline" /> */}
                    <div className="summary_desc">
                      <div className="summary_details">Booking status</div>
                      <div className="summary_count">
                        {bookingDetails?.bookingPaymentStatus}
                        {/* $224.00 */}
                      </div>
                    </div>
                    <div className="summary_desc">
                      <div className="summary_details">
                        {bookingDetails?.bookingPaymentStatus === "Processing"
                          ? "Total Amount to be paid"
                          : "Total Amount paid for entire booking"}
                      </div>
                      <div className="summary_count">
                        {"AUD "}
                        {parseFloat(bookingDetails?.amountPaid).toFixed(2)}
                        {/* $224.00 */}
                      </div>
                    </div>
                    <div className="summary_desc">
                      <div className="summary_details">Booking Date & Time</div>
                      <div className="summary_count">
                        {moment
                          .parseZone(bookingDetails?.bookingDate)
                          .format("Do MMM YYYY, h:mm A")}
                      </div>
                    </div>
                    {LISTING_TYPE.C !== type && (
                      <div className="summary_desc">
                        <div className="summary_details">
                          Total No. of Sessions Booked
                        </div>
                        <div className="summary_count">
                          {bookingDetails?.sessionsBooked}
                        </div>
                      </div>
                    )}
                    {/* <div className="summary_underline" />
                     */}
                    <hr />
                  </div>
                  <div className="summary_container">
                    <div className="summary_heading">Booking details</div>
                  </div>
                  <div className="info-box-booking-head">
                    <img
                      alt=""
                      src="/static/info.svg"
                      width="16px"
                      height="16px"
                    />
                    <span>
                      Click on “View details” to know about all the bookings
                      details included in this session.
                    </span>
                  </div>

                  <div className="down_btn_container">
                    <div
                      // onClick={() => {
                      //   if (bookingDetails?.ticketUrl) {
                      //     downloadTicket(bookingDetails?.ticketUrl);
                      //   }
                      // }}
                      onClick={() => {
                        if (
                          LISTING_TYPE.S === type ||
                          LISTING_TYPE.SPECIAL === type
                        ) {
                          setShowAllBookings(true);
                        } else if (LISTING_TYPE.M === type) {
                          setShowBookingSessions(true);
                        }
                      }}
                    >
                      <OutlineButton
                        text="View all booking dates"
                        icon={<Calendar />}
                      />
                    </div>
                    {/* <div className="info_text">
                      <span>
                        <Info />
                      </span>
                      <div>
                        Please note that this ticket is valid for any number of
                        attendees and contains all their details.
                      </div>
                    </div> */}
                  </div>
                  {LISTING_TYPE.C === type && (
                    <div className="team_attendees_tab_container">
                      <div className="team_attendees_tab_wrapper">
                        <div
                          className={`team_tab ${
                            teamTab === 1 ? "active" : ""
                          }`}
                          onClick={() => setTeamTab(1)}
                        >
                          Total Attendees
                        </div>
                        <div
                          className={`team_tab ${
                            teamTab === 2 ? "active" : ""
                          }`}
                          onClick={() => setTeamTab(2)}
                        >
                          Payment details
                        </div>
                      </div>
                    </div>
                  )}

                  {LISTING_TYPE.C === type ? (
                    teamTab === 1 ? (
                      <div className="attendees_container">
                        <div className="attendees_header">
                          Total No. of Attendees :{" "}
                          {bookingDetails?.totalAttendees ||
                            bookingDetails?.attendees?.length}
                        </div>
                        <div className="attendees_list">
                          <div className="list_heading">Attendee Details</div>
                          <div className="d-none d-md-block">
                            <Table
                              columns={tableHeader}
                              rows={bookingDetails?.attendees?.map((item) => {
                                return {
                                  name: `${item.name}`,
                                  age: `${item.age ? item.age + "Yr" : "--"}`,
                                  gender: `${item.gender}`,
                                  email: `${item.email}`,
                                  phone: `${
                                    item.contactNumber
                                      ? item.contactNumber
                                      : "--"
                                  }`,
                                };
                              })}
                            />
                          </div>
                          <div className="d-md-none d-block">
                            <TableResponsive
                              rows={bookingDetails?.attendees?.map((item) => {
                                return {
                                  name: `${item.name}`,
                                  age: `${item.age ? item.age + "Yr" : "--"}`,
                                  gender: `${item.gender}`,
                                  email: `${item.email}`,
                                  phone: `${
                                    item.contactNumber
                                      ? item.contactNumber
                                      : "--"
                                  }`,
                                };
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="payment_details_wrapper">
                        {bookingDetails?.transactionDetails?.map(
                          (item, index) => {
                            return (
                              <>
                                <div className="transactions-info-wrap">
                                  <div className="left">
                                    <img alt="" src="/static/trans-arrow.svg" />
                                    <div>
                                      <div>
                                        {item.paymentDuration === "m"
                                          ? "Paid for month"
                                          : "Paid for year"}
                                      </div>
                                      <div className="date">
                                        {moment(item?.createdAt).format(
                                          "DD MMM, YYYY"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="right">
                                    ${item?.grossAmount}
                                  </div>
                                </div>
                                <div className="divider"></div>
                              </>
                            );
                          }
                        )}
                      </div>
                    )
                  ) : (
                    <>
                      <hr />
                      <div className="attendees_container">
                        <div className="list_heading">Attendee Details</div>

                        <div className="attendees_header">
                          Total No. of Attendees{" "}
                          {type !== "P"
                            ? `(${
                                moment(
                                  currentBookingData?.eventData?.date
                                ).format("Do MMMM") || ""
                              })`
                            : ""}
                          :{" "}
                          {bookingDetails?.totalAttendees ||
                            bookingDetails?.attendees?.length}
                        </div>
                        <div className="attendees_list">
                          <div className="d-none d-md-block">
                            <Table columns={tableHeader} rows={tableData} />
                          </div>
                          <div className="d-md-none d-block">
                            <TableResponsive rows={tableData} />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* {LISTING_TYPE.C !== type && (
                    <div
                      className="view_all_bookings"
                      onClick={() => {
                        if (
                          LISTING_TYPE.S === type ||
                          LISTING_TYPE.SPECIAL === type
                        ) {
                          setShowAllBookings(true);
                        } else if (LISTING_TYPE.M === type) {
                          setShowBookingSessions(true);
                        }
                      }}
                    >
                      View all booking dates
                    </div>
                  )} */}
                  <div className="btn_footer">
                    <button
                      className="common_btn btn_continue"
                      style={{ maxWidth: "398px" }}
                      onClick={() => {
                        if (bookingDetails?.ticketUrl) {
                          downloadTicket(bookingDetails?.ticketUrl);
                        }
                      }}
                    >
                      Download ticket
                    </button>
                    <div className="info-box-booking-head">
                      <img
                        alt=""
                        src="/static/info.svg"
                        width="16px"
                        height="16px"
                      />
                      <span>
                        Please note that this ticket is valid for any number of
                        attendees and contains all their details.
                      </span>
                    </div>
                  </div>

                  <div>
                    <div className="button_wrapper">
                      {LISTING_TYPE.C === type && (
                        <Button
                          btnTxt={` ${
                            bookingDetails?.isCancelled
                              ? "Cancelled"
                              : "Cancel My Team Listing"
                          }`}
                          className={`auto-paycut-btn ${
                            bookingDetails?.isCancelled ? "disable" : ""
                          } my-3`}
                          disabled={bookingDetails?.isCancelled}
                          onClick={() => {
                            setShowPaycutPopup(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {showAllBookings && (
          <AllBookings
            show={showAllBookings}
            onHide={() => setShowAllBookings(false)}
            bookingDetails={bookingDetails}
            currentBookingData={currentBookingData}
            setMapCenter={setMapCenter}
            setMapSidebar={setMapSidebar}
          />
        )}
        {showBookingSessions && (
          <BookingSessions
            show={showBookingSessions}
            onHide={() => setShowBookingSessions(false)}
            bookingDetails={bookingDetails}
            setMapCenter={setMapCenter}
            setMapSidebar={setMapSidebar}
          />
        )}

        {showPaycutPopup ? (
          <div className="modal show">
            <div className="modal-section" style={{ gap: "20px" }}>
              <div className="modal-top-part">
                <button className="close">
                  <img
                    src="/static/dismiss-cross-round-black.svg"
                    alt=""
                    onClick={() => setShowPaycutPopup(false)}
                  />
                </button>

                <h5>My Team Listings Cancellation Confirmation</h5>
                <p style={{ marginBottom: "16px" }}>
                  If you proceed, you will be removed from the team, and from
                  next month onwards you won't be charged for team membership
                  fee.
                </p>
                <div className="modalconfirmpara">
                  Are you sure you want to cancel your membership?
                </div>
                <div className="info-box-wrap">
                  <div className="info_box" style={{ boxShadow: "none" }}>
                    <img src="/static/greyinfo.svg" alt="" /> No refunds will be
                    processed by the system in case of membership cancellation.
                  </div>
                </div>
                <div className="purchase-popup-links-wrap">
                  By tapping below, I also agree to the{" "}
                  <Link to="/policies">Terms of Service,</Link>{" "}
                  <Link to="/policies">Payments</Link> and I acknowledge the{" "}
                  <Link to="/policies">Privacy Policy</Link>.
                </div>
              </div>

              <Button
                btnTxt="Confirm"
                className={"backtologin"}
                onClick={() => {
                  handleCancelTeam();
                }}
              />
              <Button
                btnTxt="Go back"
                className={"gobackmodalbtn backtologin"}
                onClick={() => setShowPaycutPopup(false)}
              />
            </div>
          </div>
        ) : null}

        {mapSidebar ? (
          <div className="map-fixed-sidebar" style={{ zIndex: "1000" }}>
            <div className="map-sidebar-box">
              <div className="map-heading d-none d-md-flex mt-4">
                <img
                  src="/static/cross-black.svg"
                  onClick={() => setMapSidebar(false)}
                  className="close-btn"
                  alt=""
                />
                <p>Maps</p>
              </div>
              <div className="sb-map-container">
                <div className="d-md-none">
                  <GlobalHeader />
                </div>
                <div
                  className="map-back-icon d-md-none"
                  onClick={() => setMapSidebar(false)}
                >
                  <LeftArrowStickIcon />
                </div>
                <GoogleMapContainer height="100%" center={mapCenter} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
