import React, { memo, useEffect, useRef, useState } from "react";
import infoIcon from "../../../assets/img/createListingIcon/info.svg";
import deleteIcon from "../../../assets/img/createListingIcon/delete.svg";
import "./style.scss";
import { showToast } from "../../../utils";
import apiConfig from "../../../utils/endpoints";
import { makeDeleteCall } from "../../../utils/Requests";
import ApiLoader from "../../../global/ApiLoader";
import FileUploadLoader from "../../../global/FileUploadLoader";

type Props = {
  handleMediaFIles?: any;
  images: any;
  setImages: any;
  isMediaUploadComplete: any;
  mediaUploadPayload:any;
  setMediaUploadPayload:any;
};

const FilesUploadComponent = ({
  handleMediaFIles,
  images = [],
  setImages,
  isMediaUploadComplete,
  mediaUploadPayload,
  setMediaUploadPayload
}: Props) => {
  const fileInputRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = () => {
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUpload = (event, drag = false) => {
    let files = [];
    if (drag) {
      files = Array.from(event.dataTransfer.files);
    } else {
      files = Array.from(event.target.files);
    }
    const maxFiles = 5;
    const maxVideoFiles = 1;
    const maxSize = 20 * 1024 * 1024; // 20 MB

    //files limit 5
    if (images.length + files.length > maxFiles) {
      showToast("You can upload a maximum of 5 media files.", "error");
      return;
    }

    // file: video and image
    const invalidFiles = files.filter((file: File) => {
      const isValidType =
        file.type.startsWith("image") || file.type.startsWith("video");

      return !isValidType;
    });
    if (invalidFiles.length > 0) {
      showToast("You can only upload image and video files.", "error");
      return;
    }

    // max file size 20mb
    const largeFiles = files.filter((file: File) => {
      const isValidSize = file.size <= maxSize;

      return !isValidSize;
    });
    if (largeFiles.length > 0) {
      showToast("The maximum file size you can upload is 20MB.", "error");
      return;
    }

    // max one video
    const videoFilesCount = files.filter((file: File) =>
      file.type.startsWith("video")
    ).length;
    const videoFilesCountNewUpload = images.filter((file: any) =>
      file?.documentFileType?.startsWith("video")
    ).length;
    if (videoFilesCount + videoFilesCountNewUpload > maxVideoFiles) {
      showToast(`You can only upload a maximum of one video files.`, "error");
      return;
    }
    const newFiles = Array.from(files).map((file) => ({
      file: file,
      uploadDocumentUrl: "",
      documentFileType: file.type,
      blobUrl: handleBlobUrl(file),
      tempId:  `${Date.now()}-${Math.random()?.toString(36)?.substring(2, 9)}`
    }));
    setImages((prevImages) => [...prevImages, ...newFiles]);
    setIsLoading(true);
  };

  const handleBlobUrl = (file: File) => {
    return URL.createObjectURL(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    handleImageUpload(event, true);
  };

  useEffect(() => {
    handleMediaFIles(images);
  }, [images]);

  useEffect(() => {
    if (isMediaUploadComplete) {
      setIsLoading(false);
    }
  }, [isMediaUploadComplete]);

  const handleDeleteFromPayload = (itemId:any) => {
    if(itemId){
    const filteredPayload = mediaUploadPayload?.filter((payload) => payload?.tempId !== itemId);
    setMediaUploadPayload(filteredPayload);
    }
  }

  const handleImageDelete = async (item, index) => {
    if (item?.id) {
      await makeDeleteCall({
        url: `${apiConfig.listing}${item?.listing}`,
        apiPayload: {
          document_id: item?.id,
        },
        content_type: "application/json",
      })
        .then((res) => {
          if (res.status.code === 200) {
            showToast(res.status.message, "success");
            setImages(images.filter((_, i) => i !== index));
            handleDeleteFromPayload(item?.tempId)
          }
        })
        .catch((err) => showToast(err, "error", "errmess43"));
    } else {
      setImages(images.filter((_, i) => i !== index));
      handleDeleteFromPayload(item?.tempId)
    }
  };

  const UploadContainer = ({ subtitle = false }) => {
    return (
      <div
        className={`container_file0 file_wrap ${dragging ? "dragging" : ""}`}
        onClick={handleUploadClick}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          multiple
          accept="image/*,video/*"
          ref={fileInputRef}
          onInput={handleImageUpload}
          style={{ display: "none" }}
        />
        <div>
          <img src="/static/uploadimg.svg" alt="" />
        </div>
        <div className="upload_file_heading">
          Drag and drop your listing photo or video
        </div>
        {subtitle && (
          <div className="upload_file_subheading">
            Or, click to browse (20 MB max)
          </div>
        )}
      </div>
    );
  };

  const FileContainer = ({ className = "", src, index }) => {
    return (
      <div className={className}>
        {src.documentFileType.startsWith("video") ? (
          <video
            src={src?.uploadDocumentUrl || src?.blobUrl}
            autoPlay
            muted
            className="uploaded_file"
          />
        ) : (
          <img
            src={src?.uploadDocumentUrl || src?.blobUrl}
            alt=""
            className="uploaded_file"
          />
        )}
        <div
          className="delete_container"
          onClick={() => handleImageDelete(src, index)}
        >
          <img src={deleteIcon} alt="" className="uploaded_file" />
        </div>
      </div>
    );
  };

  const handleFilesPreview = (qty = 0, files = []) => {
    switch (qty) {
      case 1:
        return (
          <div className="file_container grid_first">
            <FileContainer
              className="container_file1 file_wrap"
              src={files[0]}
              index={0}
            />
            <UploadContainer />
          </div>
        );

      case 2:
        return (
          <div className="file_container grid_second">
            <FileContainer
              className="container_file1 file_wrap"
              src={files[0]}
              index={0}
            />
            <FileContainer
              className="container_file2 file_wrap"
              src={files[1]}
              index={1}
            />
            <UploadContainer />
          </div>
        );

      case 3:
        return (
          <div className="file_container grid_third">
            <FileContainer
              className="container_file1 file_wrap"
              src={files[0]}
              index={0}
            />
            <FileContainer
              className="container_file2 file_wrap"
              src={files[1]}
              index={1}
            />
            <FileContainer
              className="container_file3 file_wrap"
              src={files[2]}
              index={2}
            />
            <UploadContainer />
          </div>
        );

      case 4:
        return (
          <div className="file_container grid_fourth">
            <FileContainer
              className="container_file1 file_wrap"
              src={files[0]}
              index={0}
            />
            <FileContainer
              className="container_file2 file_wrap"
              src={files[1]}
              index={1}
            />
            <FileContainer
              className="container_file3 file_wrap"
              src={files[2]}
              index={2}
            />
            <FileContainer
              className="container_file4 file_wrap"
              src={files[3]}
              index={3}
            />
            <UploadContainer />
          </div>
        );

      case 5:
        return (
          <div className="file_container grid_fifth">
            <FileContainer
              className="container_file1 file_wrap"
              src={files[0]}
              index={0}
            />
            <FileContainer
              className="container_file2 file_wrap"
              src={files[1]}
              index={1}
            />
            <FileContainer
              className="container_file3 file_wrap"
              src={files[2]}
              index={2}
            />
            <FileContainer
              className="container_file4 file_wrap"
              src={files[3]}
              index={3}
            />
            <FileContainer
              className="container_file5 file_wrap"
              src={files[4]}
              index={4}
            />
          </div>
        );
      default:
        return (
          <div className="file_container grid_empty">
            <UploadContainer subtitle />
          </div>
        );
    }
  };

  return (
    <div>
      <div className="create_listing_wrapper">
        <div className="listing_container">
          <div className="files_wrapper">
            {!isLoading && handleFilesPreview(images.length, images)}
            {isLoading && (
              <div className="file_container">
                
                 <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width:"100%", gap:"42px"}}>
                  <div style={{height:"24px", width:"24px"}}><ApiLoader /></div>
                  <div style={{color:"red"}}>Please wait, uploading ...</div>
                  {/* <FileUploadLoader />  */} 
                </div> 
              </div>
            )}
            <div className="files_info">
              <img src={infoIcon} alt="" />
              Add maximum 4 photos and 1 video upto 20 MB
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(FilesUploadComponent);
