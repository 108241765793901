import { Modal } from "react-bootstrap";
import "./paymentSuccess.scss";
import PrimaryButton from "../../Button/PrimaryButton";

const PaymentSuccessfullPopup = (props) => {
  return (
    <Modal {...props} centered dialogClassName="">
      <Modal.Body style={{ height: "fit-content", padding: "0px" }}>
        <div className="body_wrapper">
          <img
            src="/static/close/closePrimary.svg"
            className="close_btn_payment_success"
            alt=""
            onClick={props?.onHide}
          />
          <img src="/static/gif/successHQ.gif" loading="lazy" className="success_gif" alt="" />
          <div className="payment_success_title">
            {props?.isTeam
              ? "Registration Request Sent"
              : "Booking successfully initiated!"}
          </div>
          <div className="payment_success_sub_title">
            {props?.isTeam
              ? "Your request was successful. You can now continue using Fitness mates"
              : "Your booking process is underway! To view your booking status, please visit the 'My Bookings' section."}
          </div>
          <PrimaryButton onClick={props.clickCheckBooking} fullWidth>
            {props?.isTeam ? "Check My Requests" : "Check My Bookings"}
          </PrimaryButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default PaymentSuccessfullPopup;
