import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { Navigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../utils/RouteConstants";

const ProtectedRoutes = ({ auth, children }) => {
  const isAuth = Boolean(localStorage.getItem("fm_token"));
  const isProfileCompleted = Boolean(auth?.moreUserDetails?.isProfileCompleted);

  if (!isAuth) {
    return <Navigate to={ROUTE_CONSTANTS.register} />;
  } else if (!isProfileCompleted) {
    if (
      !auth?.moreUserDetails?.userType ||
      (auth?.moreUserDetails?.userType === "Guest" &&
        Number(auth?.moreUserDetails?.onboardingStep) === 1)
    ) {
      return <Navigate to={ROUTE_CONSTANTS.welcome} />;
    } else {
      if (auth?.moreUserDetails?.userType === "Member") {
        if (
          auth?.moreUserDetails?.isTrainer ||
          auth?.moreUserDetails?.isBusiness ||
          auth?.moreUserDetails?.isIndividual
        ) {
          return <Navigate to={ROUTE_CONSTANTS.manage_profile} />;
        } else {
          return <Navigate to={ROUTE_CONSTANTS.select_member_type} />;
        }
      } else {
        return <Navigate to={ROUTE_CONSTANTS.manage_profile} />;
      }
    }
  } else {
    return children;
  }
};

ProtectedRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProtectedRoutes);
