import * as React from "react";
import moment, { Moment } from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"; // Import AdapterMoment for MUI
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DATE_FORMAT } from "../../../../constants/helperScheduleDates";

export default function MultiDatePicker({
  label = "",
  name = "",
  watch = (props: any) => null,
  handleSetValue = (key: any, val: any) => null,
  showCalendarIcon = true,
  customClass = "",
  placeholder = "Select recur frequency",
  format = null,
  special = false,
  setMaxDate = false,
  setMinDate = false,
}) {
  const [selectedDates, setSelectedDates] = React.useState(watch(name) || []);

  const handleSelectedDate = (value) => {
    const newDate = moment(value);

    if (newDate.isValid()) {
      setSelectedDates((prev: any) => {
        const formattedNewDate = newDate.format(
          format || DATE_FORMAT.DDMMYYYY_FORMAT
        );
        if (prev && prev.includes(formattedNewDate)) {
          return prev.filter((val) => val !== formattedNewDate);
        } else {
          const updatedDates = [
            ...prev.map((d) =>
              moment(d, format || DATE_FORMAT.DDMMYYYY_FORMAT)
            ),
            newDate,
          ];
          return updatedDates
            .sort((a, b) => a.valueOf() - b.valueOf())
            .map((d) => d.format(format || DATE_FORMAT.DDMMYYYY_FORMAT));
        }
      });
    }
  };

  const handleMaxDate = () => {
    if (!setMaxDate) return null;
    if (selectedDates?.length > 0) {
      const selectedDate = moment(selectedDates[0], format || DATE_FORMAT.DDMMYYYY_FORMAT);
      if (
        selectedDate.month() === moment().month() &&
        selectedDate.year() !== moment().year()
      ) {
        return moment().add(1, "year");
      } else {
        return selectedDate.endOf("month");
      }
    }
    return moment().add(1, "year");
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileDatePicker
        className={customClass}
        value={null}
        onChange={null}
        format={format || DATE_FORMAT.DDMMYYYY_FORMAT}
        label={label}
        showDaysOutsideCurrentMonth
        minDate={
          setMinDate &&
          selectedDates?.length > 0 &&
          !moment(selectedDates[0], format || DATE_FORMAT.DDMMYYYY_FORMAT).isSame(moment(), "month")
            ? moment(selectedDates[0], format || DATE_FORMAT.DDMMYYYY_FORMAT).startOf("month")
            : moment().add(1, "day")
        }
        maxDate={handleMaxDate()}
        onOpen={() => setSelectedDates(watch(name) || [])}
        slots={{
          textField: ({ value, ...props }) => (
            <TextField
              {...props}
              value={
                watch(name)?.length < 2 && watch(name)?.length > 0
                  ? watch(name)?.length === 1
                    ? watch(name)[0]
                    : `${watch(name)?.length} date selected`
                  : watch(name)?.length > 0
                  ? `${watch(name)?.length} dates selected`
                  : null
              }
              placeholder={placeholder}
              readOnly
              focused
              sx={{
                width: "100%",
                ".MuiInputLabel-root": {
                  fontFamily: "Poppins !important",
                  color: "#DCDCDC !important",
                  "&.Mui-focused": {
                    fontSize: "0.93rem",
                    color: "#25272D !important",
                  },
                },
                ".MuiInputBase-input.MuiOutlinedInput-input": {
                  fontFamily: "Poppins !important",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  padding: "13px !important",
                  borderRadius: "10px !important",
                  color:
                    special && watch(name)?.length > 0
                      ? "#EE7830 !important"
                      : "#25272D",
                  "&::placeholder": {
                    color: "#25272D",
                    opacity: 1, // Full opacity
                  },
                },
                ".MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& fieldset": {
                    border: "1px solid #DCDCDC",
                  },
                  "&:hover fieldset": {
                    border: "1px solid #DCDCDC",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid #DCDCDC",
                  },
                },
                ".MuiInput-input": {
                  fontFamily: "Poppins !important",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  padding: "13px !important",
                  borderRadius: "10px !important",
                  color: special ? "#EE7830 !important" : "#25272D",
                },
                ".MuiInput-input:focus": {
                  outline: "none",
                  borderRadius: "10px !important",
                },
              }}
              InputProps={{
                endAdornment: showCalendarIcon ? (
                  <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                    <CalendarMonthIcon
                      sx={{
                        color: "#EE7830",
                      }}
                    />
                  </InputAdornment>
                ) : (
                  ""
                ),
              }}
            />
          ),
          day: ({ day, ...other }) => (
            <PickersDay
              {...other}
              onClick={() => handleSelectedDate(day)}
              sx={
                selectedDates?.includes(
                  moment(day).format(format || DATE_FORMAT.DDMMYYYY_FORMAT)
                )
                  ? {
                      backgroundColor: "rgba(238, 120, 48, 1) !important",
                      color: "#ffffff !important",
                      fontWeight: "600",
                    }
                  : {
                      backgroundColor: "transparent !important",
                      fontWeight: "unset !important",
                    }
              }
              day={day}
            />
          ),
          toolbar: () => {
            return (
              <div
                className="MuiPickersToolbar-root MuiDatePickerToolbar-root MuiPickersLayout-toolbar css-1eurbeq-MuiPickersToolbar-root-MuiDatePickerToolbar-root"
                style={{
                  padding: "8px 16px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="overline"
                  className="MuiTypography-root MuiTypography-overline"
                  sx={{
                    fontFamily: "Poppins",
                    color: "rgba(0, 0, 0, 0.6)",
                    fontWeight: "500",
                    textTransform: "uppercase",
                  }}
                >
                  {label}
                </Typography>

                <div
                  className="MuiPickersToolbar-content"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    className="MuiTypography-root MuiTypography-h4 MuiTypography-alignCenter MuiDatePickerToolbar-title"
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      color: "#25272D",
                    }}
                  >
                    {selectedDates?.length < 2 && selectedDates?.length > 0
                      ? selectedDates.length === 1
                        ? selectedDates[0]
                        : `${selectedDates?.length} date selected`
                      : selectedDates?.length > 0
                      ? `${selectedDates?.length} dates selected`
                      : `No date selected`}
                  </Typography>
                </div>
              </div>
            );
          },
          actionBar: ({ onAccept, onCancel }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "8px 16px",
                gap: "12px",
              }}
            >
              <Button
                sx={{
                  color: "rgba(238, 120, 48, 1)",
                  "&:hover": {
                    background: "rgba(238, 120, 48, 0.1)",
                  },
                }}
                onClick={() => {
                  onCancel();
                  setSelectedDates([]);
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  color: "rgba(238, 120, 48, 1)",
                  "&:hover": {
                    background: "rgba(238, 120, 48, 0.1)",
                  },
                }}
                onClick={() => {
                  onAccept();
                  handleSetValue(name, selectedDates);
                }}
              >
                Ok
              </Button>
            </div>
          ),
        }}
        slotProps={{
          layout: {
            sx: {
              color: "#000000",
              borderRadius: "6px",
              borderWidth: "0px",
              borderColor: "transparent",
              border: "none",
              display: "flex",
              flexDirection: "column",
              ".MuiPickersDay-root:hover": {
                backgroundColor: "rgba(238, 120, 48, 0.2)",
                color: "#000000",
              },
              ".MuiPickersDay-root.Mui-selected": {
                backgroundColor: "transparent",
                color: "#000000",
                fontWeight: "600",
              },
              ".MuiPickersDay-root:focus ": {
                backgroundColor: "transparent",
              },
              ".MuiPickersDay-root.MuiPickersDay-today:not(.Mui-selected)": {
                color: "#000000",
                border: "1px solid #EE7830",
              },
              ".MuiPickersDay-root.MuiPickersDay-today.Mui-disabled:not(.Mui-selected)":
                {
                  color: "rgba(0, 0, 0, 0.38)",
                },
              ".MuiDialogActions-root .MuiButton-root.MuiButton-text": {
                color: "rgba(238, 120, 48, 1)",
                "&:hover": {
                  background: "rgba(238, 120, 48, 0.1)",
                },
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}
