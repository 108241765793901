import WelcomeGif from "../../assets/img/signupWelcome/welcome-modal-gif.gif"

const WelcomeModal = ({ handleContinue = () => null }) => {
  return (
    <div className={"modal show welcome-popup-container"}>
      <div className="welcome-modal-content">
        <div className="welcome-modal-body">
          <div className="img-wrapper">
            <img
              className="gif-class"
              src={WelcomeGif}
              alt="gif"
              loading="lazy"
            />
          </div>
          <div className="welcome-title">Welcome to Fitness mates!</div>
          <div className="sub-title">
            You’ve successfully subscribed! Now, enjoy all the amazing benefits
            of Fitness mates. Complete your profile to get the best experience,
            and start exploring the app today!
          </div>
          <div className="btn-wrapper ">
            <button className="btn-continue" onClick={handleContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;
