import React from "react";
import "./style.scss";
import moment from "moment";
import {
  DATE_FORMAT,
  FORM_KEYS,
} from "../../../../constants/helperScheduleDates";

type Props = {
  [key: string]: any;
};

const MeetingLinkFields = ({
  setValue,
  getValues,
  watch,
  data,
  indexing,
}: Props) => {
  const formattedDate = moment(
    data?.date,
    DATE_FORMAT.SORT_MONTH_FORMAT
  ).format(DATE_FORMAT.SORT_MONTH_FORMAT);

  const getTimeSlots = (start, end) => {
    const formattedStartTime = moment(start).format(DATE_FORMAT.HRS_12_FORMAT);
    const formattedEndTime = moment(end).format(DATE_FORMAT.HRS_12_FORMAT);

    return `${formattedStartTime} - ${formattedEndTime}`;
  };

  return (
    <div className="meeting_link_field_wrapper">
      <div className="date_stamp_wrap">
        <div className="time_date">
          <div className="date_stamp">
            <img src="/static/createListingIcon/calenderGrey.svg" alt="" />
            <span>{formattedDate}</span>
          </div>
          <div className="time_stamp">
            <img src="/static/createListingIcon/clockGrey.svg" alt="" />
            <span>{getTimeSlots(data?.startTime, data?.endTime)}</span>
          </div>
        </div>
      </div>
      <div className="vertical_line" />
      <div className="link_box">
        <div className="link_box_wrap">
          <img
            className="start_icon"
            src="/static/createListingIcon/linkGrey.svg"
            alt=""
          />
          <div className="input_container">
            <input
              className="input_item"
              value={watch(
                `${FORM_KEYS.DATE_TIME_SLOTS}[${indexing}].${FORM_KEYS.VIRTUAL_LINK}`
              )}
              onChange={(e) =>
                setValue(
                  `${FORM_KEYS.DATE_TIME_SLOTS}[${indexing}].${FORM_KEYS.VIRTUAL_LINK}`,
                  e.target.value
                )
              }
              placeholder="Add meeting link here"
            />
            {/* {watch(`virtual_links_multiple[${index}].virtual_link`) && (
              <img
                className="clear_icon"
                src="/static/createListingIcon/clearGrey.svg"
                alt=""
                onClick={() =>
                  handleSetValue(
                    `virtual_links_multiple[${index}].virtual_link`,
                    ""
                  )
                }
              />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingLinkFields;
