import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import InputField from "../../global/InputField";
import { apiConstants } from "../../utils/Constants";
import { loginForm } from "../../utils/FormConfig";
import { SignInValidations } from "./AuthValidation";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../global/Button";
import { makePostCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import {
  removeLocalStorage,
  setLocalStorage,
  showToast,
} from "../../utils";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import SocialLogin from "./SocialLogin";
import FullLoader from "../../global/FullLoader";
import Logo from "../../assets/img/icons/Logo";
import { useAppDispatch } from "../../store/store";
import { authSuccess } from "../../store/auth/action";
import { stepsNavigation } from "../../constants/helperOnboarding";
import { useApiRequest } from "../../hooks/useApiRequest";
const signupBg1 = require("../../assets/img/signup-bg-1.jpg");
const signupBg2 = require("../../assets/img/signup-bg-2.jpg");
const signupBg3 = require("../../assets/img/signup-bg-3.jpg");

const SignIn = () => {
  const navigate = useNavigate();
  const getUserProfile = useApiRequest();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [passType, setPassType] = useState("password");
  const [socialLoading, setSocialLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);


  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: loginForm,
    resolver: yupResolver(SignInValidations),
    mode: "onSubmit",
  });


  const handleLogin = () => {
    
    handleSubmit((data) => {
      setIsLoading(true);
      const decoData: any = { ...data, email: data.email.toLowerCase() };

      let formData: any = new FormData();
      formData.append("password", decoData.password);
      formData.append(
        "client_id",
        process.env.REACT_APP_IS_DEV === "true"
          ? process.env.REACT_APP_CLIENT_ID
          : process.env.REACT_APP_IS_LOCAL === "true"
          ? process.env.REACT_APP_LOCAL_CLIENT_ID
          : process.env.REACT_APP_STAGING_CLIENT_ID
      );
      formData.append(
        "client_secret",
        process.env.REACT_APP_IS_DEV === "true"
          ? process.env.REACT_APP_CLIENT_SECRET
          : process.env.REACT_APP_IS_LOCAL === "true"
          ? process.env.REACT_APP_LOCAL_CLIENT_SECRET
          : process.env.REACT_APP_STAGING_CLIENT_SECRET
      );
      formData.append("grant_type", "password");
      formData.append("username", decoData.email);

      makePostCall({
        url: endpoints.login,
        apiPayload: formData,
        content_type: "multipart/form-data",
      })
        .then( async (res) => {
          if (res.status.code === 200) {
            showToast(res.status.message, "success");

            dispatch(
              authSuccess({
                token: res.token.accessToken,
              })
            );
            setLocalStorage("access_token", res.token.accessToken);
            setLocalStorage(
              "isProfileComplete",
              res?.data?.isProfileCompleted || false
            );
            if (res.token.accessToken && !res?.data?.isProfileCompleted) {
              setLocalStorage("skip", true);
              setTimeout(() => {
                removeLocalStorage("skip");
              }, 1000);
            }

            setLocalStorage("refresh_token", res.token.refreshToken);

            const resp = await getUserProfile();

            if (resp?.status?.code === 200) {
              stepsNavigation(navigate, resp?.data);
            }
            
          } else {
            showToast(res.status.message || res.error.message, "error");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          showToast(err, "error");
          setIsLoading(false);
        });
    })();
  };
  useEffect(() => {
    sliderRef.current.slickGoTo(currentSlide);
  }, [currentSlide]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
  };
  return (
    <>
      <div className=" d-md-none logo_nav ">
        <Link to="/">
          <img src="/static/logo_small.svg" alt="" />
        </Link>
      </div>
      <div className="login constiner-fluid p-0 m-0 overflow-hidden">
        <div style={{ height: "100vh" }} className="row p-0 m-0">
          <div
            className="col1 d-none d-md-block col-5 px-0 overflow-hidden"
            style={{ height: "100vh" }}
          >
            <div className="img-slider" style={{ height: "100%" }}>
              <Slider {...settings} ref={sliderRef} style={{ height: "100%" }}>
                <div className="slider-item">
                  <img src={signupBg1} alt="" style={{ objectFit: "cover" }} />
                  <div className="overlay"></div>
                </div>
                <div className="slider-item">
                  <img src={signupBg2} alt="" style={{ objectFit: "cover" }} />
                  <div className="overlay"></div>
                </div>
                <div className="slider-item">
                  <img src={signupBg3} alt="" style={{ objectFit: "fill" }} />
                  <div className="overlay"></div>
                </div>
              </Slider>
            </div>
            <div className="txtb" style={{ width: "100%" }}>
              <div className="logo">
                <Logo fill="#FFFFFF" />
                <p style={{ color: "#FFFFFF" }}>
                  {currentSlide === 0
                    ? "Find your ideal fitness mates and activities, anywhere anytime."
                    : currentSlide === 1
                    ? "Connecting fitness and health enthusiasts to join classes and activities together"
                    : "Create or join a health or fitness activity that suits your needs today."}
                </p>
              </div>
              <div className="guest-button">
                <div className="slider-box">
                  <div className="slider-bg"></div>
                  {[0, 1, 2].map((index) => (
                    <div
                      key={index}
                      className={`slider ${
                        currentSlide === index ? "active" : ""
                      }`}
                      onClick={() => setCurrentSlide(index)}
                      style={{ transform: `translateX(${index * 43}px)` }}
                    ></div>
                  ))}
                </div>
                {/* <NavLink
                  to={ROUTE_CONSTANTS.events}
                  style={{ color: "#FFFFFF" }}
                >
                  Continue as Guest
                </NavLink> */}
              </div>
            </div>
          </div>
          <div className="col2 col-12 col-md-7 mx-auto login-right-container overflow-y-auto vh-100">
            {socialLoading ? (
              <FullLoader loaderClass={"fullLoader"} />
            ) : (
              <form onSubmit={handleSubmit(handleLogin)}>
                <h1 className="l-onboarding-text">Welcome!</h1>
                <p className="l-onboarding-info">
                  Please sign in to access your account and connect with your
                  fitness community.
                </p>
                <InputField
                  type={"email"}
                  className={"floating_input"}
                  name={apiConstants.email}
                  placeholder={"Enter Email"}
                  inputLabel={"Email Address"}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />
                <div
                  className="password"
                  style={{
                    marginTop: "18px",
                  }}
                >
                  <InputField
                    type={passType}
                    className={"floating_input"}
                    name={apiConstants.password}
                    placeholder={"Enter password"}
                    inputLabel={"Password"}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    addIconPadding
                    fieldType="password"
                    passType={passType}
                    setPassType={setPassType}
                  />
                </div>

                <div className="forgot_password_wrap">
                  <Link
                    to={ROUTE_CONSTANTS.forgot_password}
                    className="forgot_password"
                  >
                    {STRING_CONSTANTS.forgot_password}
                  </Link>
                </div>

                <Button
                  type="submit"
                  btnTxt="Login account"
                  isLoading={isLoading}
                />
                <div className="continue_container">
                  <div className="line"></div>
                  <div className="continue_with ">or continue with</div>
                  <div className="line"></div>
                </div>

                <SocialLogin setSocialLoading={setSocialLoading} />
                <div className="no_account ">
                  Don’t have an account?
                  <Link to={ROUTE_CONSTANTS.register}> Create account.</Link>
                </div>
                <div className="policies ">
                  <Link
                    to={ROUTE_CONSTANTS.policy}
                    target={"_blank"}
                    className="policies "
                  >
                    Terms and Privacy
                  </Link>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
