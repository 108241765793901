import { makeGetCall } from "../utils/Requests";
import apiConfig from "../utils/endpoints";
import { authSuccess } from "../store/auth/action";
import { showToast } from "../utils";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../utils/RouteConstants";
import { useAppDispatch } from "../store/store";
import { handleData } from "../constants/helperOnboarding";

export const useApiRequest = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getUserProfile = async () => {
    try {
      const profileResponse = await makeGetCall({ url: apiConfig.profile });

      if (profileResponse?.status?.code === 200) {
        dispatch(
          authSuccess({
            moreUserDetails: handleData(profileResponse?.data?.userDetail),
            businessDetail: profileResponse?.data?.businessDetail || null,
            trainerData: profileResponse?.data?.trainerData || null,
          })
        );
      } else {
        showToast("Failed to fetch profile data", "error");
      }

      return profileResponse;
    } catch (err) {
      navigate(ROUTE_CONSTANTS.login);
      showToast(err.message || "An error occurred", "error");
      return null;
    }
  };

  return getUserProfile;
};
