import * as React from "react";
import { styled } from "@mui/system";
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
  numberInputClasses,
} from "@mui/base/Unstable_NumberInput";
import "./style.scss";

export default function CustomNumberInput({
  endAdornmentText = "",
  label = "",
  customClass = "",
  name = "",
  watch = (props: any) => null,
  handleSetValue = (key: any, val: any) => null,
  min = 0,
  max = null,
  isDisabled = false,
  isReadOnly=false
}) {
  const [value, setValue] = React.useState(watch(name) || 0);

  const handleChange = (event: any) => {
    const inputValue = event?.target?.value;
    if (
      !isNaN(Number(inputValue)) &&
      Number(inputValue) > 0 &&
      inputValue.length <= 3
    ) {
      setValue(Number(inputValue));
      handleSetValue(name, Number(inputValue));
    }
  };

  const NumberInput = React.forwardRef(function CustomNumberInput(
    props: NumberInputProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) {
    return (
      <BaseNumberInput
        slots={{
          root: InputRoot,
          input: InputElement,
          incrementButton: Button,
          decrementButton: Button,
        }}
        slotProps={{
          incrementButton: {
            children: (
              <span
                className="arrow"
                onClick={() => {
                  setValue((prev) => prev + 1);
                  handleSetValue(name, Number(value + 1));
                }}
              >
                <img src="/static/field_up_arrow.svg" alt="" />
              </span>
            ),
          },
          decrementButton: {
            children: (
              <span
                className="arrow"
                onClick={() => {
                  setValue((prev) => {
                    if (prev > 0) {
                      return prev - 1;
                    }
                    return prev;
                  });
                  handleSetValue(name, Number(value - 1));
                }}
              >
                <img src="/static/field_down_arrow.svg" alt="" />
              </span>
            ),
          },
        }}
        {...props}
        ref={ref}
      />
    );
  });

  return (
    <div className={"customNumberInput " + customClass}>
      <label htmlFor="custom-number-input" className="label">
        {label}
      </label>
      <NumberInput
        disabled={isDisabled}
        readOnly={isReadOnly}
        defaultValue={value}
        min={min}
        max={max}
        onChange={handleChange}
        endAdornment={
          endAdornmentText && (
            <InputAdornment className="endAdornment">
              {endAdornmentText}
            </InputAdornment>
          )
        }
      />
    </div>
  );
}

const InputAdornment = styled("div")(
  ({ theme }) => `
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  grid-row: 1/3;
  color: ${theme.palette.mode === "dark" ? grey[500] : grey[700]};
  width: fit-content;
  margin-right: 6px;
`
);

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#25272D",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const InputRoot = styled("div")(
  ({ theme }) => `
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  display: grid;
  grid-template-columns: auto 1fr auto 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  padding: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  width: 170px;

  .base--disabled{
    color: #BCBCBC;
  }
  &.base--disabled{
    color: #BCBCBC;
    .endAdornment{
      color: #BCBCBC;
    }
  }

  &.${numberInputClasses.focused} {

  }

  &:hover {

  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const InputElement = styled("input")(
  ({ theme }) => `
  line-height: 1.5;
  grid-row: 1/3;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
  width: 100%;
  padding-right: 6px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
`
);

const Button = styled("button")(
  ({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 19px;
  height: 20px;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 0;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 4/5;
    grid-row: 1/2;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};

    &:hover {
      cursor: pointer;
    }
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 4/5;
    grid-row: 2/3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};

    &:hover {
      cursor: pointer;
    }
  }

  & .arrow {
    transform: translateY(-1px);
  }

  & .arrow {
    transform: translateY(-1px);
  }
`
);
