import React from "react";
import { apiConstants } from "../../../utils/Constants";
import "./style.scss";
import InputField from "../../../global/InputField";
import InfoCard from "../InfoCard/InfoCard";
import HorizontalLine from "../HorizontalLine/HorizontalLine";

type Props = {
  [key: string]: any;
};

const MeetingLink = (props: Props) => {
  const {
    watch,
    handleSetValue,
    control,
    errors,
    setValue,
    isTeam = false,
  } = props;
  return (
    <div className="meeting_link_container">
      {!isTeam && (
        <div className="header_text">
          Would you like to use the same meeting link for all events?
        </div>
      )}
      {!isTeam && (
        <div className="button_container">
          <div
            className={`button_wrap ${
              watch(apiConstants.multiple_virtual_links_allowed) === true
                ? "selected"
                : ""
            }`}
            onClick={() =>
              handleSetValue(apiConstants.multiple_virtual_links_allowed, true)
            }
          >
            Yes
          </div>
          <div
            className={`button_wrap ${
              watch(apiConstants.multiple_virtual_links_allowed) === false
                ? "selected"
                : ""
            }`}
            onClick={() =>
              handleSetValue(apiConstants.multiple_virtual_links_allowed, false)
            }
          >
            No
          </div>
        </div>
      )}

      {watch(apiConstants.multiple_virtual_links_allowed) === true || isTeam ? (
        <div className="meeting_link_form_wrap">
          <div className="heading_2">
            {isTeam
              ? "The URL you provide will be assigned to the team you are creating."
              : "The URL you provide will be applied to all events created within this package."}
          </div>
          <div>
            <InputField
              type={"text"}
              className={"floating_input"}
              name={apiConstants.virtual_links_single}
              inputLabel={"Add Meeting URL"}
              placeholder={"Enter URL"}
              control={control}
              errors={errors}
              setValue={setValue}
            />
          </div>
          <div>
            <InfoCard
              title="
              Please include only web URLs for Google Meet, Zoom, and Microsoft Teams here."
            />
          </div>
        </div>
      ) : watch(apiConstants.multiple_virtual_links_allowed) === false ? (
        <div className="heading_2">
          You can add multiple web url for your each event at the time of its
          creation.
        </div>
      ) : null}
      <HorizontalLine />
    </div>
  );
};

export default MeetingLink;
