import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../global/Button";
import FullLoader from "../../global/FullLoader";
import InputField from "../../global/InputField";
import {
  setLocalStorage,
  showToast,
} from "../../utils";
import { apiConstants } from "../../utils/Constants";
import endpoints from "../../utils/endpoints";
import { registrationForm } from "../../utils/FormConfig";
import { makePostCall } from "../../utils/Requests";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { RegistrationValidations } from "./AuthValidation";
import SocialLogin from "./SocialLogin";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../assets/img/icons/Logo";
import { authSuccess } from "../../store/auth/action";
import { useAppDispatch } from "../../store/store";
import { handleData } from "../../constants/helperOnboarding";

const signupBg1 = require("../../assets/img/signup-bg-1.jpg");
const signupBg2 = require("../../assets/img/signup-bg-2.jpg");
const signupBg3 = require("../../assets/img/signup-bg-3.jpg");

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [socialLoading, setSocialLoading] = useState(false);
  const [passType, setPassType] = useState("password");
  const [confirmPassType, setConfirmPassType] = useState("password");
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);
  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
  const isValid = (testValue:string) => passwordRegex.test(testValue);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: registrationForm,
    resolver: yupResolver(RegistrationValidations),
    mode: "onSubmit",
  });

  useEffect(() => {
    sliderRef.current.slickGoTo(currentSlide);
  }, [currentSlide]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
  };

  const handleLogin = () => {
    handleSubmit((data) => {
      let formData: any = new FormData();
      // pass and confirm password should be same and valid;
      let isValidPassword = false ;
      // const handleAllWhiteSpacesRemove = (str:any) => str?.replace(/\s+/g, '');
      const finalPassword = data?.password;
      const finalConfirmedPassword = data?.confirmPassword ;
      // total scenario -
      // 1. remove all white spaces;
      // 2. then pass and confirm pass must be same and atleast 8 characters ;

      if((finalPassword === finalConfirmedPassword) && (finalPassword?.length > 7)){
        // removing standard password format and keeping the password lenght must be at lest 8 characters & this can be any value
       // isValid(data?.password) ;
        isValidPassword = true;
      }else{
        if(finalPassword !== finalConfirmedPassword ){
          showToast("Please enter same password", "error");
        } else  if(finalPassword?.length < 8 || finalConfirmedPassword?.length < 8){
          showToast("Password or confirm password should be atleast 8 character long", "error");
        }
        return
      }

      formData.append("email", data.email.toLowerCase());
      formData.append("onboardingStep", 1);
      formData.append("password", data.password);
      if (localStorage.getItem("attribute")) {
        formData.append("attribution", localStorage.getItem("attribute"));
      }
      //for pre registration form
      if (process.env.REACT_APP_IS_PROD === "true") {
        formData.append("pre_launch_user", true);
      }

      formData.append(
        "client_id",
        process.env.REACT_APP_IS_DEV === "true"
          ? process.env.REACT_APP_CLIENT_ID
          : process.env.REACT_APP_IS_LOCAL === "true"
          ? process.env.REACT_APP_LOCAL_CLIENT_ID
          : process.env.REACT_APP_STAGING_CLIENT_ID
      );
      formData.append(
        "client_secret",
        process.env.REACT_APP_IS_DEV === "true"
          ? process.env.REACT_APP_CLIENT_SECRET
          : process.env.REACT_APP_IS_LOCAL === "true"
          ? process.env.REACT_APP_LOCAL_CLIENT_SECRET
          : process.env.REACT_APP_STAGING_CLIENT_SECRET
      );
      setIsLoading(true);
      if(isValidPassword){
      makePostCall({
        url: endpoints.profile,
        apiPayload: formData,
        content_type: "multipart/form-data",
      })
        .then((res) => {
          if (res.status.code === 200) {
            dispatch(
              authSuccess({
                token: res.token.accessToken,
                moreUserDetails: handleData(res?.data || {}),
                loggedIn: true,
              })
            );
            setLocalStorage("access_token", res?.token.accessToken);
            setLocalStorage("refresh_token", res?.token.refreshToken);
            showToast(res.status.message, "success");
            setIsLoading(false);

            if (process.env.REACT_APP_IS_PROD === "true") {
              navigate(ROUTE_CONSTANTS.manage_profile);
              setLocalStorage("userType", "Member");
            } else {
              navigate(ROUTE_CONSTANTS.welcome);
              setLocalStorage("userType", res.data.userType || "Guest");
            }
          } else {
            showToast(res.status.message, "error");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          showToast(err?.status?.message, "error");
          setIsLoading(false);
        });
      }
    })();
  };
  const imgchange = [0, 1, 2];
  return (
    <>
      <div className=" d-md-none logo_nav ">
        <Link to="/">
          <img src="/static/logo_small.svg" alt="" />
        </Link>
      </div>
      <div className="login constiner-fluid p-0 m-0 overflow-hidden">
        <div style={{ height: "100vh" }} className="row p-0 m-0 ">
          <div
            className="col1 d-none d-md-block col-5  overflow-hidden"
            style={{ paddingLeft: "0px", paddingRight: "0px", height: "100vh" }}
          >
            <div className="img-slider">
              <Slider {...settings} ref={sliderRef}>
                <div className="slider-item">
                  <img src={signupBg1} alt="" style={{ objectFit: "cover" }} />
                  <div className="overlay"></div>
                </div>
                <div className="slider-item">
                  <img src={signupBg3} alt="" style={{ objectFit: "cover" }} />
                  <div className="overlay"></div>
                </div>
                <div className="slider-item">
                  <img src={signupBg2} alt="" style={{ objectFit: "cover" }} />
                  <div className="overlay"></div>
                </div>
              </Slider>
            </div>
            <div className="txtb" style={{ width: "100%" }}>
              <div className="logo">
                <Logo fill="#FFFFFF" />
                <p style={{ color: "#FFFFFF" }}>
                  {currentSlide === 0
                    ? "Find your ideal fitness mates and activities, anywhere anytime."
                    : currentSlide === 1
                    ? "Connecting fitness and health enthusiasts to join classes and activities together"
                    : "Create or join a health or fitness activity that suits your needs today."}
                </p>
              </div>
              <div className="guest-button">
                <div className="slider-box">
                  <div className="slider-bg"></div>
                  {imgchange.map((index) => (
                    <div
                      key={index}
                      className={`slider ${
                        currentSlide === index ? "active" : ""
                      }`}
                      style={{ transform: `translateX(${index * 43}px)` }}
                    ></div>
                  ))}
                </div>
                {
                  // process.env.REACT_APP_IS_PROD !== "true" ? (
                  //   <NavLink
                  //     to={ROUTE_CONSTANTS.events}
                  //     style={{ color: "#FFFFFF" }}
                  //   >
                  //     Continue as Guest
                  //   </NavLink>
                  // ) : null
                }
              </div>
            </div>
          </div>
          <div className="col2 col-12 col-md-7 mx-auto login-right-container overflow-y-auto vh-100">
            {socialLoading ? (
              <FullLoader />
            ) : (
              <form onSubmit={handleSubmit(handleLogin)}>
                <h1>Let’s get started!</h1>
                <p>
                  Whether you're looking to find workout partners or create
                  fitness events, we got you covered!
                </p>

                <InputField
                  className={"floating_input"}
                  type={"email"}
                  name={apiConstants.email}
                  placeholder={"Enter Email"}
                  inputLabel={"Email Address"}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />
                <div
                  className="password"
                  style={{
                    marginTop: "18px",
                  }}
                >
                  <InputField
                    type={passType}
                    className={"floating_input"}
                    name={apiConstants.password}
                    placeholder={"Create Password"}
                    inputLabel={"Password"}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    // extramsg={"Minimum 8 characters."}
                    addIconPadding
                    fieldType="password"
                    passType={passType}
                    setPassType={setPassType}
                  />
                </div>
                <div
                  className="password"
                  style={{
                    marginTop: "18px",
                  }}
                >
                  <InputField
                    type={confirmPassType}
                    className={"floating_input"}
                    name={apiConstants.confirmPassword}
                    placeholder={"Enter Confirm Password"}
                    inputLabel={"Confirm Password"}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    extramsg={
                      "Create a password and confirm password with at least 8 characters."
                    }
                    addIconPadding
                    fieldType="password"
                    passType={confirmPassType}
                    setPassType={setConfirmPassType}
                  />
                </div>
                <div className="note">
                  By pressing “Create account” you agree to our
                  <Link to={ROUTE_CONSTANTS.policy} target={"_blank"}>
                    {" "}
                    Terms & Privacy{" "}
                  </Link>
                </div>

                <Button
                  type="submit"
                  btnTxt="Create account"
                  isLoading={isLoading}
                />

                <div className="continue_container">
                  <div className="line"></div>
                  <div className="continue_with ">or continue with</div>
                  <div className="line"></div>
                </div>
                <SocialLogin setSocialLoading={setSocialLoading} />
                <div className="no_account">
                  Already have an account?
                  {process.env.REACT_APP_IS_PROD !== "true" ? (
                    <Link to={ROUTE_CONSTANTS.login}> Sign in.</Link>
                  ) : null}
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
