/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import InputField from "../../../global/InputField";
import {
  convertBytesToMB,
  formatBytes,
  showToast,
  handleFileUpload,
} from "../../../utils";
import { apiConstants } from "../../../utils/Constants";
import { ExpertiseDefaultValue } from "../../../utils/FormConfig";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import {
  makeDeleteCall,
  makeGetCall,
  makePostCall,
  makePatchCall,
} from "../../../utils/Requests";
import endpoints from "../../../utils/endpoints";
import { useOutsideClick } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import LoadingLayer from "../../ConnectUserProfile/LoaderLayer";

interface ExperienceCardProps {
  expdata?: any;
  item: any;
  updateExpById: any;
  userAge: any;
  handleNext?: any;
  handleSave: any;
  refetchList: any;
}
const ExperienceCard = (props: ExperienceCardProps) => {
  const { item, updateExpById, userAge, refetchList } = props;
  const [open, setOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [documentDetails, setDocumentDetails] = useState([]);
  const [isGolbalLoading, setGlobalLoading] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: ExpertiseDefaultValue,
    mode: "onChange",
  });

  function convertToYearsMonths(days: number) {
    const daysInYear = 365;
    const daysInMonth = 30;
  
    const years = Math.floor(days / daysInYear);
    const remainingDays = days % daysInYear;
    const months = Math.floor(remainingDays / daysInMonth);
  
    return { years, months };
  }
  useEffect(() => {
    const { years, months } = convertToYearsMonths(item.experience);
    setValue(apiConstants.notes, item?.interestInformation);
    setValue(apiConstants.year, years);
    setValue(apiConstants.month, months);
  }, [item, props]);

  const [uploadFiles, setUploadFiles] = useState<any>([]);
  const [oldFiles, setOldFiles] = useState<any>([]);
  const [uploading, setUploading] = useState(false);
  const [intdeleteId, setIntDeleteId] = useState(null);

  const refCard = useRef(null);

  const fileInputRef = useRef(null);
  const reset = () => {
    fileInputRef.current.value = "";
  };

  const handleFileUploads = async (e:any) => {
    const files: any = Array.from(e.target.files);
    // file: pdf and image
    const invalidFiles = files.filter((f: File) => {
      const isValidType = f.type.includes("pdf") || f.type.includes("image");

      return !isValidType;
    });
    if (invalidFiles.length > 0) {
      showToast("You can only upload 'pdf' and 'image' files.", "error");
      return;
    }
    const fileSize = Number(convertBytesToMB(files[0].size));

    if (fileSize <= 50 && item.documents.length + files?.length <= 5) {
      let images = [];
      for (let i = 0; i < files?.length; i++) {
        images.push(files[i]);
      }
      setUploadFiles([...uploadFiles, ...images]);
      setUploading(true);

      let newDocumentDetails = [];

      for (let i = 0; i < files?.length; i++) {
        const res = await makeGetCall({
          url: `${endpoints.signed_url}/${files[i].name}`,
        });

        if (res.status.code === 200) {
          const {
            AWSAccessKeyId = "",
            key = "",
            policy = "",
            signature = "",
          } = res?.data?.fields;

          const uploadedResponse = await handleFileUpload(
            files[i], // Correctly reference the file in the loop
            res.data.url,
            key,
            AWSAccessKeyId,
            policy,
            signature
          );

          newDocumentDetails.push(uploadedResponse);

          let content = document.getElementById(`docu_${i}`);
          let progressUpdate = document.getElementById(`progress_${i}`);
          if (progressUpdate && content) {
            progressUpdate.style.maxWidth = "100%";
          }
        }
      }

      setDocumentDetails([...documentDetails, ...newDocumentDetails]);
      setUploading(false);
    } else {
      if (item.documents.length + files.length > 5) {
        showToast("You can upload a maximum of 5 files", "error", "err2");
      } else {
        showToast("File should be less than 50MB.", "error");
      }
    }
  };

  const deleteFile = (index) => {
    let newArray = uploadFiles.filter((_, id) => id !== index);
    let newArrayUpload = documentDetails.filter((_, id) => id !== index);
    setUploadFiles(newArray);
    setDocumentDetails(newArrayUpload);
  };

  const handleDeleteDocument = (id, docid) => {
    if (id && docid) {
      makeDeleteCall({
        url: endpoints.interest_experience + id + "/",
        apiPayload: { documentId: docid },
      })
        .then((res) => {
          if (res.status.code === 200) {
            item?.documents.filter((_, id) => id !== docid);
            refetchList();
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };
  const handleDeleteInterest = async (item) => {
    setGlobalLoading(true);
    item?.documents?.map((i) => handleDeleteDocument(item?.id, i?.id));

    const postData = {
      update_experience: [
        {
          id: item?.id,
          experience: null,
          interest_information: "",
          document_details: [],
        },
      ],
    };

    await makePatchCall({
      url: endpoints.interest_experience,
      apiPayload: postData,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setUploadFiles([]);
          setIsEditable(false);
          setTimeout(() => {
            setGlobalLoading(false);
          }, 0);
          refetchList();
        }
      })
      .catch((err) => {
        showToast(err, "error");
        setGlobalLoading(false);
      });
  };

  const handleSaveInterest = async (itemData) => {
    setGlobalLoading(true);
    const postData = {
      update_experience: [
        {
          id: itemData.id,
          experience:
            Number(365 * itemData?.years) + Number(itemData?.months * 30),
          interest_information: getValues(apiConstants.notes)?.trim(),
          document_details: documentDetails,
        },
      ],
    };

    makePatchCall({
      url: endpoints.interest_experience,
      apiPayload: postData,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setUploadFiles([]);
          setIsEditable(false);
          setGlobalLoading(false);
          refetchList();
        }
      })
      .catch((err) => {
        showToast(err, "error");
        setGlobalLoading(false);
      });
  };

  const handleDownloadExp = (filename, file) => {
    const link = document.createElement("a");
    link.href = file;
    link.download = filename || "file";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {/* <div className="d-none d-md-block"> */}
      {isGolbalLoading && <LoadingLayer />}
      <div className="qualification-card-wrap" ref={refCard}>
        {isEditable ? (
          <>
            <div className="card-top-edit">
              <div>
                <img
                  src={
                    item?.interest?.image ||
                    item?.interest?.icon ||
                    "/static/connect/avatar.svg"
                  }
                  alt=""
                  className="expertise-img"
                />
              </div>
              <div>
                <div className="training-info-box-edit">
                  <div className="edit-title">{item?.interest?.title}</div>
                  <div className="edit-btns">
                    <span
                      className="save"
                      onClick={() => handleSaveInterest(item)}
                    >
                      Save
                    </span>
                    <span
                      className="clear"
                      onClick={() => {
                        handleDeleteInterest(item);
                      }}
                    >
                      Clear
                    </span>
                  </div>
                </div>
                <div className="text-fields-box">
                  <div className="form_group">
                    <div className="input_box modify_exp_box">
                      <ReactInputMask
                        mask="99"
                        maskChar={null}
                        onChange={(e: any) => {
                          if (!userAge) {
                            updateExpById({
                              id: item?.id,
                              value: e.target.value,
                              type: "year",
                            });
                          } else if (Number(e.target.value) < userAge) {
                            updateExpById({
                              id: item?.id,
                              value: e.target.value,
                              type: "year",
                            });
                          } else {
                            showToast(
                              `Expertise should not be more than ${
                                userAge - 1
                              } years`,
                              "error",
                              "expertise-age"
                            );
                          }
                        }}
                        value={item?.years}
                      />
                      <span className="new_training_label">{"Years"}</span>
                    </div>
                  </div>
                  <div className="form_group">
                    <div className="input_box modify_exp_box">
                      <span className="new_training_label">{"Month"}</span>
                      <ReactInputMask
                        mask="99"
                        maskChar={null}
                        onChange={(e: any) => {
                          if (
                            Number(e.target.value) >= 0 &&
                            Number(e.target.value) <= 11
                          ) {
                            updateExpById({
                              id: item?.id,
                              value: e.target.value,
                              type: "month",
                            });
                          } else {
                            showToast(
                              STRING_CONSTANTS.month_not_more_than_12,
                              "error"
                            );
                          }
                        }}
                        value={item?.months}
                      />
                    </div>
                  </div>
                  <span className="optional_txt">(optional)</span>
                </div>
                <div className="additional-notes-wrap">
                  <div className="form_group">
                    <div className="input_box">
                      <span className="textarea-label">Additional note</span>
                      <InputField
                        type={"textarea"}
                        name={apiConstants.notes}
                        className={"listing_desc"}
                        placeholder={"Tell others more about your expertise"}
                        control={control}
                        getValues={getValues}
                        maxlength={300}
                        errors={errors}
                        setValue={setValue}
                        handleNotes={(e: any) => {
                          updateExpById({
                            id: item?.id,
                            value: e.target.value,
                            type: "interestInformation",
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <label
                  htmlFor={item.id}
                  className="attach-document"
                  style={{
                    cursor: uploadFiles.length ? "text" : "pointer",
                  }}
                  onClick={() => {
                    reset();
                    oldFiles.length >= 5 &&
                      showToast(STRING_CONSTANTS.upload_file_5, "error");
                    uploading &&
                      showToast("File is uploading. Please wait a moment.", "error");
                  }}
                >
                  <span>Attach Document</span>
                  <img alt="" src="/static/plusorange.svg" />
                </label>
                <input
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  type="file"
                  disabled={oldFiles.length >= 5}
                  multiple
                  name="upload_docs"
                  id={item.id}
                  onChange={handleFileUploads}
                  onClick={(e: any) => (e.currentTarget.value = null)}
                />
              </div>
            </div>
            <div>
              {item?.documents?.map((item) => (
                <div className="uploded-file">
                  <div
                    className="left"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleDownloadExp(
                        item.documentName,
                        item.uploadDocumentUrl
                      )
                    }
                  >
                    <img alt="file-icon" src="/static/fileicon.svg" />
                    <span>{item.documentName}</span>
                  </div>
                  <div className="right">
                    <img
                      alt=""
                      src="/static/DeleteBlack.svg"
                      onClick={() =>
                        handleDeleteDocument(
                          item.trainerSpecializationExperience,
                          item.id
                        )
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="loder-part-scro">
              {uploadFiles.length > 0 && (
                <ul className="file-cont">
                  {uploadFiles.map((item: any, index: number) => {
                    return (
                      <>
                        <li className="file_preview" key={index}>
                          <img src="/static/file.svg" alt="" />
                          <div className="name">
                            <p>
                              {item.name}{" "}
                              <span className="size">
                                {" "}
                                {formatBytes(item.size)}
                              </span>
                            </p>
                            <div className="progressbar">
                              <span id={`progress_${index}`}></span>
                            </div>
                          </div>

                          <div className="file-status">
                            <img
                              src="/static/checked.svg"
                              alt=""
                              width={"16px"}
                              height={"16px"}
                            />
                            <p className="" id={`docu_${index}`}></p>
                          </div>
                          <div
                            className="cancel-delete"
                            onClick={() => deleteFile(index)}
                          >
                            <img
                              src="/static/close/closeSecondary.svg"
                              alt=""
                            />
                          </div>
                        </li>
                      </>
                    );
                  })}
                </ul>
              )}
            </div>
          </>
        ) : (
          <>
            {(item?.experience ||
              item?.interestInformation ||
              item?.documents?.length > 0) &&
            !isEditable ? (
              <>
                <div className="card-top">
                  <div className="content-left">
                    <div>
                      <img
                        src={
                          item?.interest?.image ||
                          item?.interest?.icon ||
                          "/static/connect/avatar.svg"
                        }
                        alt=""
                        className="training-img"
                      />
                    </div>
                    <div className="training-info-box">
                      <div className="training-title">
                        {item?.interest?.title}
                      </div>
                      <div className="exp">
                        {item?.experience ? `${Math.floor(item.experience / 365)} years ${Math.floor((item.experience % 365) / 30)} months`: null}
                      </div>
                    </div>
                  </div>
                  <div className="content-right">
                    <img
                      src="/static/EditBlack.svg"
                      alt=""
                      onClick={() => setIsEditable(true)}
                    />
                    {/* <img
                      src="/static/DeleteRed.svg"
                      alt=""
                      onClick={() => setIntDeleteId(item.id)}
                    /> */}
                  </div>
                </div>

                <div className="card-bottom">
                  {open ? (
                    <div className={open ? "descfull" : "desc"}>
                      {item?.interestInformation}
                    </div>
                  ) : (
                    <div className={open ? "descfull" : "desc"}>{""}</div>
                  )}
                  {(item?.interestInformation ||
                    item?.documents?.length > 0) && (
                    <div className="show-more" onClick={() => setOpen(!open)}>
                      {open ? "Show less" : "Show more"}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="no-doc-card">
                <div className="left">
                  <img
                    src={
                      item?.interest?.icon ||
                      item?.interest?.image ||
                      "/static/connect/avatar.svg"
                    }
                    alt=""
                    className="training-img empty_icon"
                  />
                  <div className="title">{item?.interest?.title}</div>
                </div>
                <div className="right">
                  <span
                    className="add-qualification"
                    onClick={() => setIsEditable(true)}
                  >
                    Add Qualification
                  </span>
                  {/* <img
                    src="/static/DeleteRed.svg"
                    alt=""
                    onClick={() => setIntDeleteId(item.id)}
                  /> */}
                </div>
              </div>
            )}
            {open ? (
              <div className="document-box">
                <div className="filenamebox">
                  {item?.documents?.map((item) => (
                    <div className="flie-card">
                      <img alt="file-icon" src="/static/fileicon.svg" />
                      <span>{item.documentName}</span>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default ExperienceCard;
