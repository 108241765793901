import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { Navigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../utils/RouteConstants";

const OnboardingRoutes = ({ auth, children }) => {
  const isAuth = Boolean(localStorage.getItem("fm_token"));
  const isProfileCompleted = Boolean(auth?.moreUserDetails?.isProfileCompleted);
  const pathname = window?.location?.pathname || "";
  const step1Routes = ROUTE_CONSTANTS.welcome;
  const step2Routes = ROUTE_CONSTANTS.select_member_type;
  const step3Routes = ROUTE_CONSTANTS.manage_profile;

  if (isAuth && !isProfileCompleted) {
    if (
      !auth?.moreUserDetails?.userType ||
      (auth?.moreUserDetails?.userType === "Guest" &&
        Number(auth?.moreUserDetails?.onboardingStep) === 1 &&
        step1Routes === pathname)
    ) {
      return children;
    } else {
      if (auth?.moreUserDetails?.userType === "Member") {
        if (
          auth?.moreUserDetails?.isTrainer ||
          auth?.moreUserDetails?.isBusiness ||
          auth?.moreUserDetails?.isIndividual
        ) {
          if (step3Routes === pathname) {
            return children;
          } else {
            return <Navigate to={ROUTE_CONSTANTS.manage_profile} />;
          }
        } else if (
          !auth?.moreUserDetails?.isTrainer &&
          !auth?.moreUserDetails?.isBusiness &&
          !auth?.moreUserDetails?.isIndividual &&
          step2Routes === pathname
        ) {
          return children;
        } else {
          return <Navigate to={ROUTE_CONSTANTS.select_member_type} />;
        }
      } else if (
        auth?.moreUserDetails?.userType === "Guest" &&
        Number(auth?.moreUserDetails?.onboardingStep) > 1 &&
        step3Routes === pathname
      ) {
        return children;
      } else {
        return <Navigate to={ROUTE_CONSTANTS.welcome} />;
      }
    }
  } else {
    if (isAuth && isProfileCompleted) {
      return <Navigate to={ROUTE_CONSTANTS.landing} />;
    } else {
      return <Navigate to={ROUTE_CONSTANTS.register} />;
    }
  }
};

OnboardingRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(OnboardingRoutes);
