import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { USER_TYPE } from "../../types";

import {
  dateFormatter,
  getItemFromStore,
  removeAllItemFromStore,
  scrollToTop,
  setLocalStorage,
  showToast,
} from "../../utils";
import { apiConstants } from "../../utils/Constants";
import endpoints from "../../utils/endpoints";
import { makePatchCall, makePostCall } from "../../utils/Requests";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import { BasicDetailsValidation } from "./ProfileValidations";
import ApiLoader from "../../global/ApiLoader";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";
import "./profile.scss";
import UserDetails from "./ManageProfileComponent/UserDetails";
import Interest from "./Interest";
import Trainer from "./Trainer";
import BusinessDetails from "./ManageProfileComponent/BusinessDetails";
import apiConfig from "../../utils/endpoints";
import { USER_ROLES } from "../Connect/ConnectConstants";
import { useApiRequest } from "../../hooks/useApiRequest";
import LogoutModal from "../../global/LogoutModal";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { logoutSuccess } from "../../store/auth/action";

const ManageProfile = () => {
  const getUserProfile = useApiRequest();
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);

  const userData = getItemFromStore("userData");
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [countryCode, setCountryCode] = useState<any>("");

  const [currentStep, setCurrentStep] = useState(1);
  const [coverMedia, setCoverMedia] = useState(null);
  const [profileMedia, setProfileMedia] = useState(null);
  const [totalSteps, setTotalSteps] = useState(2);
  const [userTypeData, setUserTypeData] = useState({
    isGuest: false,
    isTrainer: false,
    isBusiness: false,
    isIndividual: false,
  });
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({
    resolver: yupResolver(BasicDetailsValidation),
    mode: "onSubmit",
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const screenSection = searchParams.get("onboarding");
  const [toggleLogoutModal, setToggleLogoutModal] = useState(false);

  const handleScreenSection = (type) => {
    setSearchParams({ onboarding: type });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    switch (screenSection) {
      case "business":
        setCurrentStep(2);
        break;
      case "interest":
        setCurrentStep(3);
        break;
      case "trainee":
        setCurrentStep(4);
        break;
      default:
        setCurrentStep(1);
        setSearchParams({});

        break;
    }
  }, [screenSection]);

  const handleTotalSteps = (userDetail) => {
    const {
      isTrainer = false,
      isBusiness = false,
      isIndividual = false,
    } = userDetail;

    if (isTrainer) {
      setTotalSteps(4);
    } else if (isBusiness || isIndividual) {
      setTotalSteps(3);
    }
  };

  const fetchProfileData = async () => {
    setUserDataLoading(true);
    const res = await getUserProfile();
    if (res?.status?.code === 200) {
      const businessData = res?.data?.businessDetail?.length
        ? res?.data?.businessDetail[0]
        : null;
      const tempData = {
        dobDate:
          res?.data?.userDetail?.dob &&
          dayjs(res?.data?.userDetail?.dob).format("D"),
        dobMonth:
          res?.data?.userDetail?.dob &&
          dayjs(res?.data?.userDetail?.dob).format("MMMM"),
        dobYear:
          res?.data?.userDetail?.dob &&
          dayjs(res?.data?.userDetail?.dob).format("YYYY"),
        [apiConstants.profileImage]: res?.data?.userDetail?.profilePicture,
        [apiConstants.profileImageExtension]:
          res?.data?.userDetail?.profilePictureExtension,
        [apiConstants.coverPhoto]: res?.data?.userDetail?.coverPhotoVideo,
        [apiConstants.coverExtension]: res?.data?.userDetail?.coverExtension,
        [apiConstants.name]: res?.data?.userDetail?.name,
        [apiConstants.gender]: res?.data?.userDetail?.gender,
        [apiConstants.dob]: res?.data?.userDetail?.dob,
        [apiConstants.bio]: res?.data?.userDetail?.bio,
        // business data
        [apiConstants.tradingName]: businessData?.tradingName,
        [apiConstants.abn]: businessData?.abn,
        [apiConstants.address]: res?.data?.userDetail?.address,
        [apiConstants.businessContactEmail]: businessData?.businessContactEmail,
        [apiConstants.businessContactNumber]:
          businessData?.businessContactNumber?.replace("+", ""),
      };
      reset(tempData);
      handleTotalSteps(res.data?.userDetail);
      const userDetail = res?.data?.userDetail || {};
      const {
        userType,
        isTrainer = false,
        isBusiness = false,
        isIndividual = false,
      } = userDetail;

      setUserTypeData({
        isGuest: userType === USER_TYPE.GUEST,
        isTrainer,
        isBusiness,
        isIndividual,
      });
    } else {
      showToast(res.status.messgae, "error");
    }
    setUserDataLoading(false);
    const { isProfileCompleted = true, userType = USER_ROLES.GUEST } =
      res?.data?.userDetail;
    if (
      !isProfileCompleted &&
      userType === USER_ROLES.GUEST &&
      currentStep === 1 &&
      !res?.data?.userDetail?.name
    ) {
      showToast(
        "You have successfully login as a Guest.You can now start using the application as a guest and enjoy all its benefits.",
        "success",
        'first_guest'
      );
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, [currentStep]);

  const checkNumberIsAdded = () => {
    const numberWithoutCountryCode = getValues(
      apiConstants.businessContactNumber
    ).startsWith(countryCode?.dialCode)
      ? getValues(apiConstants.businessContactNumber)?.slice(
          countryCode?.dialCode?.length
        )
      : getValues(apiConstants.businessContactNumber);
    return numberWithoutCountryCode;
  };

  const generateStep =
    auth?.moreUserDetails?.userType === "Guest"
    
      ? 5
      : auth?.moreUserDetails?.isTrainer || auth?.moreUserDetails?.isIndividual || auth?.moreUserDetails?.isBusiness
      ? auth?.moreUserDetails?.name ? 5 : 4
      : 7;
  ;

  const onSubmit = (data: any) => {
    const formData = new FormData();

    if (getValues("dobDate") && getValues("dobMonth") && getValues("dobYear")) {
      const mergeDob = `${getValues("dobDate")} ${getValues(
        "dobMonth"
      )} ${getValues("dobYear")}`;
      const dateDob = moment(mergeDob, "DD MMMM YYYY").format(
        "ddd MMM DD YYYY 00:00:00 [GMT]ZZ [(India Standard Time)]"
      );
      formData.append(apiConstants.dob, dateFormatter(dateDob, "YYYY-MM-DD"));
    }
    formData.append(apiConstants.onBoardingStep, String(generateStep));
    Object.keys(dirtyFields).forEach((item: any) => {
      switch (item) {
        case "businessContactNumber":
          if (checkNumberIsAdded()) {
            formData.append(
              apiConstants.businessContactNumber,
              `+${data.businessContactNumber}`
            );
          } else {
            formData.append(apiConstants.businessContactNumber, "");
          }
          break;

        case "abn":
          formData.append(
            apiConstants.abn,
            getValues(apiConstants.abn).replaceAll(" ", "")
          );
          if (watch(apiConstants?.abnDetails)) {
            const abnDetails = watch(apiConstants.abnDetails) as any;
            formData.append("businessContactName", abnDetails?.EntityName);
            formData.append(
              "businessAddress",
              `${abnDetails?.AddressState} ${abnDetails?.AddressPostcode}`
            );
          }
          break;

        case "profilePicture":
          const fileData: any = getValues(apiConstants.profileImage);
          formData.append("profilePictureExtension", fileData?.type);
          formData.append(apiConstants.profileImage, fileData);
          break;

        case "coverPhotoVideo":
          formData.append(
            "cover_photo_video",
            getValues(apiConstants.coverPhoto)
          );
          formData.append(
            "cover_extension",
            getValues(apiConstants.coverExtension)
          );
          break;

        case "address":
          if (getValues(apiConstants.address)) {
            formData.append(
              apiConstants.address,
              getValues(apiConstants.address) || null
            );
            formData.append("latitude", getValues(apiConstants.lat) || null);
            formData.append("longitude", getValues(apiConstants.lng) || null);
            formData.append(
              "location",
              getValues(apiConstants.lng)
                ? `POINT (${getValues(apiConstants.lng)} ${getValues(
                    apiConstants.lat
                  )})`
                : null
            );
            formData.append(
              apiConstants.postCode,
              getValues(apiConstants.postCode)
            );
            formData.append(
              apiConstants.state,
              getValues(apiConstants.state) || null
            );
            formData.append(
              apiConstants.city,
              getValues(apiConstants.city) || null
            );
            formData.append(
              apiConstants.country,
              getValues(apiConstants.country) || null
            );
          }

          if (getValues(apiConstants.tradingName)) {
            formData.append(
              "trading_name",
              getValues(apiConstants.tradingName) || null
            );
          }
          break;

        default:
          if (getValues(item) !== null) formData.append(item, getValues(item));
          break;
      }
    });

    setIsLoading(true);
    makePatchCall({
      url: endpoints.profile,
      apiPayload: formData,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        if (res?.status?.code === 200) {
          scrollToTop();
          setLocalStorage("userData", {
            ...res.data?.userDetail,
            ...res.data?.businessDetail,
            ...res.data?.trainerData,
          });
          const { userType = USER_TYPE.GUEST, onboardingStep } =
            res?.data?.userDetail;

          if (userType === USER_TYPE.GUEST) {
            handleScreenSection("interest");
          } else {
            if (Number(onboardingStep) === 5) {
              handleScreenSection("interest");
            } else if (Number(onboardingStep) === 4) {
              handleScreenSection("business");
            }
          }
          // }
        } else {
          showToast(res.status.message, "error");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setIsLoading(false);
      });
  };

  const onError = () => {
    showToast(STRING_CONSTANTS.general_form_error_message, "error");
  };

  const handleNext = (path?: string, dobError?: boolean) => {
    if (currentStep === 1) {
      if (!watch(apiConstants.name)?.trim()) {
        showToast("Please enter the username", "error");
      } else if (watch("dobDate") || watch("dobMonth") || watch("dobYear")) {
        const mergeDob = `${getValues("dobDate")} ${getValues(
          "dobMonth"
        )} ${getValues("dobYear")}`;
        const dateDob = moment(mergeDob, "DD MMMM YYYY").format(
          "ddd MMM DD YYYY 00:00:00 [GMT]ZZ [(India Standard Time)]"
        );

        if (!watch("dobDate") || !watch("dobMonth") || !watch("dobYear")) {
          showToast("Please enter your complete date of birth", "error");
          return;
        } else if (moment(dateDob).isAfter(moment())) {
          showToast(
            "The date of birth cannot be in the future. Please enter a valid date of birth",
            "error"
          );
          return;
        } else {
          handleSubmit(onSubmit, onError)();
        }
      } else {
        handleSubmit(onSubmit, onError)();
      }
    } else {
      handleSubmit(onSubmit, onError)();
    }
  };

  const handleInterestSelectContinue = () => {
    if (auth?.moreUserDetails?.isTrainer) {
      handleScreenSection("trainee");
    } else {
      navigate(ROUTE_CONSTANTS?.landing);
    }
  };

  const handleBusineessDetailSkip = () => {
    let apiPayload: any = {};
    apiPayload["onboardingStep"] = "5";

    makePatchCall({
      url: apiConfig.profile,
      apiPayload,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setIsLoading(false);
          handleScreenSection("interest");
        } else {
          showToast(STRING_CONSTANTS.wrong_data_message, "error");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        showToast(err, "error");
        setIsLoading(false);
      });
  };

  const handleInterestPageSkip = () => {
    handleScreenSection("trainee");
  };

  const handleProgressWidth = (step: number) => {
    switch (step) {
      case 1:
        return "33%";
      case 2:
        return "66%";
      case 3:
        return "80%";
      case 4:
        return "85%";
      default:
        return "33%";
    }
  };

  const ProgressBar = () => {
    let localizedCurrentStep;
    if (userData?.userType === USER_TYPE.GUEST && currentStep == 3) {
      localizedCurrentStep = currentStep - 1;
    } else {
      localizedCurrentStep = currentStep;
    }
    return (
      <div className="mp_progress_container">
        <div className="mp_progress">
          <div
            className="mp_current_progress"
            style={{ width: handleProgressWidth(currentStep) }}
          />
        </div>
        <div className="step_counts">{`Step ${localizedCurrentStep}/${totalSteps}`}</div>
      </div>
    );
  };

  const manageProfileSteps = (step: number) => {
    switch (step) {
      case 2:
        return (
          <BusinessDetails
            coverMedia={coverMedia}
            setCoverMedia={setCoverMedia}
            profileMedia={profileMedia}
            setProfileMedia={setProfileMedia}
            handleNext={handleNext}
            control={control}
            errors={errors}
            isLoading={isLoading}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            handleBusineessDetailSkip={handleBusineessDetailSkip}
            setCountryCode={setCountryCode}
          />
        );
      case 3:
        return (
          <Interest
            handleInterestSelectContinue={handleInterestSelectContinue}
            handleInterestPageSkip={handleInterestPageSkip}
          />
        );
      case 4:
        return (
          <Trainer
            handleInterestSelectContinue={handleInterestSelectContinue}
          />
        );
      default:
        return (
          <UserDetails
            coverMedia={coverMedia}
            setCoverMedia={setCoverMedia}
            profileMedia={profileMedia}
            setProfileMedia={setProfileMedia}
            handleNext={handleNext}
            control={control}
            errors={errors}
            isLoading={isLoading}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            userTypeData={userTypeData}
          />
        );
    }
  };

  const handleLogout = () => {
    let apiPayload = { email: auth?.moreUserDetails?.email || '' };
    makePostCall({ url: endpoints.logout, apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
          navigate(ROUTE_CONSTANTS.login);
          removeAllItemFromStore();
          setTimeout(() => {
            dispatch(logoutSuccess());
          }, 0);
          showToast(STRING_CONSTANTS.logout_success, "success");
        }
      })
      .catch((err) => showToast(err, "error"));
  };

  const ProfileHeader = ({ step = 1 }) => {
    switch (step) {
      case 2:
        return (
          <>
            <div className="mp_heading">
              <img
                src="/static/arrow/backButton.svg"
                className="back_icon"
                alt=""
                onClick={() => setSearchParams({})}
              />
              Add your business details
            </div>
            <div className="mp_description">
              Add your details to help your customers easily recognise you
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="mp_heading">
              <img
                src="/static/arrow/backButton.svg"
                className="back_icon"
                alt=""
                onClick={() => {
                  if (userTypeData?.isGuest) {
                    setSearchParams({});
                  } else {
                    handleScreenSection("business");
                  }
                }}
              />
              Your business interests
            </div>
            <div className="mp_description">
              Select at-least one interest to continue.
            </div>
          </>
        );

      case 4:
        return (
          <>
            <div className="mp_heading">
              <img
                src="/static/arrow/backButton.svg"
                className="back_icon"
                alt=""
                onClick={() => handleScreenSection("interest")}
              />
              Make your journey count
            </div>
            <div className="mp_description">
              Let us know about your expertise as a trainer and in your chosen
              interests!
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="mp_heading">
              {!userTypeData.isGuest && (
                <img
                  src="/static/arrow/backButton.svg"
                  className="back_icon"
                  alt=""
                  onClick={
                    () => setToggleLogoutModal(true)
                  }
                />
              )}
              {userTypeData.isBusiness
                ? "Business profile"
                : STRING_CONSTANTS.basic_details_title}
            </div>
            <div className="mp_description">
              {userTypeData.isBusiness
                ? "Add your business profile details to help your customers easily recognise you."
                : "Add your personal details to help your customers easily recognise you"}
            </div>
          </>
        );
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    } else {
      navigate(ROUTE_CONSTANTS?.events);
    }
  };

  return (
    <>
      {userDataLoading ? (
        <ApiLoader />
      ) : (
        <>
          <GlobalHeader2 />
          <div className="manage_profile_wrapper">
            <div className="mp_header">
              <ProfileHeader step={currentStep} />
              {/* <img
                className="back_btn"
                src="/static/arrow-back.svg"
                alt=""
                onClick={handleBack}
              /> */}
            </div>

            <div className="mp_progress_wrapper">
              <ProgressBar />
            </div>
            <>{manageProfileSteps(currentStep)}</>
          </div>
          {toggleLogoutModal && (
            <LogoutModal
              onOk={() => handleLogout()}
              onCancel={() => setToggleLogoutModal(false)}
            />
          )}
        </>
      )}
    </>
  );
};

export default ManageProfile;
