import Slider from "react-slick";
import SampleNextArrow from "./SampleNextArrow";
import React from "react";
import SamplePrevArrow from "./SamplePrevArrow";

const MultipleItems = ({ item, interests, setInterests }) => {
  const interestArray = item;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    centerMode: false, // Disable center mode to prevent cropping at the start
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        {interestArray.map((item) => {
          return (
            <div
              key={item?.id}
              className="filter-item"
              onClick={() => {
                let temp_interests = [...interests];
                if (temp_interests.includes(item.id)) {
                  const index = temp_interests.indexOf(item.id);
                  if (index > -1) {
                    // only splice array when item is found
                    temp_interests.splice(index, 1); // 2nd parameter means remove one item only
                    setInterests([...temp_interests]);
                  }
                } else {
                  temp_interests.push(item.id);
                  setInterests([...temp_interests]);
                }
              }}
            >
              <img
                // src={item.icon ? item.icon : item.image}
                src={
                  interests.includes(item.id) ? item.colouredIcon : item.icon
                }
                style={{ width: "24px", height: "24px" }}
              />
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#52545B",
                }}
                className={interests.includes(item.id) ? "active-text-new" : ""}
              >
                {item.title}
              </p>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default React.memo(MultipleItems);
