import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ComingSoon = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);
  
  return (
    <div className="coming_soon">
      <img src="/static/orange-logo.svg" alt="" />

      <div>Good things coming soon!</div>
    </div>
  );
};

export default ComingSoon;
