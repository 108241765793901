import React, { useEffect } from "react";
import "./style.scss";

type Props = {
  [key: string]: any;
};

const LoadingLayer = (props: Props) => {
  const { loading = true, isCustom = false } = props;

  useEffect(() => {
    document.body.classList.add("disable_scroll");

    return () => {
      document.body.classList.remove("disable_scroll");
    };
  }, []);

  return (
    <div className="loader_wrapper_layer">
      <div className="inner_wrap">
        {isCustom ? (
          <div style={{
            fontWeight: 500
          }}>Please wait...</div>
        ) : (
          <img
            src="/static/connect/spinner.svg"
            className="spinner_img"
            alt=""
          />
        )}
      </div>
    </div>
  );
};

export default LoadingLayer;
