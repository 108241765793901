import { useEffect, useRef, useState } from "react";
import { makeGetCall, makeNextPageCall, makePostCall } from "../../../utils/Requests";
import { apiConstants } from "../../../utils/Constants";
import { scrollToTop, showToast } from "../../../utils";
import SearchFilter from "../ConnectComponents/SearchFilter";
import Loader from "../../../global/Loader";
import FavCard from "../ConnectComponents/FavCard";
import IconSeeMore from "../../../assets/img/connectIcons/see-more.png";
import ConnectScrollableCard from "../ConnectComponents/ConnectScrollableCard";
import ConnectUserCard from "../ConnectComponents/ConnectUserCard";
import { isMobile, isTablet } from 'react-device-detect';
import {
  CONNECTION_TYPE,
  getFilterParams,
  initialFilterData,
  SUGGESTIONS_TYPES,
} from "../ConnectConstants";
import ConnectMapContainer from "../ConnectComponents/ConnectMapContainer";
import SeeMoreDataCard from "../ConnectComponents/SeeMore";
import { useNavigate } from "react-router-dom";
import NoMatchedDataCard from "../ConnectComponents/NoMatchedDataCard";
import FavSeeMoreCard from "../ConnectComponents/FavSeeMoreCard";
import ReactPaginate from "react-paginate";
import NoSearchedFilter from "../ConnectComponents/NoSearchedFilter";

const ConnectZone = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showMap, setShowMap] = useState(false);
  const [users, setUsers] = useState<any>([]);
  const navigate = useNavigate();
  const [favouriteConnectionsList, setFavouriteConnectionsList] = useState([]);
  const [isFavouriteClicked, setIsFavouriteClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [isSearchedSeeAllTrue, setIsSearchedSeeAllTrue] = useState(false);
  const [serachData, setSearchData] = useState<any>();
  const [limit, setLimit] = useState<number>(8);
  const [userCurrentLocation, setUserCurrentLocation] = useState({
    lat: 0,
    long: 0,
  });
  const [apiDetails, setApiDetails] = useState({
    prevUrl: "",
    nextUrl: "",
    pageCount: 1,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState<number>(0);
  const [nextUrl, setNextUrl] = useState<any>(null);
  const [profiles, setProfiles] = useState<any>([]);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [connectid, setConnectid] = useState<any>(null);
  const [isFilterCleared, setIsFilterCleared] = useState(false);
  const scrollContainerRef = useRef(null);

  const [suggestionData, setSuggestionData] = useState({
    interest: [],
    near_by: [],
    trainer: [],
    other: [],
  });
  const [isDataFetching, setIsDataFetching] = useState({
    interest: true,
    near_by: true,
    trainer: true,
    other: true,
  });
  const handleClearClick = (mode="search") => {

    if(mode === "search"){
      setSearch("");
    }else{
     // for clearing the filter
     setIsActiveFilter(false);
     getSuggestionsList(SUGGESTIONS_TYPES.INTEREST_BASED);
     getSuggestionsList(SUGGESTIONS_TYPES.NEAR_BY);
     getSuggestionsList(SUGGESTIONS_TYPES.TRAINER);
     getSuggestionsList(SUGGESTIONS_TYPES.OTHER);
    }
    setIsSearchedSeeAllTrue(false);
    setIsDataFetching({
      interest: true,
      near_by: true,
      trainer: true,
      other: true,
    });
  };
  const handleSuggestionSearch = (value: any) => {
    setSearch(value);
  };

  const handleFilterApply = (filterData: any) => {
    setIsSearchedSeeAllTrue(true);
    setShowFilter(true);
    setIsActiveFilter(true);
    getSearchedSuggestion("filter", filterData);
    // getSuggestionsList(SUGGESTIONS_TYPES.INTEREST_BASED, 12, true, filterData);
    // getSuggestionsList(SUGGESTIONS_TYPES.NEAR_BY, 12, true, filterData);
    // getSuggestionsList(SUGGESTIONS_TYPES.TRAINER, 12, true, filterData);
    // getSuggestionsList(SUGGESTIONS_TYPES.OTHER, 12, true, filterData);
  };
  const handleSearchedSeeAllClick = () => {
    setIsSearchedSeeAllTrue(true);
    setShowFilter(false);
   /*  getSuggestionsList(SUGGESTIONS_TYPES.INTEREST_BASED);
    getSuggestionsList(SUGGESTIONS_TYPES.NEAR_BY);
    getSuggestionsList(SUGGESTIONS_TYPES.TRAINER);
    getSuggestionsList(SUGGESTIONS_TYPES.OTHER); */
  };
  const handleViewToggle = () => {
    setShowMap((prev) => !prev);
  };

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected);
    setOffset(event.selected * limit);
    if (apiDetails.nextUrl) {
      makeNextPageCall({ url: apiDetails.nextUrl })
        .then((res) => {
          if (res.next || res.previous) {
            setApiDetails({
              prevUrl: res.previous ?? "",
              nextUrl: res.next ?? "",
              pageCount: res.count ?? 1,
            });
          }
          setSearchData(res.results);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    } else if (apiDetails.prevUrl) {
      makeNextPageCall({ url: apiDetails.prevUrl })
        .then((res) => {
          if (res.next || res.previous) {
            setApiDetails({
              prevUrl: res.previous ?? "",
              nextUrl: res.next ?? "",
              pageCount: res.count ?? 1,
            });
          }
          setSearchData(res.results);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
    scrollToTop();
  };
  const getSuggestionsList = (
    filterBy = SUGGESTIONS_TYPES.INTEREST_BASED,
    limit = 12,
    isFilterApplied = false,
    filterParams = initialFilterData
  ) => {
    const params = {
      filter_by: filterBy,
      point:`${userCurrentLocation.long},${userCurrentLocation.lat}`,
      limit: limit,
    };
    makeGetCall({
      url: apiConstants.get_connect_suggestions,
      params: search
        ? { ...params, search: search }
        : isFilterApplied
        ? { ...params, ...getFilterParams(filterParams) }
        : params,
    })
      .then((res) => {
        if (res.status.code === 200) {
          const data = res.results;

          if (res.next || res.previous) {
            setApiDetails({
              prevUrl: res.previous ?? "",
              nextUrl: res.next ?? "",
              pageCount: res.count ?? 1,
            });
          }

          if (filterBy === SUGGESTIONS_TYPES.INTEREST_BASED) {
            setSuggestionData((prev) => ({
              ...prev,
              interest: data,
            }));
            setIsDataFetching((prev) => ({ ...prev, interest: false }));
          } else if (filterBy === SUGGESTIONS_TYPES.NEAR_BY) {
            setSuggestionData((prev) => ({
              ...prev,
              near_by: data,
            }));
            setIsDataFetching((prev) => ({ ...prev, near_by: false }));
          } else if (filterBy === SUGGESTIONS_TYPES.TRAINER) {
            setSuggestionData((prev) => ({
              ...prev,
              trainer: data,
            }));
            setIsDataFetching((prev) => ({ ...prev, trainer: false }));
          } else {
            setSuggestionData((prev) => ({
              ...prev,
              other: data,
            }));
            setIsDataFetching((prev) => ({ ...prev, other: false }));
          }
        }
        setIsLoading((prev) => !prev);
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };

  const getSearchedSuggestion = (mode="search", filterData=initialFilterData
  ) => {
    const params = {
      tab:"Users",
      search: search,
      point:`${userCurrentLocation.long},${userCurrentLocation.lat}`,
      limit:12,
    };
    makeGetCall({
      url: apiConstants.get_connect_suggestions,
      params: mode === "search" ? {...params} : { ...params, ...getFilterParams(filterData) },
    })
      .then((res) => {
        if (res.status.code === 200) {
          const data = res.results;

          if (res.next || res.previous) {
            setApiDetails({
              prevUrl: res.previous ?? "",
              nextUrl: res.next ?? "",
              pageCount: res.count ?? 1,
            });
          }
          setCount(res?.count)
          setSearchData(data);
        }
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };

  const getFavouriteConnections = () => {
    makeGetCall({
      url: apiConstants.get_my_favourites,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setFavouriteConnectionsList(res.results);
          setIsFavouriteClicked(false);
        }
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };

  useEffect(()=>{

    if(userCurrentLocation.lat && userCurrentLocation.long){
      getSuggestionsList(SUGGESTIONS_TYPES.INTEREST_BASED);
      getSuggestionsList(SUGGESTIONS_TYPES.NEAR_BY);
      getSuggestionsList(SUGGESTIONS_TYPES.TRAINER);
      getSuggestionsList(SUGGESTIONS_TYPES.OTHER);
    }
  },[userCurrentLocation.lat, userCurrentLocation.long])

  useEffect(() => {
    if (isFavouriteClicked) {
      getFavouriteConnections();
    }
  }, [isFavouriteClicked]);
  useEffect(() => {
    // Check if Geolocation API is available in the browser
    getFavouriteConnections();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserCurrentLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
         
        },
        (err) => {
          showToast(
            "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
            "error", "connect"
          );
        }
      );
    } else {
      showToast("It looks like geolocation is not available in your browser.", "error");
    }
  }, []);

  useEffect(() => {
    if (search !== "") {
      setIsSearchedSeeAllTrue(false);
      getSearchedSuggestion();
    }
    if (search === "") {
      handleClearClick()
      getSuggestionsList(SUGGESTIONS_TYPES.INTEREST_BASED);
      getSuggestionsList(SUGGESTIONS_TYPES.NEAR_BY);
      getSuggestionsList(SUGGESTIONS_TYPES.TRAINER);
      getSuggestionsList(SUGGESTIONS_TYPES.OTHER);
    }
  }, [search]);

 

  const renderFavConnectionsData = () => {
    let dataToReturn = favouriteConnectionsList?.map((item) => {
      return <FavCard connectionData={item} key={item.id}/>;
    });
    
    if(isMobile && dataToReturn.length > 2){
      dataToReturn.push(
        <FavSeeMoreCard />
      );
    }else if(isTablet && dataToReturn.length > 5){
      dataToReturn.push(
        <FavSeeMoreCard />
      );
    }else if(dataToReturn.length > 8){
      dataToReturn.push(
        <FavSeeMoreCard />
      );
    }
    
    return dataToReturn;
  };

  const renderSuggestedConnectionsData = (
    selectBy = SUGGESTIONS_TYPES.INTEREST_BASED
  ) => {
    if (suggestionData[selectBy]?.length > 0) {
      const dataToReturn = suggestionData[selectBy]?.map((suggestion: any) => {
        return (
          <div key={`${selectBy}${suggestion.id}`}>
            <ConnectUserCard
              connectionData={suggestion}
              mode={CONNECTION_TYPE.SUGGESTIONS}
              setIsFavouriteClicked={setIsFavouriteClicked}
              isScrollable={true}
              key={suggestion?.id}
            />
          </div>
        );
      });
      dataToReturn.push(<SeeMoreDataCard path="/connect/suggestions" />);
      return dataToReturn;
    } else {
      return <NoMatchedDataCard />
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <SearchFilter
        showMap={showMap}
        handleViewToggle={handleViewToggle}
        handleSearchChange={handleSuggestionSearch}
        serachData={serachData}
        handleSeeAll={handleSearchedSeeAllClick}
        isFilterSupported={true}
        handleFilterApply={handleFilterApply}
        handleClearSearch={handleClearClick}
        isSearchedSeeAllTrue={isSearchedSeeAllTrue}
        isActiveFilter={isActiveFilter}
      />
      {isLoading ? (
        <Loader />
      ) : !showMap ? (

        !isSearchedSeeAllTrue ?
        <div>
          {favouriteConnectionsList.length !== 0 && (
            <ConnectScrollableCard
              title="My favourite mates"
              description="View and manage your favourite Fitness mates connections"
              path="/connect/favourite"
              ClassName="fav-content-bloc"
              render={renderFavConnectionsData()}
              isLoading={isLoading}
            />
          )}
          <ConnectScrollableCard
            title="Fitness mates based on your interest"
            description="Expand your network with suggested connections based on your interest"
            path="/connect/suggestions"
            ClassName="fav-content-bloc"
            render={renderSuggestedConnectionsData()}
            isLoading={isDataFetching.interest}
          />
          <ConnectScrollableCard
            title="Nearby fitness mates"
            description="Discover and connect with active individuals in your local area"
            path="/connect/suggestions"
            ClassName="fav-content-bloc"
            render={renderSuggestedConnectionsData("near_by")}
            isLoading={isDataFetching.near_by}
          />
          <ConnectScrollableCard
            title="Find fitness experts"
            description="Connect with certified trainers and nutrition specialists in your area"
            path="/connect/suggestions"
            ClassName="fav-content-bloc"
            render={renderSuggestedConnectionsData("trainer")}
            isLoading={isDataFetching.trainer}
          />
          <ConnectScrollableCard
            title="Other suggestions"
            description="Get all the fitness mates suggestion here"
            path="/connect/suggestions"
            ClassName="fav-content-bloc"
            render={renderSuggestedConnectionsData("other")}
            isLoading={isDataFetching.other}
          />
        </div>
       : <div>
        <div style={{fontSize:"14px", fontFamily:"Poppins", lineHeight:"24px", fontWeight:"500", margin:"21px 0px 12px 0px"}}>{`${showFilter ? "Filter results" : "Search results" } (${count})`}</div>
          {serachData?.length > 0 ? 
          <div>
            <div
              className="content-style"
              // style={{ display: "flex", flexWrap: "wrap", gap: "24px" }}
            >
              {serachData?.map((connection) => (
                <ConnectUserCard
                  connectionData={connection}
                  key={connection.id}
                  mode={CONNECTION_TYPE.SUGGESTIONS}
                  isScrollable={false}
                />
              ))}
            </div>
           { count > 12 && <div className="paginate-style">
              <div className="pages-inner-container">
                <div className="pagination-box">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <div
                          className={
                            Math.ceil(apiDetails.pageCount / limit) ===
                            currentPage + 1
                              ? "previous-button disabled"
                              : "previous-button"
                          }
                        >
                          
                          <img src="/static/u_angle-left-b(1).svg" alt="" />
                          {!isMobile ? "Next " : ""}
                        </div>
                      </>
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={!isMobile ? 5 : 2}
                    forcePage={currentPage}
                    pageCount={Math.ceil(apiDetails.pageCount / limit)}
                    previousLabel={
                      <>
                        <div className="previous-button">
                        {!isMobile ? "Previous " : ""}
                          <img src="/static/u_angle-left-b.svg" alt="" />
                        </div>
                      </>
                    }
                    renderOnZeroPageCount={null}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                  />
                </div>
              </div>
            </div> }
          </div> :
          <NoSearchedFilter mode={showFilter ? "filter" : "search" } searchTerm={search} /> 
          }
       </div>
      ) : (
        <div className="inner-contet">
          {!isSearchedSeeAllTrue && <div className="content-title">Suggestions near by you</div>}
          {isSearchedSeeAllTrue && <div style={{fontSize:"14px", fontFamily:"Poppins", lineHeight:"24px", fontWeight:"500", margin:"21px 0px 12px 0px"}}>{`${showFilter ? "Filter results" : "Search results" } (${count})`}</div>}
          <ConnectMapContainer
            eventMap={isSearchedSeeAllTrue ? serachData : [
              ...suggestionData.interest,
              ...suggestionData.near_by,
              ...suggestionData.trainer,
              ...suggestionData.other,
            ]}
            latitude={userCurrentLocation.lat}
            longitude={userCurrentLocation.long}
            mode={CONNECTION_TYPE.SUGGESTIONS}
          />
        </div>
      )}
    </div>
  );
};

export default ConnectZone;
